import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ENVIRONMENT_TOKEN, LoaderEnum, LoadingService, StateService, storageKey } from '@lib/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  template: '',
})
export class MaintenancePageComponent implements OnInit, AfterViewInit {
  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected route: ActivatedRoute,
    protected translateService: TranslateService,
    protected stateService: StateService,
    protected modalService: BsModalService,
    protected loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {});
    this.modalService.hide();
  }

  ngAfterViewInit(): void {
    this.loadingService.hideLoader(LoaderEnum.MAIN);
  }

  goToRepertoire() {
    const url = this.stateService.getItem(storageKey.backUrl) ? this.stateService.getItem(storageKey.backUrl) : this.environment.backUrl;
    window.location.href = url;
  }
}
