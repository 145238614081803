import {DateTime} from 'luxon';
import { AbstractViewModel } from '../../abstract.view.model';
import { ReservationEventApiModel } from '../../api-model/reservation/reservation-event.api.model';
import {EventItemViewModel} from '../event/event-item.view.model';
import { TagGroupViewModel } from '../tag-group.view.model';

export class ReservationEventViewModel extends AbstractViewModel<ReservationEventApiModel> {
  id: string;
  duration: number;
  ageLongDescription: string;
  releaseDate: DateTime;
  posters: string[];
  pictures: string[];
  name: string;
  items: EventItemViewModel[];
  tagGroups: TagGroupViewModel[];

  constructor(
    protected apiModel: ReservationEventApiModel = new ReservationEventApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.duration = this.apiModel.duration;
    this.ageLongDescription = this.apiModel.ageLongDescription;
    this.releaseDate = this.apiModel.releaseDate ? DateTime.fromISO(this.apiModel.releaseDate) : null;
    this.posters = this.apiModel.posters ?? [];
    this.pictures = this.apiModel.pictures ?? [];
    this.name = this.apiModel.name;
    this.items = this.apiModel.items ? this.apiModel.items.map(item => new EventItemViewModel(item)) : [];
    this.tagGroups = this.apiModel.tagGroups ? this.apiModel.tagGroups.map(item => new TagGroupViewModel(item)) : [];
  }

  toApiModel(): ReservationEventApiModel {
    return undefined;
  }
}
