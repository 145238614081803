import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENVIRONMENT_TOKEN } from '../../injection.tokens';
import { IEnvironment } from '../../_environment/interface';
import { catchError, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private logEndpoint: string;

  constructor(@Inject(ENVIRONMENT_TOKEN) environment: IEnvironment, private http: HttpClient) {
    this.logEndpoint = environment.logUrl;
  }

  log(message: string, params?: any) {
    if (!this.logEndpoint) {
      return of(null);
    }

    const logData: {
      message: string;
      params?: any;
    } = { message };

    if (params) {
      logData.params = params;
    }

    return this.http.post(this.logEndpoint, logData).pipe(catchError(() => of(null)));
  }
}
