<div class="container p-0">
  <div class="d-flex head-inner justify-content-between w-100 px-3 px-md-9">
    <div class="logocontainer brand col-9 col-sm-6">
      <a href="{{env.siteUrl}}">
        <picture>
          <source media="(min-width:650px)" srcset="/assets/images/logo/main-logo.svg">
          <source media="(min-width:465px)" srcset="/assets/images/logo/main-logo.svg">
          <img src="/assets/images/logo/main-logo.svg" alt="Logo" style="width:auto;">
        </picture>
      </a>
    </div>
    <div class="text-sm-right">
      <app-user-status></app-user-status>
    </div>
  </div>
</div>
<app-order-background-component></app-order-background-component>