import { Expose } from 'class-transformer';

export class UserBookingApiModel {
  @Expose()
  salesDocumentId: string;

  @Expose()
  bookingId: string;

  @Expose()
  reservationId: string;
}
