import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { storageKey } from '../../app.const';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';
import { StateService } from './state.service';
import { filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { TokenStorageService } from '../service/token-storage.service';
import { CookieService } from 'ngx-cookie-service';
import { ReservationViewModel } from '../model/view-model/reservation/reservation.view.model';
import { ReservationDataProvider } from '../data-provider/reservation.data-provider';
import { ReservationRequestModel } from '../model/api-model/reservation/reservation.request.model';

@Injectable({
  providedIn: 'root',
})
export class ReservationStateService extends StateService {
  private model: ReservationViewModel | null;

  private state = new BehaviorSubject<ReservationViewModel>(null);
  state$: Observable<ReservationViewModel>;

  private initialDataFetched$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private interceptLoadData = false;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected tokenStorageService: TokenStorageService,
    protected cookieService: CookieService,
    protected reservationDataProvider: ReservationDataProvider
  ) {
    super(tokenStorageService, cookieService);
    this.state$ = this.initialDataFetched().pipe(switchMap(() => this.state.asObservable()));
    this.initState();
  }

  initialDataFetched() {
    return this.initialDataFetched$.asObservable().pipe(
      filter((v) => !!v),
      take(1)
    );
  }

  private initState() {
    const storedReservation = this.getReservationData();
    const initObservable$ = !storedReservation ? of(null) : this.fetchReservationData(storedReservation.id, storedReservation.cinemaId);
    initObservable$
      .pipe(
        finalize(() => {
          this.initialDataFetched$.next(true);
        })
      )
      .subscribe();
  }

  private fetchReservationData(reservationId, cinemaId) {
    return this.reservationDataProvider.getReservation(reservationId, cinemaId).pipe(
      tap({
        next: (res) => {
          if (!this.interceptLoadData) {
            this.setReservationData(res);
          }
        },
        error: (e) => this.removeReservation(),
      })
    );
  }

  createOrPatchReservation(reservationRequestModel: ReservationRequestModel) {
    let reservationObserver: Observable<ReservationViewModel>;

    if (this.model) {
      reservationObserver = this.reservationDataProvider.patchSeat(this.model.id, reservationRequestModel.cinemaId, reservationRequestModel);
    } else {
      reservationObserver = this.reservationDataProvider.create(reservationRequestModel);
    }

    return reservationObserver.pipe(
      tap((reservation) => {
        this.setReservationData(reservation);
      })
    );
  }

  clearState() {
    this.setReservationData(null);
  }

  setReservationData(reservation?: ReservationViewModel) {
    if (reservation) {
      this.setReservation(reservation?.id, reservation?.cinemaId, reservation?.screeningId, reservation?.expiryDate);
    } else {
      this.removeReservationStorage();
    }

    this.model = reservation;
    this.state.next(reservation);
  }

  getReservation(): ReservationViewModel {
    return this.model;
  }

  getCinemaId(): string {
    return this.model?.cinemaId;
  }

  removeReservation() {
    this.removeReservation$().subscribe();
  }

  removeReservation$() {
    return this.reservationDataProvider.delete(this.model?.id, this.model?.cinemaId).pipe(
      tap(() => {
        this.removeReservationStorage();
        this.setReservationData(null);
      })
    );
  }

  getReservationData() {
    const result = {
      id: this.getItem(storageKey.reservationId),
      cinemaId: this.getItem(storageKey.reservationCinemaId),
      screeningId: this.getItem(storageKey.reservationScreeningId),
      timeTo: DateTime.fromISO(this.getItem(storageKey.reservationScreeningTimeTo)),
    };

    return result.id && result.cinemaId ? result : null;
  }
}
