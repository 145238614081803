import { Expose, Transform } from 'class-transformer';

export class UserApiModel {
  @Expose()
  public id?: string;

  @Expose()
  public email: string;

  @Expose()
  public login: string;

  @Expose()
  public firstName: string;

  @Expose()
  public lastName: string;

  @Expose()
  public street: string;

  @Expose()
  public streetNumber: string;

  @Expose()
  public postalCode: string;

  @Expose()
  public city: string;

  @Expose()
  public phone: string;

  @Expose()
  public nick: string;

  @Expose()
  public birthday: string;

  @Expose()
  public plainPassword: string;

  @Expose()
  public agreements: string[] = new Array<string>();

  @Expose()
  public gender: number;

  @Expose()
  public avatar: string;

  @Expose()
  public twoFactorAuthCode: string;
}
