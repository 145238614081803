import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../../abstract.view.model';
import { EventApiModel } from '../../../api-model/account-items/reservation/event.api.model';
import { TagGroupViewModel } from '../../tag-group.view.model';
import { EventItemViewModel } from './event-item.view.model';

export class EventViewModel extends AbstractViewModel<EventApiModel> {
  public id: string;
  public duration: number;
  public ageLongDescription: string;
  public releaseDate: DateTime;
  public posters: string[] = [];
  public pictures: string[] = [];
  public name: string;
  public items: EventItemViewModel[] = [];
  public tagGroups: TagGroupViewModel[] = [];

  constructor(protected apiModel: EventApiModel = new EventApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.duration = this.apiModel.duration;
    this.ageLongDescription = this.apiModel.ageLongDescription;
    this.releaseDate = this.apiModel.releaseDate ? DateTime.fromISO(this.apiModel.releaseDate) : null;
    this.posters = this.apiModel.posters ? this.apiModel.posters : [];
    this.pictures = this.apiModel.posters ? this.apiModel.posters : [];
    this.name = this.apiModel.name;
    this.items = this.apiModel.items ? this.apiModel.items.map((item) => new EventItemViewModel(item)) : [];
    this.tagGroups = this.apiModel.tagGroups ? this.apiModel.tagGroups.map((tagGroup) => new TagGroupViewModel(tagGroup)) : [];
  }

  toApiModel(): EventApiModel {
    return undefined;
  }
}
