import { Component, Inject, Input, OnInit } from '@angular/core';
import { ENVIRONMENT_TOKEN, makeUrl } from 'libs/core/src/public-api';

@Component({
  selector: 'app-flag',
  templateUrl: './flag.component.html',
})
export class FlagComponent implements OnInit {
  @Input() lang: string;
  @Input() size = 16;
  @Input() path: string;

  imageUrl: string;
  imageLoaded: boolean = false;

  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any) {}

  ngOnInit(): void {
    this.imageUrl = this.buildImageUrl();
  }

  private buildImageUrl(): string {
    const lang = this.lang?.toLowerCase() ?? '';
    const basePath = this.path || this.environment.externalFlagsApiPath || makeUrl(this.environment, `/assets/images/flags/`);
    const normalizedPath = basePath.endsWith('/') ? basePath : `${basePath}/`;
    const ext = this.environment.flagImageExtension;
    const normalizedExt = ext ? `.${ext}` : '';

    return lang ? `${normalizedPath}${lang}${normalizedExt}` : '';
  }
}
