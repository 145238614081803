export const cardTokenizationIframeOptions = {
  lang: 'pl',
  size: {
    width: '100%',
    height: '381px',
  },
  labels: {
    cardholder: 'Imię i nazwisko posiadacza karty',
    card: 'Numer karty',
    cvv: 'Numer CVV/CVC karty',
    exp: 'Data ważności karty',
  },
  agreement: {
    contentEnabled: {
      enabled: true,
      checkboxEnabled: true,
    },
  },
  placeholders: {
    card: 'Podaj numer swojej karty',
    cvv: 'Podaj numer CVV swojej karty',
    exp: 'Podaj datę ważności swojej karty',
    cardholder: 'Podaj imię i nazwisko posiadacza karty',
  },
  styles: {
    font: {
      basic: {
        size: '0.8rem',
        family: '"DM Sans",sans-serif',
        weight: '200',
        color: '#000',
      },
      error: {
        color: 'red',
        weight: '500',
      },
      agreement: {
        family: 'sans-serif',
        link: '#0a0aee',
        visited: '#0a0aee',
        active: '#0a0aee',
      },
      focus: {
        color: '#000',
        weight: '500',
      },
      placeholders: {
        color: '#495057',
        weight: '500',
      },
    },
    border: {
      card: {
        type: 'solid',
        color: '#d6d6d6',
        width: '1px',
        radius: '5px',
      },
      cvv: {
        type: 'solid',
        color: '#d6d6d6',
        width: '1px',
        radius: '5px',
      },
      exp: {
        type: 'solid',
        color: '#d6d6d6',
        width: '1px',
        radius: '5px',
      },
      form: {
        type: 'none',
      },
      cardholder: {
        type: 'solid',
        color: '#d6d6d6',
        width: '1px',
        radius: '5px',
      },
    },
    agreement_checkbox: {
      color: '#0f0f0f',
      colorInside: '#fff',
      colorSelected: '#000',
      width: '22px',
    },
    background: {
      card: {
        color: '#f4f4f7',
      },
      cvv: {
        color: '#f4f4f7',
      },
      exp: {
        color: '#f4f4f7',
      },
      form: {
        color: '#fff',
      },
      cardholder: {
        color: '#f4f4f7',
      },
    },
  },
};
