String.prototype.format = format;
String.prototype.toTranslationKey = toTranslationKey;
String.prototype.replaceWhiteSpaces = replaceWhiteSpaces;
String.prototype.correctSpaceAfterCommas = correctSpaceAfterCommas;
String.prototype.toFirstCharCapitalLetter = toFirstCharCapitalLetter;

interface String {
  trimWhiteSpaces: typeof format;
  toTranslationKey: typeof toTranslationKey;
  format: (formatString: string, ...replacement: any[]) => string;
  replaceWhiteSpaces: typeof replaceWhiteSpaces;
  correctSpaceAfterCommas: typeof correctSpaceAfterCommas;
  toFirstCharCapitalLetter: typeof toFirstCharCapitalLetter;
}

function format() {
  let formatted = this;
  for (let i = 0; i < arguments.length; i++) {
    const regexp = new RegExp('\\{' + i + '\\}', 'gi');
    formatted = formatted.replace(regexp, arguments[i]);
  }
  return formatted;
}

/**
 * Changes the string into translation key format
 */
function toTranslationKey(prefixTranslationKey: string | null = null) {
  const SINGLE_WHITESPACE = ' ';
  const TRANSLATION_KEY_GLUE = '_';
  const TRANSLATION_KEY_SEPARATOR = '.';
  let content: string = this;

  content = content.replace(/\s\s+/g, SINGLE_WHITESPACE).toLocaleLowerCase().trim().replace(/\s/g, TRANSLATION_KEY_GLUE);

  if (prefixTranslationKey !== null && !prefixTranslationKey.endsWith('.')) {
    prefixTranslationKey += TRANSLATION_KEY_SEPARATOR;
  }

  return prefixTranslationKey === null ? content : prefixTranslationKey + content;
}

function replaceWhiteSpaces(replaceWith: string = '-') {
  return this.replace(/\s/g, replaceWith);
}

function correctSpaceAfterCommas() {
  return this.replaceAll(',', ', ').replaceAll('  ', ' ');
}

function toFirstCharCapitalLetter(): string {
  return this ? `${this.charAt(0).toUpperCase()}${this.slice(1)}` : '';
}
