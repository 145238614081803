import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../../abstract.view.model';
import { UserBookingApiModel } from '../../../api-model/user-history/booking/user-booking.api.model';

export class UserBookingViewModel extends AbstractViewModel<UserBookingApiModel> {
  public salesDocumentId: string = null;
  public bookingId: string = null;
  public reservationId: string = null;

  constructor(protected apiModel: UserBookingApiModel = new UserBookingApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.salesDocumentId = this.apiModel.salesDocumentId;
    this.bookingId = this.apiModel.bookingId;
    this.reservationId = this.apiModel.reservationId;
  }

  toApiModel(): UserBookingApiModel {
    return undefined;
  }
}
