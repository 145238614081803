import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../../abstract.view.model';
import { UserVoucherItemApiModel } from '../../../api-model/user-history/voucher-item/user-voucher-item.api.model';

export class UserVoucherItemViewModel extends AbstractViewModel<UserVoucherItemApiModel> {
  public id: string = null;
  public salesDocumentId: string = null;
  public itemId: string = null;
  public name: string = null;
  public price: number = null;
  public quantity: number = null;
  public pickupTime: DateTime = null;
  public additionalSalesDocumentId: string = null;
  public additionalSalesDocumentDate: DateTime = null;

  constructor(protected apiModel: UserVoucherItemApiModel = new UserVoucherItemApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.salesDocumentId = this.apiModel.salesDocumentId;
    this.itemId = this.apiModel.itemId;
    this.name = this.apiModel.name;
    this.price = this.apiModel.price;
    this.quantity = this.apiModel.quantity;
    this.pickupTime = this.apiModel.pickupTime;
    this.additionalSalesDocumentId = this.apiModel.additionalSalesDocumentId;
    this.additionalSalesDocumentDate = this.apiModel.additionalSalesDocumentDate;
  }

  toApiModel(): UserVoucherItemApiModel {
    return undefined;
  }
}
