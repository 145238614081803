import { Expose, Type } from 'class-transformer';
import { UserPaymentApiModel } from './user-payment.api.model';

export class UserSalesDocumentApiModel {
  @Expose()
  id: string;

  @Expose()
  basketId: string;

  @Expose()
  saleDate: string;

  @Expose()
  clientName: string;

  @Expose()
  clientLastName: string;

  @Expose()
  clientEmail: string;

  @Expose()
  clientPhoneNo: string;

  @Expose()
  cinemaId: string;

  @Expose()
  totalValue: number;

  @Expose()
  totalNetValue: number;

  @Expose()
  totalTaxValue: number;

  @Expose()
  pointsAffected: number;

  @Expose()
  pointsAffectedBoxOffice: number;

  @Expose()
  pointsAffectedConcession: number;

  @Expose()
  orderNumber: string;

  @Expose()
  @Type(() => UserPaymentApiModel)
  payments: UserPaymentApiModel[];
}
