import { Expose, Transform, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { UserFbModifierApiModel } from './user-fb-modifier.api.model';
import { UserFbSubItemApiModel } from './user-fb-sub-item.api.model';

export class UserFbItemApiModel {
  @Expose()
  id: string;

  @Expose()
  salesDocumentId: string;

  @Expose()
  itemId: string;

  @Expose()
  name: string;

  @Expose()
  price: number;

  @Expose()
  quantity: number;

  @Expose()
  @Transform(({ value }) => (value !== null ? DateTime.fromISO(value, { setZone: true }) : null), { toClassOnly: true })
  pickupTime: DateTime;

  @Expose()
  additionalSalesDocumentId: string;

  @Expose()
  @Transform(({ value }) => (value !== null ? DateTime.fromISO(value, { setZone: true }) : null), { toClassOnly: true })
  additionalSalesDocumentDate: DateTime;

  @Expose()
  voucherNumber: string;

  @Expose()
  voucherName: string;

  @Expose()
  voucherBatchName: string;

  @Expose()
  @Type(() => UserFbModifierApiModel)
  modifiers: UserFbModifierApiModel[];

  @Expose()
  @Type(() => UserFbSubItemApiModel)
  subItems: UserFbSubItemApiModel[];
}
