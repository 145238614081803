import { panValue } from "../../../tool/number/number";
import { AbstractViewModel } from "../../abstract.view.model";
import { ReservationItemApiModel } from "../../api-model/reservation/reservation-item.api.model";

export class ReservationItemViewModel extends AbstractViewModel<ReservationItemApiModel> {
  id: string;
  seatId: string;
  row: string;
  seat: string;

  constructor(
    protected apiModel: ReservationItemApiModel = new ReservationItemApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.seatId = this.apiModel.seatId;
    this.row = this.apiModel.row;
    this.seat = this.apiModel.seat;
  }

  toApiModel(): ReservationItemApiModel {
    return undefined;
  }

  public get seatPlace(): string {
    return `${panValue(this.row)}_${panValue(this.seat)}`;
  }
}
