<h3 class="mb-3">{{ 'user.user-my-profile.upcomingBookings' | translate}}</h3>

<div class="coming-grid" [smooth-in]="userAccountItems">
  <ng-container *ngIf="upcomingScreenings.length > 0; else empty">
    <div *ngFor="let screening of upcomingScreenings; index as ind">

      <div class="row m-0">
        <div class="col-12 col-sm-4 col-lg-2 p-0">
          <img pimg style="aspect-ratio: 11/17;" [imageUrl]="screening.getPoster()" alt="">
        </div>
        <div class="col-12 col-sm-8 col-lg-10 d-flex flex-column justify-content-center px-1 px-sm-3">
          <ng-container *ngIf="!screening.isEvent()">
            <div class="pt-2 pb-1">
              <h5 class="m-0 mr-4 pr-2 d-inline light-opacity">
                {{ screening.movie.title }}
              </h5>
            </div>
            <h8 class="d-flex flex-column" style="gap: 0.5rem;">
              <span class="pr-2"> {{ screening.screen.name }}</span>
              <span>{{ screening.screening.screeningTimeFrom | dateTime : 'DATE_HUMAN_COMA_MDY' }}
                {{ screening.screening.screeningTimeFrom | dateTime: '12H' }}</span>
              <span>{{'user.upcoming.tickets' | translate}} {{ screening.tickets.length }}</span>
              <span>{{'user.upcoming.bookingNo' | translate}} {{ screening.bookingId }}</span>
            </h8>
          </ng-container>
        </div>
      </div>
    </div>

  </ng-container>
</div>

<ng-template #empty>
  <div class="d-flex flex-column">
    <span>{{'user.upcoming.empty' | translate}}</span>
    <a href="{{environment.homepageUrl}}">{{'user.upcoming.bookNow' | translate}}</a>
  </div>
</ng-template>
