import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, finalize, map, take, tap } from 'rxjs';
import { DateTime } from 'luxon';
import { SystemSettingsHttpService } from '../http/system-settings.http.service';
import { SystemSetting } from '../model/view-model/system-setting/system-setting.model';
import { SystemSettingSymbol } from '@lib/core';

@Injectable({
  providedIn: 'root',
})
export class SystemSettingsService {
  public settings: SystemSetting[] = [];
  private initialDataFetched$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private systemSettingsHttpService: SystemSettingsHttpService) {
    this.systemSettingsHttpService
      .get()
      .pipe(
        tap((settings) => {
          this.settings = settings.hoParameters?.map((item) => new SystemSetting(item.symbol, item.type, item.value));
        }),
        finalize(() => this.initialDataFetched$.next(true))
      )
      .subscribe();
  }

  initialDataFetched() {
    return this.initialDataFetched$.asObservable().pipe(
      filter((v) => !!v),
      take(1)
    );
  }

  public getSetting$(key: SystemSettingSymbol): Observable<string | number | DateTime> {
    return this.initialDataFetched().pipe(map(() => this.getSetting(key)));
  }

  getSetting(symbol: SystemSettingSymbol): string | number | DateTime {
    const setting = this.settings.find((x) => x.symbol === symbol);
    return setting?.getValueBasedOnType();
  }
}
