import { Component } from '@angular/core';
import { CinemaApiModel } from '@lib/core';
import { SideEnum } from 'libs/core/src/lib/enum/side.enum';
import { MoviePrintApiModel } from 'libs/core/src/lib/model/api-model/movie/movie-print.api.model';
import { MovieApiModel } from 'libs/core/src/lib/model/api-model/movie/movie.api.model';
import { OrderApiModel } from 'libs/core/src/lib/model/api-model/order/order.api.model';
import { RegionApiModel } from 'libs/core/src/lib/model/api-model/region/region.api.model';
import { ScreenheadApiModel } from 'libs/core/src/lib/model/api-model/screen/screen-head.api.model';
import { ScreeningApiModel } from 'libs/core/src/lib/model/api-model/screening/screening.api.model';
import { CinemaViewModel } from 'libs/core/src/lib/model/view-model/cinema/cinema.view.model';
import { MoviePrintViewModel } from 'libs/core/src/lib/model/view-model/movie/movie-print.view.model';
import { MovieViewModel } from 'libs/core/src/lib/model/view-model/movie/movie.view.model';
import { OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { RegionViewModel } from 'libs/core/src/lib/model/view-model/region/region.view.model';
import { ScreenheadViewModel } from 'libs/core/src/lib/model/view-model/screen/screen-head.view.model';
import { ScreeningViewModel } from 'libs/core/src/lib/model/view-model/screening/screening.view.model';
import { ScreeningDetails } from 'libs/core/src/lib/wp-model/adapters';

export enum OrderFlowEnum {
  STANARD = 'standard',
  REWARDS = 'rewards',
}

@Component({
  selector: 'app-ui-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class UITestComponent {
  public testObject: any;

  side: SideEnum = SideEnum.LEFT;
  orderFlow: OrderFlowEnum = OrderFlowEnum.STANARD;

  ngOnInit() {
    this.buildModels();
  }

  onSideChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.side = selectElement.value as SideEnum;
  }

  onOrderFlowChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.orderFlow = selectElement.value as OrderFlowEnum;
    this.buildModels();
  }

  buildModels() {
    const cinemaId = 'cin1';

    const orderViewModel = new OrderViewModel(cinemaId, testOrderApiModel);
    orderViewModel.cardTypeItems[0].profit = 10;

    this.testObject = {
      rewards: this.orderFlow === OrderFlowEnum.REWARDS,
      screeningDetails: [
        new ScreeningDetails(
          new MovieViewModel(testMovieApiMode),
          null,
          new MoviePrintViewModel(testMoviePrintApiModel),
          new RegionViewModel(testRegionApiModel),
          new CinemaViewModel(testCinemaApiModel),
          new ScreeningViewModel(testScreeningApiModel),
          new ScreenheadViewModel(testScreenheadApiModel)
        ),
      ],
      order: orderViewModel,
    };
  }
}

const testMovieApiMode: MovieApiModel = {
  id: '1',
  duration: 120,
  description: 'A thrilling adventure film',
  ageLongDescription: 'Suitable for ages 12 and up',
  releaseDate: '2024-12-25',
  distributor: 'Big Film Co.',
  distributorId: 'BF001',
  genres: [{ id: 'g1', name: 'Action', description: 'High energy action films' }],
  trailers: ['https://example.com/trailer1.mp4'],
  posters: ['https://example.com/poster1.jpg'],
  pictures: ['https://example.com/pic1.jpg'],
  posPosters: ['https://example.com/posposter1.jpg'],
  banners: [{ resourceUrl: 'https://example.com/banner1.jpg', targetUrl: 'https://example.com/target1' }],
  ratings: [{ cinemaGroupId: 'cg1', symbol: 'PG-13', value: '13+', description: 'Parental Guidance' }],
  title: 'Epic Adventure',
  shortTitle: 'Adventure',
  originalTitle: 'Epic Adventure',
  onlineTitle: 'Epic Adventure Online',
  filmCast: 'John Doe, Jane Smith',
  scenario: 'Jane Doe',
  isForChildren: false,
  yearOfProduction: '2024',
  director: 'Director Name',
  shortDescription: 'An epic adventure film filled with excitement and action.',
  premiereDate: '2024-12-20',
  country: 'USA',
  originalLanguage: 'English',
  originalTitleLanguage: 'English',
  imdbId: 'tt123456',
  imdbRating: '8.5',
  type: 'Feature',
  worldPremiereDate: '2024-11-30',
  tagGroups: [{ symbol: 'tag1', description: 'Adventure', tags: [{ orderNo: 1, symbol: 'adventure', description: 'Exciting and adventurous' }] }],
  brightcoveVideos: null,
};

const testMoviePrintApiModel: MoviePrintApiModel = {
  id: 'mp1',
  printType: 'Digital',
  printExternalId: 'mp1',
  soundType: 'Stereo',
  speakingType: 'Original',
  movieId: '1',
  language: 'English',
  subtitles: 'None',
  subtitles2: 'Spanish',
  release: 'Standard',
  format: '2D',
  frameRate: '24fps',
  resolution: '4K',
};

const testRegionApiModel: RegionApiModel = {
  id: 'r1',
  code: 'REG1',
  region: 'North America',
  cinemas: [
    {
      id: 'cin1',
      name: 'Mega Cinema',
      groupId: 'cg1',
      city: 'New York',
      province: 'NY',
      street: '123 Main St',
      latitude: 40.7128,
      longitude: -74.006,
      phone: '555-1234',
      zipcode: '10001',
      reservationPhone: '555-5678',
      description: 'A state-of-the-art cinema with multiple screens',
      url1: 'https://example.com/cinema1',
      url2: 'https://example.com/cinema2',
      numberOfScreens: 15,
      numberOfSeats: 500,
      numberOfDisabledSeats: 20,
      graphics: ['https://example.com/cinema1-graphic.jpg'],
      email: 'info@megacinema.com',
      reservationGroupPhone: '555-8765',
      fax: '555-4321',
      isAnyDreamScreen: true,
      timezone: 'EST',
    },
  ],
};

const testCinemaApiModel: CinemaApiModel = {
  id: 'cin1',
  name: 'Mega Cinema',
  groupId: 'cg1',
  city: 'New York',
  province: 'NY',
  street: '123 Main St',
  latitude: 40.7128,
  longitude: -74.006,
  phone: '555-1234',
  zipcode: '10001',
  reservationPhone: '555-5678',
  description: 'A state-of-the-art cinema with multiple screens',
  url1: 'https://example.com/cinema1',
  url2: 'https://example.com/cinema2',
  numberOfScreens: 15,
  numberOfSeats: 500,
  numberOfDisabledSeats: 20,
  graphics: ['https://example.com/cinema1-graphic.jpg'],
  email: 'info@megacinema.com',
  reservationGroupPhone: '555-8765',
  fax: '555-4321',
  isAnyDreamScreen: true,
  timezone: 'EST',
};

const testScreeningApiModel: ScreeningApiModel = {
  id: 's1',
  regionId: 'r1',
  cinemaId: 'cin1',
  screenId: 'sh1',
  moviePrintId: 'mp1',
  movieExternalId: '1',
  moviePrintExternalId: 'mp1',
  screeningTimeFrom: '2024-12-25T10:00:00',
  screeningTimeTo: '2024-12-25T12:00:00',
  screeningDuration: 120,
  audience: 200,
  generalAdmission: true,
  saleTimeTo: '2024-12-25T09:00:00',
  reservationTimeTo: '2024-12-25T08:00:00',
  isScreenSwapping: false,
  availabilityStatus: 1,
  maxOccupancy: 250,
  movieId: '1',
  printType: 'Digital',
  speakingType: 'Original',
  language: 'English',
  subtitles: 'None',
  subtitles2: 'Spanish',
  soundType: 'Stereo',
  release: 'Standard',
  format: '2D',
  resolution: '4K',
  frameRate: '24fps',
};

const testScreenheadApiModel: ScreenheadApiModel = {
  id: 'sh1',
  name: 'Screen 1',
  type: 'Standard',
  feature: 'Dolby Atmos',
  number: 1,
  screenGroupId: 'sg1',
  ffaNumber: 'FFA001',
  proCinema: 'Yes',
};

const testOrderApiModel: OrderApiModel = {
  id: 'order123',
  bookingId: 'booking456',
  dateEntry: '2024-12-12T10:30:00.000Z',
  dateTimeToLive: '2024-12-12T12:30:00.000Z',
  userFirstName: 'John',
  userLastName: 'Doe',
  userPhone: '+123456789',
  userEmail: 'john.doe@example.com',
  status: 1,
  screeningItems: [
    {
      id: 'screenItem001',
      seatId: 'seatA12',
      screeningId: 's1',
      ticketId: 'ticket123',
      price: 15.0,
      ticketPrice: 18.0,
      ticketPriceWithMandatoryExtraFees: 20.0,
      ticketDefaultPriceLevelPriceWithMandatoryExtraFees: 22.0,
      quantity: 1,
      tax: 1.5,
      extraFees: [
        {
          id: 'extraFee001',
          name: '3D Glasses Fee',
          quantity: 1,
          price: 2.0,
          taxRate: 0.07,
          isOptional: false,
          defaultPriceLevelPrice: 2.5,
        },
        {
          id: 'extraFee002',
          name: 'Special Fee',
          quantity: 2,
          price: 2.0,
          taxRate: 0.07,
          isOptional: false,
          defaultPriceLevelPrice: 2.5,
        },
      ],
      membershipPrices: [
        {
          isAppliedOnOrder: true,
          membershipLevel: 1,
          membershipCardTypeId: 'memberType001',
          membershipTicketPrice: 16.5,
          membershipPrice: 18.0,
        },
        {
          isAppliedOnOrder: false,
          membershipLevel: 2,
          membershipCardTypeId: 'memberType002',
          membershipTicketPrice: 14.0,
          membershipPrice: 15.5,
        },
      ],
      bookingId: 'booking123',
      cardId: 'card456',
      optionalExtraFees: ['extraFee002'],
      name: 'Movie Ticket - Popcorn Combo',
      printoutName: 'Popcorn Combo Ticket',
      voucherNumber: 'V123456',
      voucherName: 'Discount Voucher',
      defaultPriceLevelPrice: 22.0,
      ticketDefaultPriceLevelPrice: 20.0,
      itemEarnedPoints: 5,
      itemRedemptionPoints: 3,
      promotionId: 'promo567',
      promotionName: 'Holiday Discount',
      row: 'A',
      seat: '12',
    },
  ],
  cardTypeItems: [
    {
      id: 'cardItem002',
      itemId: 'item456',
      typeId: 'type002',
      templateId: 'template002',
      chargeValue: 50.0,
      email: 'customer@example.com',
      message: 'Card Type Item 1 Message',
      promotionId: 'promo789',
      promotionName: 'Card Type Item 1 Promotion Name',
      name: 'Card Type Item 1 Long Test Value (long test value name)',
      quantity: 1,
      taxRate: 0.05,
      itemPrice: 45.0,
      cardId: 'card789',
      itemDefaultPriceLevelPrice: 60.0,
      itemEarnedPoints: 15,
      itemRedemptionPoints: 8,
      membershipPrices: [
        {
          isAppliedOnOrder: true,
          membershipLevel: 1,
          membershipCardTypeId: 'memberType003',
          membershipItemPrice: 40.0,
          membershipChargeValue: 10.0,
        },
        {
          isAppliedOnOrder: false,
          membershipLevel: 2,
          membershipCardTypeId: 'memberType004',
          membershipItemPrice: 30.0,
          membershipChargeValue: 5.0,
        },
      ],
      voucherNumber: '',
      voucherName: '',
    },
    {
      id: 'cardItem002',
      itemId: 'item456',
      typeId: 'type002',
      templateId: 'template002',
      chargeValue: 50.0,
      email: 'customer@example.com',
      message: 'Card Type Item 2 Message',
      promotionId: 'promo789',
      promotionName: 'Card Type Item 2 Promotion Name',
      name: 'Card Type Item 2 Long Test Value (long test value name)',
      quantity: 2,
      taxRate: 0.05,
      itemPrice: 45.0,
      cardId: undefined, // 'card789',
      itemDefaultPriceLevelPrice: 60.0,
      itemEarnedPoints: 15,
      itemRedemptionPoints: 8,
      membershipPrices: [
        {
          isAppliedOnOrder: true,
          membershipLevel: 1,
          membershipCardTypeId: 'memberType003',
          membershipItemPrice: 40.0,
          membershipChargeValue: 10.0,
        },
        {
          isAppliedOnOrder: false,
          membershipLevel: 2,
          membershipCardTypeId: 'memberType004',
          membershipItemPrice: 30.0,
          membershipChargeValue: 5.0,
        },
      ],
      voucherNumber: '',
      voucherName: '',
    },
  ],
  voucherItems: [
    {
      id: 'voucherItem001',
      itemName: 'Voucher Item 1 Long Test Value (long test value name)',
      itemId: 'item123',
      quantity: 3,
      itemTaxRate: 0.07,
      itemPrice: 15.0,
      value: 45.0,
      bookingId: 'booking001',
      promotionId: 'promo456',
      promotionName: 'Voucher Item 1 Promotion Name',
      itemRedemptionPoints: 20,
      membershipPrices: [
        {
          isAppliedOnOrder: true,
          membershipLevel: 1,
          membershipCardTypeId: 'memberType001',
          membershipItemPrice: 12.0,
          membershipValue: 5.0,
        },
        {
          isAppliedOnOrder: false,
          membershipLevel: 2,
          membershipCardTypeId: 'memberType002',
          membershipItemPrice: 10.0,
          membershipValue: 3.0,
        },
      ],
      purchasedVoucherNumbers: ['V001', 'V002', 'V003'],
    },
    {
      id: 'voucherItem002',
      itemName: 'Voucher Item 2 Long Test Value (long test value name)',
      itemId: undefined, //'item123',
      quantity: 3,
      itemTaxRate: 0.07,
      itemPrice: 15.0,
      value: 45.0,
      bookingId: 'booking001',
      promotionId: undefined, //'promo456',
      promotionName: 'Voucher Item 2 Promotion Name',
      itemRedemptionPoints: 20,
      membershipPrices: [
        {
          isAppliedOnOrder: true,
          membershipLevel: 1,
          membershipCardTypeId: 'memberType001',
          membershipItemPrice: 12.0,
          membershipValue: 5.0,
        },
        {
          isAppliedOnOrder: false,
          membershipLevel: 2,
          membershipCardTypeId: 'memberType002',
          membershipItemPrice: 10.0,
          membershipValue: 3.0,
        },
      ],
      purchasedVoucherNumbers: ['V001', 'V002', 'V003'],
    },
  ],
  fbItems: [
    {
      id: 'fbItem001',
      articleId: 'article123',
      quantity: 2,
      voucherNumber: 'V123456',
      voucherName: 'F&B Item 1 Voucher Name',
      name: 'F&B Item 1 Long Test Value (long test value name)',
      price: 20.0,
      comboMinPrice: 18.0,
      taxValue: 1.5,
      pickupTime: '2024-12-12T18:30:00Z',
      defaultPriceLevelPrice: 22.0,
      subArticleList: [
        {
          articleId: 'subArticle001',
          quantity: 1,
          name: 'Small Popcorn',
          taxRate: 0.07,
          price: 5.0,
          defaultPriceLevelPrice: 6.0,
        },
        {
          articleId: 'subArticle002',
          quantity: 1,
          name: 'Medium Soda',
          taxRate: 0.07,
          price: 3.0,
          defaultPriceLevelPrice: 4.0,
        },
      ],
      modifierItemList: [
        {
          modifierId: 'mod001',
          name: 'Extra Cheese',
          isSeparate: false,
          modifierName: 'Cheese Options',
          modifierItemId: 'modItem001',
          modifierItemName: 'Cheddar Cheese',
          modifierItemDescription: 'Add extra cheddar cheese to your order.',
          quantity: 1,
          taxRate: 0.07,
          price: 2.5,
          primaryModifierItemId: 'primaryModItem001',
          defaultPriceLevelPrice: 3.0,
        },
      ],
      itemEarnedPoints: 10,
      itemRedemptionPoints: 5,
      promotionId: 'promo567',
      promotionName: 'F&B Item 1 Promotion Name',
      membershipPrices: [
        {
          isAppliedOnOrder: true,
          membershipLevel: 1,
          membershipCardTypeId: 'memberType001',
          membershipPrice: 19.0,
        },
        {
          isAppliedOnOrder: false,
          membershipLevel: 2,
          membershipCardTypeId: 'memberType002',
          membershipPrice: 17.5,
        },
      ],
      graphicUrl: 'https://example.com/images/popcorn-combo.png',
      assignedSeatId: 'seatA12',
      assignedScreeningId: 'screening001',
    },
    {
      id: 'fbItem001',
      articleId: 'article123',
      quantity: 2,
      voucherNumber: 'V123456',
      voucherName: 'F&B Item 2 Voucher Name',
      name: 'F&B Item 2 Long Test Value (long test value name)',
      price: 20.0,
      comboMinPrice: 18.0,
      taxValue: 1.5,
      pickupTime: '2024-12-12T18:30:00Z',
      defaultPriceLevelPrice: 22.0,
      subArticleList: [
        {
          articleId: 'subArticle001',
          quantity: 1,
          name: 'Small Popcorn',
          taxRate: 0.07,
          price: 5.0,
          defaultPriceLevelPrice: 6.0,
        },
        {
          articleId: 'subArticle002',
          quantity: 1,
          name: 'Medium Soda',
          taxRate: 0.07,
          price: 3.0,
          defaultPriceLevelPrice: 4.0,
        },
      ],
      modifierItemList: [
        {
          modifierId: 'mod001',
          name: 'Extra Cheese',
          isSeparate: false,
          modifierName: 'Cheese Options',
          modifierItemId: 'modItem001',
          modifierItemName: 'Cheddar Cheese',
          modifierItemDescription: 'Add extra cheddar cheese to your order.',
          quantity: 1,
          taxRate: 0.07,
          price: 2.5,
          primaryModifierItemId: 'primaryModItem001',
          defaultPriceLevelPrice: 3.0,
        },
      ],
      itemEarnedPoints: 10,
      itemRedemptionPoints: 5,
      promotionId: undefined, //'promo567',
      promotionName: 'F&B Item 2 Promotion Name',
      membershipPrices: [
        {
          isAppliedOnOrder: true,
          membershipLevel: 1,
          membershipCardTypeId: 'memberType001',
          membershipPrice: 19.0,
        },
        {
          isAppliedOnOrder: false,
          membershipLevel: 2,
          membershipCardTypeId: 'memberType002',
          membershipPrice: 17.5,
        },
      ],
      graphicUrl: 'https://example.com/images/popcorn-combo.png',
      assignedSeatId: 'seatA12',
      assignedScreeningId: 'screening001',
    },
  ],
  cardItems: [
    {
      id: 'cardItem001',
      cardId: undefined, // 'cardId',
      cardTypeId: 'type001',
      cardBatchId: 'batch001',
      cardBatchName: 'Card Batch Name 1',
      cardPictureUrl: 'https://example.com/images/card-picture.png',
      name: 'Card Item Name 1',
      batchCardUnlimited: false,
      value: 150.0,
      promotionId: 'promo123',
      promotionName: 'Holiday Promotion',
      shippingAddress: {
        firstName: 'John',
        lastName: 'Doe',
        city: 'New York',
        street: 'Broadway',
        streetNumber: '123',
        postalCode: '10001',
        address1: 'Apartment 4B',
        address2: 'Near Central Park',
      },
      membershipPrices: [
        {
          isAppliedOnOrder: true,
          membershipLevel: 1,
          membershipCardTypeId: 'memberType001',
          membershipValue: 120.0,
        },
        {
          isAppliedOnOrder: false,
          membershipLevel: 2,
          membershipCardTypeId: 'memberType002',
          membershipValue: 100.0,
        },
      ],
    },
    {
      id: 'cardItem002',
      cardId: 'card123',
      cardTypeId: 'type001',
      cardBatchId: 'batch001',
      cardBatchName: 'Card Batch Name 2',
      cardPictureUrl: 'https://example.com/images/card-picture.png',
      name: 'Card Item Name 2',
      batchCardUnlimited: false,
      value: 150.0,
      promotionId: 'promo123',
      promotionName: 'Holiday Promotion',
      shippingAddress: {
        firstName: 'John',
        lastName: 'Doe',
        city: 'New York',
        street: 'Broadway',
        streetNumber: '123',
        postalCode: '10001',
        address1: 'Apartment 4B',
        address2: 'Near Central Park',
      },
      membershipPrices: [
        {
          isAppliedOnOrder: true,
          membershipLevel: 1,
          membershipCardTypeId: 'memberType001',
          membershipValue: 120.0,
        },
        {
          isAppliedOnOrder: false,
          membershipLevel: 2,
          membershipCardTypeId: 'memberType002',
          membershipValue: 100.0,
        },
      ],
    },
  ],
  externalUserId: 'external123',
  cardId: 'card123',
  paymentMethods: [
    {
      id: 'payment001',
      name: 'Credit Card',
      identifier: 'visa',
      cardId: 'card123',
      value: 50.0,
    },
  ],
  valueToPay: 50.0,
  totalValue: 50.0,
  totalNetValue: 45.0,
  totalTaxValue: 5.0,
  defaultPriceLevelTotalValue: 50.0,
  taxId: 'tax001',
  orderNumber: undefined, //'ORD123456',
  totalEarnedPoints: 10,
  totalRedemptionPoints: 5,
  defaultExtraFees: [
    {
      basketItemId: 'basketItemId1',
      defaultExtraFeeId: 'defaultExtraFeeId1',
      defaultExtraFeeName: 'Default Extra Fee Long Test Value (long test value name)',
      defaultExtraFeePrice: 30.0,
      defaultExtraFeeEarnedPoints: 10.0,
      defaultExtraFeeRedemptionPoints: 5.0,
      membershipPrices: [
        {
          isAppliedOnOrder: false,
          membershipLevel: 1,
          membershipCardTypeId: 'membershipCardTypeId',
          membershipDefaultExtraFeePrice: 40.0,
        },
      ],
    },
  ],
  membershipPrices: [
    {
      isAppliedOnOrder: false,
      membershipLevel: 1,
      membershipCardTypeId: 'membershipCardTypeId',
      membershipTotalValue: 40.0,
    },
  ],
  documentNumber: undefined, //'DOC123',
  salesDocumentId: undefined, //'SD123',
};
