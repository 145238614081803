import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, map } from 'rxjs';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { SystemSetting, SystemSettings } from '../model/view-model/system-setting/system-setting.model';

@Injectable({
  providedIn: 'root',
})
export class SystemSettingsHttpService {
  public static cacheBuster$ = new Subject<void>();

  constructor(private http: HttpClient) {}

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: SystemSettingsHttpService.cacheBuster$,
    maxAge: 86400000,
  })
  get(): Observable<SystemSettings> {
    return this.http.get<SystemSettings>(`/system/settings`).pipe();
  }
}
