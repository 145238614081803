import { Component, Inject, Input } from '@angular/core';
import { ScreeningWithTickets } from 'libs/core/src/lib/model/screening-with-tickets';
import { AccountItemsViewModel } from 'libs/core/src/lib/model/view-model/account-items/account-items.view.model';
import { ReservationViewModel } from 'libs/core/src/lib/model/view-model/account-items/reservation/reservation.view.model';
import { ScreeningWithTicketsAdapter } from 'libs/core/src/lib/model/view-model/account-items/screening-with-tickets-adapter';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';

@Component({
  template: '',
})
export class UserUpcomingComponent {
  userAccountItems: AccountItemsViewModel;
  upcomingScreenings: ScreeningWithTickets[] = [];

  @Input() set accountItems(accountItems: AccountItemsViewModel) {
    this.userAccountItems = accountItems;
    if (accountItems?.reservations) {
      this.upcomingScreenings = this.adaptScreeningWithTickets(accountItems.reservations);
    }
  }

  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, protected screeningWithTicketsAdapter: ScreeningWithTicketsAdapter) {}

  //overridden by extension components
  adaptScreeningWithTickets(items: ReservationViewModel[]) {
    return this.screeningWithTicketsAdapter.adapt(items);
  }
}
