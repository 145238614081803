import { PaymentRequestClientAddress } from '../request/create-payment.request.model';
import { OrderPaymentRequestModel } from '../request/order-payment.request.model';

export interface IOrderPaymentPackage {
  cinemaId: string;
  orderId: string;

  paymentProviderIdentifier: string;

  requestModel: OrderPaymentRequestModel;

  recaptchaResponse?: string | null;

  clientAddress?: PaymentRequestClientAddress;
}
