import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationHelperService } from 'libs/core/src/lib/service/navigation/navigation-helper.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { CreatePaymentRedirectUrl } from '../create-payment-redirect-url';

@Injectable({
  providedIn: 'root',
})
export class PaymentHelperService {
  private paymentRedirectUrlPattern: string;

  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, private router: Router, private navigationHelper: NavigationHelperService) {
    this.paymentRedirectUrlPattern = environment.constants.paymentRedirectUrl;
  }

  /**
   * Creates an payment redirect URI
   * @param request
   */
  public createPaymentRedirectUrl(request: CreatePaymentRedirectUrl, target: string = null): string {
    return this.paymentRedirectUrlPattern
      .replace('{cinemaId}', request.order.cinemaId)
      .replace('{orderId}', request.order.id)
      .replace('{target}', encodeURIComponent(target ? target : this.getTargetUrl(request)))
      .replace('{paymentChannel}', request.paymentChannel);
  }

  public getTargetUrl(request: CreatePaymentRedirectUrl): string {
    const urlTree: string = this.router
      .createUrlTree([this.navigationHelper.getNextRoute(request.route.snapshot)], {
        queryParams: {
          orderId: request.order.id,
          cinemaId: request.order.cinemaId,
        },
      })
      .toString();
    return `${window.location.origin}/#${urlTree}`;
  }
}
