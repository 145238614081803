import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Inject, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { OrderDataProvider } from 'libs/core/src/lib/data-provider/order.data-provider';
import { PaymentProviderConfigRequestModel } from 'libs/core/src/lib/model/request/payment-provider-config.request.model';
import { PaymentViewModel } from 'libs/core/src/lib/model/view-model/payment.view.model';
import { PaymentConfigViewModel } from 'libs/core/src/lib/model/view-model/payment/config/payment.config.view.model';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { Observable, Subscription, defer, finalize, forkJoin, iif, of, switchMap, tap, throwError } from 'rxjs';
import { AbstractTagProviderComponent } from '../../../../tag-manager/provider/abstract-tag-provider.component';
import { PaymentProviderEvent, DonePaymentProviderEvent, ErrorPaymentProviderEvent } from '../../event/payment-provider.event';
import { PaymentMethodEnum } from '../payu/payment-method.enum';
import { OrderPaymentPackage } from '../../../../../../../../core/src/lib/model/order-payment.package.model';
import { PaymentProviderComponentInterface } from '../payment-provider.component.interface';
import { BridgePayEventModel } from './bridgepay.model';
import { GoogleTagManagerService } from 'libs/core/src/lib/service/analytics-services/google-tag-manager.service';
import { PaymentMethodViewModel } from 'libs/core/src/lib/model/view-model/order/payment-method/payment-method.view.model';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationHelperService } from 'libs/core/src/lib/service/navigation/navigation-helper.service';
import { OrderPaymentRequestModel } from 'libs/core/src/lib/model/request/order-payment.request.model';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { RxjsHelper } from 'libs/core/src/lib/helper/rxjs.helper';
import { PaymentProviderStateEnum } from '../../model/payment-provider-status.enum';
import { ENVIRONMENT_TOKEN, OrderViewModel, ProviderEnum, TranslationService } from '@lib/core';
import { IOrderPaymentPackage } from 'libs/core/src/lib/model/interface/order-payment.package.model';

@Component({
  selector: 'app-payment-provider-bridgepay-component',
  templateUrl: './bridgepay-payment-provider.component.html',
})
export class BridgePayPaymentProviderComponent extends AbstractTagProviderComponent implements PaymentProviderComponentInterface, OnInit, OnDestroy {
  PaymentProviderStateEnum = PaymentProviderStateEnum;

  @Output() public events: EventEmitter<PaymentProviderEvent> = new EventEmitter<PaymentProviderEvent>();

  public paymentMethod = PaymentMethodEnum;
  public readonly paymentProviderIdentifier: string = ProviderEnum.BRIDGEPAY;
  public paymentProviderConfig: PaymentConfigViewModel | null;

  public bridgePayToken: BridgePayEventModel;
  public paymentInitialized = false;
  public paymentProviderState: PaymentProviderStateEnum = PaymentProviderStateEnum.Initial;

  private order: OrderViewModel = null;
  private readonly paymentChannel: string | null = null;
  private paymentProviderInitSubscription: Subscription = Subscription.EMPTY;

  @ViewChild('bridgePayConfirmBtn') bridgePayConfirmBtn!: ElementRef<HTMLButtonElement>;
  isSubmitting = false;

  @HostListener('window:message', ['$event'])
  handleKeyDown(event: any) {
    if (this.paymentProviderState !== PaymentProviderStateEnum.InProgress) {
      if (this.paymentProviderConfig) {
        this.paymentProviderState = PaymentProviderStateEnum.Ready;
      } else {
        this.paymentProviderState = PaymentProviderStateEnum.Initial;
      }
    }

    if (!event?.data || (event.data.type !== 'success' && !this.isSubmitting)) {
      return;
    }

    if ((event.data.type === 'validation' && event.data.errorMessage !== '') || event.data.status === 'auth_error') {
      this.handleButtonHideLoader();
      return;
    }

    let token: BridgePayEventModel = new BridgePayEventModel();
    try {
      Object.assign(token, event?.data);
    } catch (e) {
      return;
    }

    if (token?.data && token.data.token && token.data.status === 'auth_success') {
      this.bridgePayToken = token;

      let paymentMethodViewModel = new PaymentMethodViewModel();
      paymentMethodViewModel.identifier = this.paymentProviderIdentifier;

      this.isSubmitting = true;
      this.loadingService.showLoader(LoaderEnum.PAYMENT);
      this.onInitPayment(paymentMethodViewModel).subscribe({
        next: () => {
          this.loadingService.hideLoader(LoaderEnum.PAYMENT);
        },
        error: (e: HttpErrorResponse) => {
          this.handleButtonHideLoader();
        },
      });
    }
  }

  public constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected renderer: Renderer2,
    @Inject(DOCUMENT) protected _document,
    protected orderDataProvider: OrderDataProvider,
    protected orderStateService: OrderStateService,
    protected googleTagManagerService: GoogleTagManagerService,
    protected router: Router,
    protected navigationHelperService: NavigationHelperService,
    protected route: ActivatedRoute,
    protected loadingService: LoadingService,
    protected rxjsHelper: RxjsHelper,
    protected translationService: TranslationService
  ) {
    super(renderer, _document);
    this.paymentChannel = environment.constants.paymentChannel;
  }

  public ngOnInit(): void {
    this.orderStateService.state$.subscribe((order) => {
      this.order = order;
    });

    this.createStyles();
    this.createScript();
  }

  public ngOnDestroy(): void {
    if (this.paymentProviderInitSubscription !== Subscription.EMPTY) {
      this.paymentProviderInitSubscription.unsubscribe();
      this.paymentProviderInitSubscription = Subscription.EMPTY;
    }
  }

  private initPaymentProviderWidget(): Observable<PaymentConfigViewModel> {
    const requestModel = new OrderPaymentRequestModel();
    requestModel.channel = this.paymentChannel;

    const orderPaymentPackage = new OrderPaymentPackage();
    orderPaymentPackage.cinemaId = this.order.cinemaId;
    orderPaymentPackage.orderId = this.order.id;
    orderPaymentPackage.paymentProviderIdentifier = this.paymentProviderIdentifier;
    orderPaymentPackage.requestModel = requestModel;

    return this.orderDataProvider.getPaymentProviderConfig(orderPaymentPackage).pipe(
      tap({
        next: (paymentProviderConfigModel) => {
          this.paymentProviderConfig = paymentProviderConfigModel;
          this.paymentProviderState = PaymentProviderStateEnum.Ready;
          this.events.next(new DonePaymentProviderEvent());
        },
        error: (e: HttpErrorResponse) => this.events.next(new ErrorPaymentProviderEvent(e)),
      })
    );
  }

  onInitPayment(paymentMethod: PaymentMethodViewModel): Observable<any> {
    this.loadingService.showLoader(LoaderEnum.PAYMENT);
    const paymentInitModel = new OrderPaymentPackage();

    let g$ = !this.paymentInitialized
      ? this.onPreInitPayment(paymentInitModel).pipe(
          tap({
            next: () => {
              this.orderStateService.setPaymentMethod(paymentMethod);
              this.googleTagManagerService.addPaymentInfo(paymentMethod?.name);
              this.paymentInitialized = true;
              this.loadingService.hideLoader(LoaderEnum.PAYMENT);
            },
          })
        )
      : defer(() => {
          this.loadingService.showLoader(LoaderEnum.PAYMENT);
          this.paymentProviderState = PaymentProviderStateEnum.InProgress;

          return this.rxjsHelper.executeAfter(
            200,
            () => !this.bridgePayToken,
            switchMap(() =>
              iif(
                () => !!this.bridgePayToken,
                this.orderDataProvider.postPayment(this.createPaymentPackage(paymentMethod.identifier, paymentInitModel)).pipe(
                  switchMap((paymentModel) => {
                    return forkJoin({
                      payment: of(paymentModel),
                      order: this.orderDataProvider.getOrder(this.orderStateService.getOrder()?.cinemaId, this.orderStateService.getOrder()?.id),
                    });
                  }),
                  tap({
                    next: (v) => {
                      this.orderStateService.setOrder(v.order);
                      this.onPostInitPayment(v.payment);
                    },
                    error: (e) => {
                      this.paymentInitialized = false;
                      this.bridgePayToken = null;
                      this.loadingService.hideLoader(LoaderEnum.PAYMENT);
                    },
                  }),
                  finalize(() => {
                    this.paymentProviderState = PaymentProviderStateEnum.Complete;
                  })
                ),
                throwError(() => {
                  this.paymentProviderState = PaymentProviderStateEnum.Error;
                  this.loadingService.hideLoader(LoaderEnum.PAYMENT);
                })
              )
            ),
            2000
          );
        });

    return g$;
  }

  onPreInitPayment(event: OrderPaymentPackage): Observable<OrderPaymentPackage> {
    return this.initPaymentProviderWidget().pipe(
      tap(() => {
        setTimeout(() => {
          this.createAdditionalScript();
        });
      }),
      switchMap(() => {
        return new Observable<OrderPaymentPackage>((subscriber) => {
          subscriber.next(event);
          subscriber.complete();
        });
      })
    );
  }

  private createStyles() {
    this.createStyleElement(
      `
      #payment-form {
        input {
          color: #fff;
        }

        .input-wrapper {
          color: #fff;
        }

        .valid {
          color: #fff;
        }
      }
    `,
      'customStyles'
    );
  }

  private createScript() {
    const scriptElement: HTMLElement = document.createElement('script');
    scriptElement['type'] = 'text/javascript';
    scriptElement['src'] = this.environment.constants.paymentTestMode
      ? 'https://www.bridgepaynetsecuretest.com/WebSecurity/TokenPay/js/tokenPay.js'
      : 'https://www.bridgepaynetsecuretx.com/WebSecurity/TokenPay/js/tokenPay.js';
    this.addElement(this.createScriptElement(scriptElement), this._document.head);
  }

  private createAdditionalScript() {
    const script =
      `var tokenpay = TokenPay('` +
      this.paymentProviderConfig?.providerConfiguration?.key +
      `');
    tokenpay.initialize({
        dataElement: '#card',
        errorElement: '#errorMessage',
        amountElement: '#amount',
        useStyles: true,
        useACH: false,
        disableZip: true,
        disableCvv: false
    });

    var form = document.getElementById('paymentForm');
    form.addEventListener('submit', function (event) {
        event.preventDefault();
        tokenpay.createToken(function (result) {}, function (result) {
            console.log("error: " + result);
        });
    });`;

    this.addElement(this.createScriptElement(script), this._document.head);
  }

  onPostInitPayment(paymentModel: PaymentViewModel): void {
    window.location.replace(`/${this.translationService.currentLang}/order-status?params=${this.order.id},${this.order.cinemaId}`);
    this.events.next(new DonePaymentProviderEvent());
  }

  createPaymentPackage(paymentProviderIdentifier: string, orderPaymentPackage: OrderPaymentPackage): IOrderPaymentPackage {
    orderPaymentPackage = Object.assign(new OrderPaymentPackage(), orderPaymentPackage);
    orderPaymentPackage.cinemaId = this.order.cinemaId;
    orderPaymentPackage.orderId = this.order.id;
    orderPaymentPackage.paymentProviderIdentifier = paymentProviderIdentifier;
    orderPaymentPackage.paymentToken = this.bridgePayToken.data.token;

    return orderPaymentPackage;
  }

  reload() {
    window.location.reload();
  }

  handleButtonClick() {
    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;
    this.bridgePayConfirmBtn.nativeElement.click();
  }

  handleButtonHideLoader() {
    setTimeout(() => {
      if (!this.bridgePayToken) {
        this.isSubmitting = false;
        return;
      }
    }, 1000);
  }
}
