import { inject, Injectable } from '@angular/core';
import { first, forkJoin, map, mergeMap, Observable, of, switchMap, tap } from 'rxjs';
import { CardDataProvider } from '../data-provider/card.data-provider';
import { OrderDataProvider } from '../data-provider/order.data-provider';
import { UserDataProvider } from '../data-provider/user.data-provider';
import { UseCardRequestModel } from '../model/request/use-card.request.model';
import { OrderViewModel } from '../model/view-model/order/order.view.model';
import { PaymentMethodViewModel } from '../model/view-model/order/payment-method/payment-method.view.model';
import { OrderStateService } from '../state/order.state.service';
import { OrderHttpService } from '../http/order.http.service';
import { CardPaymentEnum } from '../enum/card-payment.enum';
import { CardViewModel } from '../model/view-model/account-items/card/card.view.model';
import { CmsHelper } from '../helper/cms.helper';
import { WpOptionEnum } from 'libs/webcomponent/src/lib/enum/wp-option.enum';
import { isBitwiseFlag, WebComponentOptionsService } from '@lib/core';
import { cardType, errorCodeEnum } from '../../app.const';
import { getCardTypeNamesOfBitwiseFlag } from '../function/custom-function';
import { NotificationService } from './notification/notification.service';
import { NotificationModel } from './notification/notification.model';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType } from './notification/notification-type.enum';
import { ErrorHandlerService } from './error-handler/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class CardPaymentService {
  paymentMethods: PaymentMethodViewModel[];
  internalPaymentTypes: Map<string, string> = null;

  constructor(
    protected orderStateService: OrderStateService,
    protected orderDataProvider: OrderDataProvider,
    protected userDataProvider: UserDataProvider,
    protected cardDataProvider: CardDataProvider,
    protected cmsHelper: CmsHelper,
    protected notificationService: NotificationService,
    protected translateService: TranslateService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    if (this.cmsHelper.canUseCms) {
      const webComponentOptionsService = inject(WebComponentOptionsService);

      forkJoin({
        internalPaymentTypeIdForGiftCard: webComponentOptionsService.getWpOption$(WpOptionEnum.INTERNAL_PAYMENT_TYPE_ID_FOR_GIFTCARD),
        internalPaymentTypeIdForPrePaid: webComponentOptionsService.getWpOption$(WpOptionEnum.INTERNAL_PAYMENT_TYPE_ID_FOR_PREPAID),
      }).subscribe((data) => {
        this.internalPaymentTypes = new Map()
          .set(CardPaymentEnum.GiftCard, String(data.internalPaymentTypeIdForGiftCard))
          .set(CardPaymentEnum.Prepaid, String(data.internalPaymentTypeIdForPrePaid));
      });
    }

    const order = this.orderStateService.getOrder();
    if (order?.cinemaId && order?.id) {
      this.orderDataProvider
        .getPaymentMethodList(order.cinemaId, order.id)
        .pipe(first())
        .subscribe((s) => (this.paymentMethods = s));
    }
  }

  canUse(cardType: number) {
    if (this.orderStateService.useIsDisabled()) {
      return false;
    }

    return this.cardPaymentAvailable(cardType);
  }

  cardPaymentAvailable(cardType: number) {
    const order = this.orderStateService.getOrder();
    if (!order?.cinemaId || !order?.id) {
      return false;
    }

    return this.paymentMethods?.some((p) => getCardTypeNamesOfBitwiseFlag(cardType).includes(p.name)) || !!this.getPaymentTypeIdForCms(cardType);
  }

  getPaymentTypeIdByCardType(cardType: number) {
    const result = this.paymentMethods?.find((p) => getCardTypeNamesOfBitwiseFlag(cardType).includes(p.name))?.id;
    return result ?? this.getPaymentTypeIdForCms(cardType);
  }

  getPaymentTypeIdForCms(cardType: number) {
    if (this.internalPaymentTypes) {
      if (this.isPrepaid(cardType)) {
        return this.internalPaymentTypes.get(CardPaymentEnum.Prepaid);
      } else if (this.isGiftCard(cardType)) {
        return this.internalPaymentTypes.get(CardPaymentEnum.GiftCard);
      }
    }

    return null;
  }

  useCard(cardId: string, cardType: number): Observable<OrderViewModel> {
    const order = this.orderStateService.getOrder();
    if (!order?.cinemaId || !order?.id) {
      return of(null);
    }

    const request = [
      ...order.paymentMethods?.filter((f) => f.cardId).map((m) => new UseCardRequestModel(m.cardId, m.id)),
      this.makeUseCardRequestModel(cardId, cardType),
    ];

    OrderHttpService.cacheBuster$.next();
    return this.completeMissingCardTypeId(request).pipe(
      switchMap((completedRequest) => this.cardDataProvider.useCards(order.cinemaId, order.id, completedRequest)),
      tap({
        next: (order) => {
          this.orderStateService.setOrder(order);
        },
      })
    );
  }

  useCards(request: UseCardRequestModel[]): Observable<OrderViewModel> {
    const order = this.orderStateService.getOrder();
    if (!order?.cinemaId || !order?.id) {
      return of(null);
    }

    const cardsFromOrder = order.paymentMethods?.filter((f) => f.cardId).map((m) => new UseCardRequestModel(m.cardId, m.id));
    const fullRequest = [
      ...cardsFromOrder,
      ...request.filter((f) => !cardsFromOrder.some((c) => c.cardId === f.cardId)).map((m) => new UseCardRequestModel(m.cardId, m.typeId)),
    ];

    OrderHttpService.cacheBuster$.next();

    return this.completeMissingCardTypeId(fullRequest).pipe(
      switchMap((completedRequest) => this.cardDataProvider.useCards(order.cinemaId, order.id, completedRequest)),
      tap({
        next: (order) => {
          this.orderStateService.setOrder(order);
        },
      })
    );
  }

  useCardByNumber(cardNumber: string) {
    const order = this.orderStateService.getOrder();
    if (!order?.cinemaId || !order?.id) {
      return of(null);
    }

    return this.cardDataProvider.info(cardNumber).pipe(
      switchMap((cardInfo) => {
        const request = [
          ...order.paymentMethods?.filter((f) => f.cardId).map((m) => new UseCardRequestModel(m.cardId, m.id)),
          this.makeUseCardRequestModel(cardInfo.id, cardInfo.type),
        ];

        OrderHttpService.cacheBuster$.next();
        return this.cardDataProvider.useCards(order.cinemaId, order.id, request).pipe(
          tap({
            next: (order) => {
              this.orderStateService.setOrder(order);
            },
          })
        );
      })
    );
  }

  completeMissingCardTypeId(request: UseCardRequestModel[]) {
    const missingCardTypes = request.filter((el) => !el.typeId);
    if (!missingCardTypes?.length) {
      return of(request);
    }

    return forkJoin(
      missingCardTypes.map((el) =>
        this.cardDataProvider.info(el.cardId).pipe(
          map((cardInfo) =>
            cardInfo.paymentMethods?.length && cardInfo.type === 32
              ? {
                  ...el,
                  typeId: cardInfo.paymentMethods[0].id,
                }
              : el
          )
        )
      )
    );
  }

  public makeUseCardRequestModel(cardId: string, cardTypeNumber: number) {
    return new UseCardRequestModel(cardId, this.getPaymentTypeIdByCardType(cardTypeNumber));
  }

  public isPrepaid(type: number) {
    return isBitwiseFlag(type, cardType.prepaid.value);
  }

  public isGiftCard(type: number) {
    return isBitwiseFlag(type, cardType.gift.value);
  }

  public isDiscountCard(type: number) {
    return isBitwiseFlag(type, cardType.discount.value);
  }

  public isLoyalty(type: number) {
    return isBitwiseFlag(type, cardType.loyalty.value);
  }

  public isEmpty(card: any) {
    if (card instanceof CardViewModel) {
      return card.valueBalance <= 0;
    }
  }

  public handleCardError(e?: any) {
    const error = this.errorHandlerService.getError(e);
    let notification: string;

    switch (error?.code) {
      case errorCodeEnum.GIFT_CARD_NOT_FOUND:
        notification = this.translateService.instant('giftCardUse.error.not_found');
        break;
      case errorCodeEnum.GIFT_CARD_ALREADY_LOCKED:
        notification = this.translateService.instant('giftCardUse.error.already_locked');
        break;
      case errorCodeEnum.GIFT_CARD_USED_ON_ORDER:
        notification = this.translateService.instant('giftCardUse.error.used_on_order');
        break;
      default:
        notification = this.translateService.instant('giftCardUse.error.cannot_use');
        break;
    }

    this.notificationService.addNotification(new NotificationModel(notification, NotificationType.ALERT));
  }
}
