import { Injectable } from '@angular/core';
import { EMPTY, forkJoin, from, Observable, of, zip } from 'rxjs';
import { catchError, expand, groupBy, map, mergeAll, mergeMap, switchMap, tap, toArray } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { plainToInstance } from 'class-transformer';
import { MovieDataProvider } from './movie.data-provider';
import { RegionDataProvider } from './region.data-provider';
import { ScreeningHttpService } from '../http/screening.http.service';
import { MovieCopyHttpService } from '../http/movie-copy.http.service';
import { MovieHttpService } from '../http/movie.http.service';
import { GenreHttpService } from '../http/genre.http.service';
import { CinemaHttpService } from '../http/cinema.http.service';
import { ScreenheadHttpService } from '../http/screenhead.http.service';
import { EventDataProvider } from './event.data-provider';
import { ScreeningRequestModel } from '../model/request/screening.request.model';
import { MovieRequestModel } from '../model/request/movie.request.model';
import { MovieCopyRequestModel } from '../model/request/movie-copy.request.model';
import { OccupancyViewModel } from '../model/view-model/screening/occupancy/occupancy.view.model';
import { OccupancyApiModel } from '../model/api-model/screening/occupancy/occupancy.api.model';
import { TicketViewModel } from '../model/view-model/shared/ticket/ticket.view.model';
import { TicketApiModel } from '../model/api-model/shared/ticket/ticket.api.model';
import { ScreeningViewModel } from '../model/view-model/screening/screening.view.model';
import { ScreeningApiModel } from '../model/api-model/screening/screening.api.model';
import { MoviePrintApiModel } from '../model/api-model/movie/movie-print.api.model';
import { MovieViewModel } from '../model/view-model/movie/movie.view.model';
import { MoviePrintViewModel } from '../model/view-model/movie/movie-print.view.model';
import { ScreeningPeriodEnum } from '../model/enum/screening-period.enum';
import { IGroupingOption, IGroupingOrderGroup, IMoviePackage, IScreeningItem, IScreeningOptions } from '../interfaces';
import { EventViewModel } from '../model/view-model/event/event.view.model';
import { RegionViewModel } from '../model/view-model/region/region.view.model';
import { CinemaViewModel } from '../model/view-model/cinema/cinema.view.model';
import { ScreenheadViewModel } from '../model/view-model/screen/screen-head.view.model';
import { CinemaApiModel } from '../model/api-model/cinema/cinema.api.model';
import { ScreeningType } from '../enum/screening-type';
import { EventRequestModel } from '../model/request/event.request.model';
import { DateTimeService } from '../service/datetime.service';
import { GenreApiModel } from '../model/api-model/genre/genre.api.model';
import { GaTicketViewModel } from '../model/view-model/screening/ga/ga-ticket.view.model';
import { GaTicketApiModel } from '../model/api-model/screening/ga/ga-ticket.api.model';
import { SeparatorViewModel } from '../model/view-model/screening/separator/separator.view.model';
import { SeparatorApiModel } from '../model/api-model/screening/separator/separator.api.model';
import { ScreeningAvailabilityStatus } from '../enum/screening-availability-status.enum';
import { GenreViewModel } from '../model/view-model/genre/genre.view.model';
import { MovieApiModel } from '../model/api-model/movie/movie.api.model';
import { ScreeningObject } from '../model/screening-object.model';
import { TimeRangeObject } from '../date/time.model';
import { flatten } from 'lodash-es';
import _ from 'lodash';
import { notEmptyAndUnique } from '../helper/array.helper';
import { WordpressDataProvider } from './wordpress.data-provider';
import { ScreeningDetails } from '../wp-model/adapters';
import { AppService } from '../service/app.service';
import { appProjectName } from '../../app.const';
import { ScreenShowModel } from '../model/screenshow.model';
import { ExtendedDatePipe } from '../pipe/extended-date.pipe';
import { QuickFilterGroup } from '@lib/core';

@Injectable({
  providedIn: 'root',
})
export class ScreeningDataProvider {
  environment: any;

  constructor(
    private screeningHttpService: ScreeningHttpService,
    private movieCopyHttpService: MovieCopyHttpService,
    private movieHttpService: MovieHttpService,
    private genreHttpService: GenreHttpService,
    private cinemaHttpService: CinemaHttpService,
    private screenheadHttpService: ScreenheadHttpService,
    private eventDataProvider: EventDataProvider,
    private movieDataProvider: MovieDataProvider,
    private regionDataProvider: RegionDataProvider,
    private dateTimeService: DateTimeService,
    private wordpressDataProvider: WordpressDataProvider,
    private appService: AppService,
    private extendedDatePipe: ExtendedDatePipe
  ) {}

  list(
    screeningRequestModel: ScreeningRequestModel,
    movieCopyRequestModel: MovieCopyRequestModel,
    movieRequestModel: MovieRequestModel
  ): Observable<MoviePrintViewModel[]> {
    const result = forkJoin([
      this.screeningHttpService.getCinemaScreenings(screeningRequestModel).pipe(map((models) => models.map((m) => new ScreeningViewModel(m)))),
      this.movieCopyHttpService.getMoviePrints(movieCopyRequestModel).pipe(map((models) => models.map((m) => new MoviePrintViewModel(m)))),
      this.movieHttpService.getMovies(movieRequestModel).pipe(map((models) => models.map((m) => new MovieViewModel(m)))),
    ]);

    return result.pipe(
      map(([screeningsResponse, movieCopiesResponse, moviesResponse]: [ScreeningViewModel[], MoviePrintViewModel[], MovieViewModel[]]) => {
        return movieCopiesResponse.filter((item) => {
          item.movie = moviesResponse.find((movie) => movie.id === item.movieId);
          item.screenings = screeningsResponse
            .sort((s) => this.dateTimeService.convertToCinemaTimeZone(s.screeningTimeFrom).toMillis())
            .filter((screening) => screening.moviePrintId === item.id);
          return item;
        });
      })
    );
  }

  getOccupancyList(cinemaId: string, id: string): Observable<OccupancyViewModel> {
    return this.screeningHttpService.getCinemaScreeningOccupancy(cinemaId, id).pipe(
      catchError((err) => {
        throw err;
      }),
      map((res: OccupancyApiModel) => new OccupancyViewModel(res))
    );
  }

  getScreeningsByMovieId(cinemaId: string, movieId: string, dateTimeFrom: string, dateTimeTo: string): Observable<ScreeningViewModel[]> {
    return this.screeningHttpService
      .getCinemaMovieScreenings(cinemaId, movieId, dateTimeFrom, dateTimeTo)
      .pipe(map((models) => models.map((model) => new ScreeningViewModel(model))));
  }

  getTicketListViaApiModel(cinemaId: string, id: string, seatIds: Array<string>): Observable<TicketViewModel[]> {
    return this.screeningHttpService.getCinemaScreeningTickets(cinemaId, id, seatIds).pipe(map((models) => models.map((model) => new TicketViewModel(model))));
  }

  getTicketList(cinemaId: string, id: string, seatIds: Array<string>): Observable<TicketViewModel[]> {
    return this.screeningHttpService.getCinemaScreeningTickets(cinemaId, id, seatIds).pipe(
      map((ticketApiModels: TicketApiModel[]) => {
        return ticketApiModels.map((ticketApiModel) => new TicketViewModel(ticketApiModel));
      })
    );
  }

  getTicketListGeneralAdmission(cinemaId: string, screeningId: string): Observable<GaTicketViewModel[]> {
    const allowFreeTickets = this.appService.isProject(appProjectName.KINOTEKA);

    return this.screeningHttpService.getCinemaScreeningGaTickets(cinemaId, screeningId).pipe(
      map((gaTicketApiModels: GaTicketApiModel[]) => {
        return gaTicketApiModels
          .filter((c: GaTicketViewModel) => allowFreeTickets || c.price != 0)
          .map((gaTicketApiModel) => new GaTicketViewModel(gaTicketApiModel));
      })
    );
  }

  getRowSeparators(cinemaId: string, id: string): Observable<SeparatorViewModel[]> {
    return this.screeningHttpService.getCinemaScreeningSeparators(cinemaId, id).pipe(
      map((models: SeparatorApiModel[]) => {
        return models.map((model) => new SeparatorViewModel(model));
      })
    );
  }

  findScreeningByIdViaApiModel(cinemaId: string, id: string): Observable<ScreeningViewModel> {
    return this.screeningHttpService.getCinemaScreening(cinemaId, id).pipe(
      map((res) => plainToInstance(ScreeningApiModel, res as object)),
      map((res) => new ScreeningViewModel(res))
    );
  }

  findById(cinemaId: string, id: string): Observable<MoviePrintViewModel> {
    return this.screeningHttpService.getCinemaScreening(cinemaId, id).pipe(
      mergeMap((screeningResponse) => {
        return this.movieCopyHttpService.getMoviePrint(screeningResponse.moviePrintId).pipe(
          switchMap((moviePrintApiModel: MoviePrintApiModel) =>
            forkJoin([this.movieHttpService.getMovie(moviePrintApiModel.movieId), this.genreHttpService.getGenres()]).pipe(
              map(([movieResponse, genreResponse]: [MovieApiModel, GenreApiModel[]]) => {
                const moviePrintViewModel = new MoviePrintViewModel(moviePrintApiModel);
                moviePrintViewModel.screenings = [new ScreeningViewModel(screeningResponse)];
                moviePrintViewModel.movie = new MovieViewModel(movieResponse);

                const includeGenres = genreResponse.filter((genreResponseModel: GenreApiModel) => {
                  return movieResponse.genres.map((m) => m.id).includes(genreResponseModel.id);
                });

                moviePrintViewModel.movie.genres = includeGenres.map((m) => new GenreViewModel(m));
                return moviePrintViewModel;
              })
            )
          )
        );
      })
    );
  }

  // via API model
  findByIdViaApiModel(cinemaId: string, id: string) {
    return this.screeningHttpService.getCinemaScreening(cinemaId, id).pipe(
      map((res) => plainToInstance(ScreeningApiModel, res as object)),
      mergeMap((screeningResponse) => {
        return this.movieCopyHttpService.getMoviePrint(screeningResponse.moviePrintId).pipe(
          switchMap((movieCopyResponse: MoviePrintApiModel) =>
            this.movieDataProvider.getMovieById(movieCopyResponse.movieId).pipe(
              map((movie: MovieViewModel) => {
                return {
                  copy: new MoviePrintViewModel(movieCopyResponse),
                  screenings: new ScreeningViewModel(screeningResponse),
                  screen: null,
                  movie: movie,
                  movieInfo: null,
                };
              })
            )
          )
        );
      })
    );
  }

  listNearestByMovieId(movieId: string, cinemaId: string, dateFrom: string, dateTo: string) {
    return this.screeningHttpService
      .getCinemaMovieScreenings(cinemaId, movieId, dateFrom, dateTo)
      .pipe(
        map((models: ScreeningApiModel[]) => models.map((model: ScreeningApiModel) => this.makeScreeningItem(Object.assign(new ScreenShowModel(), model))))
      );
  }

  listNearestByEventId(eventId: string, cinemaId: string, dateFrom: DateTime, dateTo: DateTime) {
    const screeningRequestModel = new ScreeningRequestModel(null, cinemaId, dateFrom, dateTo);
    let screeningsSource = this.screeningHttpService
      .getCinemaScreenings(screeningRequestModel)
      .pipe(map((res) => plainToInstance(ScreeningApiModel, res as object[])));

    return this.eventDataProvider.getCinemaEventList(new EventRequestModel(cinemaId, null, dateFrom.startOf('day'), dateTo.endOf('day'), eventId)).pipe(
      map((events) => {
        return events.map((event) => this.makeScreeningItemByEvent(event));
      })
    );
  }

  listByRegion(screeningRequestModel: ScreeningRequestModel) {
    return this.screeningHttpService.getCinemaScreenings(screeningRequestModel).pipe(
      map((res) => plainToInstance(ScreeningApiModel, res as object[], { strategy: 'excludeAll' })),
      map((models: ScreeningApiModel[]) => models.map((model: ScreeningApiModel) => new ScreeningViewModel(model)))
    );
  }

  getGroupedByCinemaId(screeningRequestModel: ScreeningRequestModel) {
    return this.screeningHttpService.getRegionScreenings(screeningRequestModel).pipe(
      map((res) => plainToInstance(ScreeningApiModel, res as object[], { strategy: 'excludeAll' })),
      mergeAll(),
      groupBy((item) => item.cinemaId),
      mergeMap((group) => zip(of(group.key), group.pipe(toArray())))
    );
  }

  getGrouped(regionId: string, startAt: DateTime, period: ScreeningPeriodEnum): Observable<IMoviePackage[]> {
    const dateFrom = startAt;
    const dateTo = period === ScreeningPeriodEnum.WEEK ? dateFrom.plus({ days: 7 }) : dateFrom;
    const screeningRequestModel = new ScreeningRequestModel(regionId, null, dateFrom, dateTo);

    return this.getGroupedByCinemaId(screeningRequestModel).pipe(
      mergeMap((groupByCinemaId) => {
        const cinemaId = groupByCinemaId[0],
          screenings = groupByCinemaId[1];
        const movieCopyRequestModel = new MovieCopyRequestModel(cinemaId, dateFrom, dateTo);
        const movieRequestModel = new MovieRequestModel(cinemaId, dateFrom, dateTo);

        return forkJoin({
          movieCopies: this.movieCopyHttpService.getMoviePrints(movieCopyRequestModel),
          movies: this.movieDataProvider.getMovies(movieRequestModel),
          cinemas: this.cinemaHttpService.getCinemas(),
          genres: this.genreHttpService.getGenres(),
        }).pipe(
          map((source) => {
            const cinema = source.cinemas.find((o) => o.id === cinemaId);

            return source.movieCopies.map((moviePrint) =>
              this.makeMoviePackage(
                moviePrint,
                source.movies.find((movie) => movie.id === moviePrint.movieId),
                cinema,
                screenings
              )
            );
          }),
          catchError((o) => {
            return of([] as IMoviePackage[]);
          })
        );
      })
    );
  }

  getScreeningDetailsById(cinemaId: string, screeningId: string, events?: EventViewModel[], region?: RegionViewModel): Observable<ScreeningDetails> {
    const event = events?.find((x) => x.screeningId === screeningId);
    const screening = event
      ? of(event)
      : this.screeningHttpService
          .getCinemaScreening(cinemaId, screeningId)
          .pipe(map((res) => plainToInstance(ScreeningApiModel, res as object, { strategy: 'excludeAll' })));

    return forkJoin({ screening }).pipe(
      switchMap((predata) => {
        return forkJoin({
          moviePrint: predata.screening instanceof EventViewModel ? of(null) : this.movieCopyHttpService.getMoviePrint(predata.screening.moviePrintId),
          movie:
            predata.screening instanceof EventViewModel
              ? of(
                  Object.assign(new MovieViewModel(), {
                    genres: predata.screening.genres,
                    title: predata.screening.title,
                    duration: predata.screening.duration,
                    posters: predata.screening.posters,
                    ratings: predata.screening.ratings,
                  })
                )
              : this.movieDataProvider.getMovieById(predata.screening.movieId),
          event: of(event),
          cinema: this.cinemaHttpService.getCinemaById(predata.screening.cinemaId),
          region: !region ? this.regionDataProvider.getRegionByCinemaId(predata.screening.cinemaId) : of(region),
          screen: this.screenheadHttpService.getScreenHead(predata.screening.cinemaId, predata.screening.screenId).pipe(catchError((err) => of(null))),
        }).pipe(
          map(
            (data) =>
              new ScreeningDetails(
                data.movie,
                data.event,
                predata.screening instanceof EventViewModel ? null : new MoviePrintViewModel(data.moviePrint),
                data.region,
                new CinemaViewModel(data.cinema),
                new ScreeningViewModel(
                  predata.screening instanceof EventViewModel
                    ? Object.assign(new ScreeningApiModel(), {
                        id: predata.screening.screeningId,
                        screeningTimeFrom: predata.screening.timeFrom,
                        availabilityStatus: predata.screening.availabilityStatus,
                      })
                    : predata.screening
                ),
                new ScreenheadViewModel(data.screen)
              )
          )
        );
      })
    );
  }

  private makeScreeningItem(model: ScreenShowModel): IScreeningItem {
    return {
      id: model.screeningId,
      cinemaId: model.cinemaId,
      screenId: model.screenId,
      startAt: model.timeFrom,
      saleTimeTo: model.saleTimeTo,
      reservationTimeTo: model.reservationTimeTo,
      printType: model.printType,
      content: model,
      inactive: model.availabilityStatus === ScreeningAvailabilityStatus.ForPreview,
    } as IScreeningItem;
  }

  private makeScreeningItemByEvent(model: EventViewModel): IScreeningItem {
    return {
      id: model.id,
      cinemaId: model.cinemaId,
      screenId: model.screenId,
      startAt: model.timeFrom,
      saleTimeTo: model.saleTimeTo,
      reservationTimeTo: model.reservationTimeTo,
      printType: model.movieCopy?.printType,
      content: model,
      inactive: model.availabilityStatus === ScreeningAvailabilityStatus.ForPreview,
    } as IScreeningItem;
  }

  private makeMoviePackage(moviePrintApi: MoviePrintApiModel, movie: MovieViewModel, cinemaApi: CinemaApiModel, screeningApiModelList: ScreeningApiModel[]) {
    return {
      moviePrint: new MoviePrintViewModel(moviePrintApi),
      movie: movie,
      cinema: new CinemaViewModel(cinemaApi),
      screenings: screeningApiModelList
        .filter((o) => o.moviePrintId === moviePrintApi.id)
        .map((o) => this.makeScreeningItem(Object.assign(new ScreenShowModel(), o))),
    } as IMoviePackage;
  }

  getScreenings(regionId: string, cinemaId: string, startAt: DateTime, daysRange: number, option?: IScreeningOptions) {
    const dateFrom = startAt.startOf('day').plus({ hours: option?.cinemaDayOffset ?? 0 });
    const dateTo = startAt
      .plus({ days: daysRange })
      .endOf('day')
      .plus({ hours: option?.cinemaDayOffset ?? 0 });

    return this.getScreeningsObservable(regionId, cinemaId, dateFrom, dateTo, option);
  }

  getScreeningsByCinema(cinemaId: string, startAt: DateTime, period: ScreeningPeriodEnum, option?: IScreeningOptions) {
    const dateFrom = startAt.startOf('day').plus({ hours: option?.cinemaDayOffset ?? 0 });
    const dateTo = startAt
      .plus({ days: period })
      .endOf('day')
      .plus({ hours: option?.cinemaDayOffset ?? 0 });

    return this.getScreeningsObservable(null, cinemaId, dateFrom, dateTo, option);
  }

  getScreeningsByRegion(regionId: string, cinemaId: string, startAt: DateTime, period: ScreeningPeriodEnum, option?: IScreeningOptions) {
    const dateFrom = startAt.startOf('day').plus({ hours: option?.cinemaDayOffset ?? 0 });
    const dateTo = startAt
      .plus({ days: period })
      .endOf('day')
      .plus({ hours: option?.cinemaDayOffset ?? 0 });

    return this.getScreeningsObservable(regionId, cinemaId, dateFrom, dateTo, option);
  }

  getScreeningsByTimeRange(
    regionId: string,
    cinemaId: string,
    startAt: DateTime,
    period: ScreeningPeriodEnum,
    timeRangeObject: TimeRangeObject,
    option?: IScreeningOptions
  ) {
    const dateFrom = startAt.startOf('day').plus({ hours: timeRangeObject?.from?.hour ?? 0, minute: timeRangeObject?.from?.minute ?? 0 });
    const dateTo = startAt
      .plus({ days: period })
      .startOf('day')
      .plus({ hours: timeRangeObject?.to?.hour ?? 0, minute: timeRangeObject?.to?.minute ?? 0 });

    return this.getScreeningsObservable(regionId, cinemaId, dateFrom, dateTo, option).pipe(
      tap((data: ScreeningObject[]) => {
        data.forEach((s) => {
          s.screenings = s.screenings.filter(
            (s) =>
              this.dateTimeService.convertToCinemaTimeZone(s.timeFrom) >=
                this.dateTimeService.convertToCinemaTimeZone(
                  DateTime.fromObject({
                    year: s.timeFrom.year,
                    month: s.timeFrom.month,
                    day: s.timeFrom.day,
                    hour: timeRangeObject?.from?.hour,
                    minute: timeRangeObject?.from?.minute,
                  })
                ) &&
              this.dateTimeService.convertToCinemaTimeZone(s.timeFrom) <=
                this.dateTimeService.convertToCinemaTimeZone(
                  DateTime.fromObject({
                    year: s.timeFrom.year,
                    month: s.timeFrom.month,
                    day: s.timeFrom.day,
                    hour: timeRangeObject?.to?.hour,
                    minute: timeRangeObject?.to?.minute,
                  })
                )
          );
        });
      })
    );
  }

  private getScreeningsObservable(regionId: string, cinemaId: string, dateFrom: DateTime, dateTo: DateTime, option: IScreeningOptions) {
    const screeningRequestModel = new ScreeningRequestModel(regionId, cinemaId, dateFrom, dateTo, option);
    let screenings: Observable<ScreeningViewModel[]>;
    let events: Observable<EventViewModel[]>;
    let shows: Observable<ScreenShowModel[]>;

    switch (option.type) {
      case ScreeningType.MOVIE:
        screenings = this.getScreeningsObjects(regionId, cinemaId, screeningRequestModel).pipe(
          map((items) => items.filter((item) => !option?.id || item.movieId === option?.id))
        );
        events = of([] as EventViewModel[]);
        break;
      case ScreeningType.EVENT:
        screenings = of([] as ScreeningViewModel[]);
        events = this.getEventsObjects(new EventRequestModel(cinemaId, regionId, dateFrom.startOf('day'), dateTo.endOf('day'), option?.id, false));
        break;
      default:
        screenings = this.getScreeningsObjects(regionId, cinemaId, screeningRequestModel);
        events = this.getEventsObjects(
          new EventRequestModel(cinemaId, regionId, dateFrom.startOf('day'), dateTo.endOf('day'), option.type === ScreeningType.EVENT ? option.id : null, false)
        );
        break;
    }

    shows = forkJoin({
      screenings: screenings,
      events: events,
    }).pipe(
      map((result) => [...result.screenings, ...result.events].map((show) => new ScreenShowModel(show))),
      // map((data) => data.filter((s) => s.id === '6e485320-6c8b-4e2f-9812-fe3a75d0c7e2')),
      map((shows) =>
        shows
          .filter((f) => f.timeFrom >= screeningRequestModel.dateTimeFrom && f.timeFrom <= screeningRequestModel.dateTimeTo)
          .sort((a, b) => a.timeFrom.valueOf() - b.timeFrom.valueOf())
      )
    );

    return this.createScreeningsStructure(shows, dateFrom, dateTo, option);
  }

  private getEventsObjects(requestModel: EventRequestModel) {
    let eventsSource: Observable<EventViewModel[]>;

    if (requestModel.cinemaId) {
      eventsSource = this.eventDataProvider.getEventsByCinema(requestModel);
    } else if (requestModel.regionId) {
      eventsSource = this.eventDataProvider.getEventsByRegion(requestModel);
    } else {
      eventsSource = this.eventDataProvider.getEvents(requestModel);
    }

    return eventsSource;
  }

  private getScreeningsObjects(regionId: string, cinemaId: string, screeningRequestModel: ScreeningRequestModel) {
    let screeningsSource: Observable<ScreeningApiModel[]>;

    if (cinemaId) {
      screeningsSource = this.screeningHttpService.getCinemaScreenings(screeningRequestModel);
    } else if (regionId) {
      screeningsSource = this.screeningHttpService.getRegionScreenings(screeningRequestModel);
    } else {
      screeningsSource = this.screeningHttpService.getScreenings(screeningRequestModel);
    }

    return screeningsSource.pipe(map((models) => models.map((m) => new ScreeningViewModel(m))));
  }

  public createScreeningsStructure(
    shows: Observable<ScreenShowModel[]>,
    dateFrom: DateTime,
    dateTo: DateTime,
    option: IScreeningOptions
  ): Observable<ScreeningObject[]> {
    return shows.pipe(
      switchMap((shows) => {
        const uniqueRegionIds = [...new Set(shows.map((o) => o.regionId))].filter((f) => f);
        const uniqueCinemaIds = [...new Set(shows.map((o) => o.cinemaId))];
        const uniqueDays = [
          ...new Map<string, { start: DateTime; end: DateTime }>(
            shows.map((o) => [
              o.timeFrom
                .minus({ hours: option?.cinemaDayOffset ?? 0 })
                .startOf('day')
                .toISODate(),
              {
                start: o.timeFrom
                  .minus({ hours: option?.cinemaDayOffset ?? 0 })
                  .startOf('day')
                  .plus({ hours: option?.cinemaDayOffset ?? 0 }),
                end: o.timeFrom
                  .minus({ hours: option?.cinemaDayOffset ?? 0 })
                  .endOf('day')
                  .plus({ hours: option?.cinemaDayOffset ?? 0 }),
              },
            ])
          ),
        ];

        return this.wordpressDataProvider.getRegions().pipe(
          mergeMap((regions) => regions.filter((r) => uniqueRegionIds.length === 0 || uniqueRegionIds?.includes(r.id))),
          mergeMap((region) => {
            return this.wordpressDataProvider.getCinemas().pipe(
              mergeMap((cinemas) => cinemas.filter((c) => uniqueCinemaIds.includes(c.id) && c.regionId === region.id)),
              mergeMap((cinema) => {
                //cinema
                const movieCopyRequestModel = new MovieCopyRequestModel(cinema.id, dateFrom, dateTo);
                const movieRequestModel = new MovieRequestModel(cinema.id, dateFrom, dateTo);
                const showsByCinema = shows.filter((s) => s.cinemaId === cinema.id);

                return forkJoin({
                  movies: this.movieDataProvider.getMovies(movieRequestModel),
                  moviePrints: this.movieCopyHttpService.getMoviePrints(movieCopyRequestModel),
                  screens: this.screenheadHttpService.getScreenHeads(cinema.id).pipe(map((screens) => screens.sort((a, b) => a.number - b.number))),
                }).pipe(
                  mergeMap((data) => {
                    return from(data.screens).pipe(
                      mergeMap((screen) => {
                        const screeningsByScreen = showsByCinema.filter((s) => !s.isEvent() && s.screenId === screen.id);
                        const eventsByScreen = showsByCinema.filter((s) => s.isEvent() && s.screenId === screen.id);

                        return from(uniqueDays).pipe(
                          mergeMap((dayRange) => {
                            const dayRangeContent = dayRange[1];
                            //day
                            const showsByDay = screeningsByScreen
                              .filter((o) => o.timeFrom >= dayRangeContent.start && o.timeFrom <= dayRangeContent.end)
                              .sort();
                            const uniqueMovieIds = [...new Set(showsByDay.filter((f) => !f.isEvent()).map((o) => o.id))];
                            const movies = data.movies.filter((o) => uniqueMovieIds.includes(o.id));
                            const events = [
                              ...eventsByScreen
                                .map((f) => f.model as EventViewModel)
                                .filter((o) => o.timeFrom >= dayRangeContent.start && o.timeFrom <= dayRangeContent.end)
                                .reduce((a, c) => {
                                  a.set(c.id, c);
                                  return a;
                                }, new Map())
                                .values(),
                            ];

                            return from([...movies, ...events]).pipe(
                              mergeMap((obj) => {
                                if (obj instanceof EventViewModel) {
                                  const screeningsByEvent = eventsByScreen
                                    .filter((f) => f.id === obj.id && f.timeFrom >= dayRangeContent.start && f.timeFrom <= dayRangeContent.end)
                                    .sort((a, b) => a.saleTimeTo.toMillis() - b.saleTimeTo.toMillis());

                                  return of(
                                    Object.assign(new ScreeningObject(), {
                                      showid: obj.id,
                                      regionid: region.id,
                                      cinemaid: cinema.id,
                                      screenid: screen.id,
                                      dayRange,
                                      region: region,
                                      cinema: cinema,
                                      show: obj,
                                      screen: screen,
                                      screenings: screeningsByEvent,
                                    })
                                  );
                                } else {
                                  //movie
                                  const screeningsByMovie = showsByDay.filter((c) => !c.isEvent() && c.id === obj.id);
                                  const uniqueMoviePrintIds = [...new Set(screeningsByMovie.map((o) => o.moviePrintId))];
                                  const moviePrints = data.moviePrints.filter((o) => uniqueMoviePrintIds.includes(o.id));

                                  return moviePrints.map((moviePrint) => {
                                    return Object.assign(new ScreeningObject(), {
                                      showid: obj.id,
                                      regionid: region.id,
                                      cinemaid: cinema.id,
                                      screenid: screen.id,
                                      movieprintid: moviePrint.id,
                                      dayRange,
                                      region: region,
                                      cinema: cinema,
                                      show: obj,
                                      moviePrint: moviePrint,
                                      screen: screen,
                                      screenings: screeningsByMovie.filter((c) => c.moviePrintId === moviePrint.id),
                                    });
                                  });
                                }
                              })
                            );
                          })
                        );
                      })
                    );
                  })
                );
              })
            );
          })
        );
      }),
      toArray()
    );
  }

  isFamilyAgeRestrictions = (show: MovieViewModel | EventViewModel) => {
    return (
      show.ratings.filter((rating) => {
        if (!rating.value) {
          return false;
        }

        const split = rating.value.split('/');

        if (!split[0]) {
          return false;
        }

        return parseInt(split[0], 10) <= 12;
      }).length > 0
    );
  };

  isFamilyTime = (show: ScreenShowModel) => {
    return show.timeFrom.hour > 6 && show.timeFrom.hour < 18;
  };

  getGroupByOption(source: ScreeningObject[], option: any, previousOption?: any, previousGroup?: FilterableGroup): Observable<FilterableGroup[]> {
    if (option) {
      return from(source).pipe(
        groupBy(option.groupingKey, { element: option.groupingElement }),
        mergeMap((group) =>
          zip(of(group.key), group.pipe(toArray())).pipe(
            map((o) => {
              return new FilterableGroup(
                o[0],
                option?.groupingType,
                option?.groupIndex,
                option?.showTitle,
                option?.groupingTitle(o[1][0]),
                option?.showTitle && option.groupIndex > 1
                  ? [...(previousGroup?.groupTitleArray ?? []), option?.groupingTitle(o[1][0])].filter((s) => s)
                  : previousGroup?.groupTitleArray,
                previousOption?.showTitle
                  ? [...(previousGroup?.parentGroupTitleArray ?? []), previousGroup?.groupTitle].filter((s) => s)
                  : previousGroup?.parentGroupTitleArray,
                flatten(o[1]),
                this.groupingOptionsArray.indexOf(option) === this.groupingOptionsArray.length - 2
              );
            })
          )
        ),
        toArray()
      );
    } else {
      return of(null);
    }
  }

  private groupingOptionsArray: IGroupingOption[];
  private readonly GROUPING_OPTIONS_ARRAY = [
    {
      isConstant: true,
      isDefault: true,
      groupIndex: 0,
      groupingType: 'movie',
      groupingKey: (o) => o.show.id,
      groupingTitle: (o) => o.show.title,
      showTitle: false,
      groupingElement: undefined,
    },
    {
      isConstant: false,
      isDefault: false,
      groupIndex: 0,
      groupingType: 'date',
      groupingKey: (o) => o.dayRange[0],
      groupingTitle: (o) => this.extendedDatePipe.transform(o.dayRange[0], 'DATE_SHORT'),
      showTitle: false,
      groupingElement: undefined,
    },
    {
      isConstant: false,
      isDefault: false,
      groupIndex: 0,
      groupingType: 'region',
      groupingKey: (o) => o.region.id,
      groupingTitle: (o) => o.region.name,
      showTitle: false,
      groupingElement: undefined,
    },
    {
      isConstant: false,
      isDefault: true,
      groupIndex: 0,
      groupingType: 'cinema',
      groupingKey: (o) => o.cinema.id,
      groupingTitle: (o) => o.cinema.name,
      showTitle: false,
      groupingElement: undefined,
    },
    {
      isConstant: false,
      isDefault: true,
      groupIndex: 0,
      groupingType: 'screen',
      groupingKey: (o) => o.screen.id,
      groupingTitle: (o) => o.screen.name,
      showTitle: true,
      groupingElement: undefined,
    },
    {
      isConstant: false,
      isDefault: false,
      groupIndex: 0,
      groupingType: 'moviePrint',
      groupingKey: (o) => (o.show.isEvent ? o.showid : o.moviePrint.id),
      groupingTitle: (o) => (o.show.isEvent ? '' : o.moviePrint.printType),
      showTitle: false,
      groupingElement: undefined,
    },
    {
      isConstant: false,
      isDefault: true,
      groupIndex: 0,
      groupingType: 'release',
      groupingKey: (o) => (o.show.isEvent ? '' : o.moviePrint.release),
      groupingTitle: (o) => (o.show.isEvent ? '' : o.moviePrint.release),
      showTitle: false,
      groupingElement: undefined,
    },
    {
      isConstant: true,
      isDefault: true,
      groupIndex: 0,
      groupingType: 'day',
      groupingKey: (o) => o.dayRange[0],
      groupingTitle: (o) => '',
      showTitle: true,
      groupingElement: (show) =>
        show.screenings.map((model: ScreenShowModel) => {
          show.moviePrint ||= { subtitles: '', language: '', printType: '', speakingType: '', release: '' };
          return new FilterableGroupItem(
            this.makeScreeningItem(model),
            [
              { group: QuickFilterGroup.CUSTOM, key: show.show.isEvent ? 'event' : 'movie' },
              ...show.show.genres.map((g) => {
                return { group: QuickFilterGroup.GENRE, key: g.id.toLowerCase() };
              }),
              { group: QuickFilterGroup.CUSTOM, key: this.isFamilyAgeRestrictions(show.show) && this.isFamilyTime(model) ? 'family' : 'adult' },
              { group: QuickFilterGroup.CUSTOM, key: show.moviePrint.subtitles ? 'subtitles' : undefined },
              { group: QuickFilterGroup.CINEMA, key: show.cinema.id.toLowerCase() },
              ...show.screen.feature.split(',').map((o) => {
                return { group: QuickFilterGroup.SCREEN_FEATURE, key: o.trim().toLowerCase() };
              }),
              { group: QuickFilterGroup.LANGUAGE, key: show.moviePrint.language?.toLowerCase() },
              { group: QuickFilterGroup.PRINT_TYPE, key: show.moviePrint.printType?.toLowerCase() },
              { group: QuickFilterGroup.CUSTOM, key: show.moviePrint.speakingType?.toLowerCase() },
              { group: QuickFilterGroup.CUSTOM, key: show.moviePrint.release?.trim().toLowerCase() },
              ...show.show.tagGroups?.map((t) => {
                return { group: QuickFilterGroup.TAG, key: t.symbol.toLowerCase() };
              }),
              ...show.show.tagGroups
                ?.map((t) =>
                  t.tags.map((tag) => {
                    return { group: QuickFilterGroup.TAG, key: tag.symbol.toLowerCase() };
                  })
                )
                .flat(),
            ].filter((f) => f.key && f.key !== ''),
            show
          );
        }),
    },
  ];

  getNextGroupingOption(groupingType?: string) {
    if (!groupingType) {
      return this.groupingOptionsArray[0];
    }
    const i = this.groupingOptionsArray.map((e) => e.groupingType).indexOf(groupingType);
    return this.groupingOptionsArray[i + 1];
  }

  getCurrentGroupingOption(groupingType?: string) {
    if (!groupingType) {
      return this.groupingOptionsArray[0];
    }
    const i = this.groupingOptionsArray.map((e) => e.groupingType).indexOf(groupingType);
    return this.groupingOptionsArray[i];
  }

  groupScreenings(source: ScreeningObject[], groupingOrder?: IGroupingOrderGroup[]) {
    this.groupingOptionsArray = this.createGroupingOptionsArray(groupingOrder);

    return of(
      source
        .filter((m) => m.screenings.length > 0)
        .sort((a, b) => {
          return a.show.priority - b.show.priority || a.show.title.localeCompare(b.show.title);
        })
    ).pipe(
      switchMap((screeningObjects) =>
        this.getGroupByOption(screeningObjects, this.getNextGroupingOption()).pipe(
          expand((filterableGroups) => {
            if (!filterableGroups) {
              return EMPTY;
            }

            return from(filterableGroups).pipe(
              switchMap((group) =>
                this.getGroupByOption(group.items, this.getNextGroupingOption(group.groupType), this.getCurrentGroupingOption(group.groupType), group).pipe(
                  tap((t) => {
                    group.subGroups = t;
                  })
                )
              )
            );
          }),
          toArray()
        )
      ),
      map((allGroups) => allGroups[0]),
      tap((groups) => {
        this.sortScreenings(groups);
      })
    );
  }

  createGroupingOptionsArray(groupingOrders?: IGroupingOrderGroup[]): IGroupingOption[] {
    if (!groupingOrders?.length) {
      let defaultGroups = this.GROUPING_OPTIONS_ARRAY.filter((f) => f.isDefault);
      defaultGroups.forEach((g, i) => {
        g.groupIndex = i;
      });
      return defaultGroups;
    }

    let result = [];

    for (let i = 0; i < this.GROUPING_OPTIONS_ARRAY.length; i++) {
      if (!groupingOrders?.length || this.GROUPING_OPTIONS_ARRAY[i].isConstant) {
        result.push(this.GROUPING_OPTIONS_ARRAY[i]);
      }

      if (i === 0) {
        for (let j = 0; j < groupingOrders?.length; j++) {
          let groupOption = this.GROUPING_OPTIONS_ARRAY.filter((g) => g.groupingType === groupingOrders[j].group)[0];
          if (groupOption) {
            groupOption.showTitle = groupingOrders[j].showDescription === 'true';
            result.push(groupOption);
          }
        }
      }
    }

    result.forEach((g, i) => {
      g.groupIndex = i;
    });

    return result;
  }

  sortScreenings(groups: FilterableGroup[]) {
    groups.forEach((g) => {
      if (g.subGroups) {
        this.sortScreenings(g.subGroups);
      } else {
        (g.items as any[]).sort((a, b) => {
          return DateTime.fromISO(a.content.startAt).toMillis() - DateTime.fromISO(b.content.startAt).toMillis();
        });
      }
    });
  }
}

export interface IFilterableGroupItem {
  content: any;
  attributes: string[];
  collapse: boolean;
  visible: boolean;
}

export class FilterableGroup {
  subGroups?: FilterableGroup[];
  collapse: boolean = false;
  visible: boolean = true;
  constructor(
    public key: any,
    public groupType: string,
    public groupIndex: number,
    public showTitle: boolean,
    public groupTitle: string,
    public groupTitleArray: string[],
    public parentGroupTitleArray: string[],
    public items?: ScreeningObject[],
    public isOneBeforeLast?: boolean
  ) {}

  showAgeRatingPerCinema(): boolean {
    let ageRestriction: string;

    if (!this.subGroups) {
      return false;
    }

    for (let sg of this.subGroups) {
      if (sg.groupType !== 'cinema') {
        return false;
      }

      const defaultScreening = sg.items[0];
      const ar = defaultScreening?.show.ageRestriction(defaultScreening.cinema.groupId);

      if ((ageRestriction && ageRestriction !== ar) || defaultScreening.show.ratings.map((r) => r.cinemaGroupId).length > 1) {
        return true;
      }

      if (!ageRestriction) {
        ageRestriction = ar;
      }
    }

    return false;
  }
}

export class FilterableGroupItem implements IFilterableGroupItem {
  constructor(public content: any, public attributes: any[] = [], public screeningObject?: ScreeningObject) {}
  collapse: boolean = false;
  visible: boolean = true;
}
