import { Expose, Type } from 'class-transformer';
import { UserVoucherTransferApiModel } from './voucher-transfer/user-voucher-transfer.api.model';
import { UserCardTransferApiModel } from './card-transfer/user-card-transfer.api.model';
import { UserSalesDocumentApiModel } from './salesdocument/user-salesdocument.api.model';
import { UserTicketApiModel } from './ticket/user-ticket.api.model';
import { UserMoviePrintApiModel } from './movie-print/user-movie-print.api.model';
import { UserMovieApiModel } from './movie/user-movie.api.model';
import { UserEventApiModel } from './event/user-event.api.model';
import { UserSeatApiModel } from './seat/user-seat.api.model';
import { UserScreeningApiModel } from './screening/user-screening.api.model';
import { UserCardApiModel } from './card/user-card.api.model';
import { UserScreenApiModel } from './screen/user-screen.api.model';
import { UserCardTypeApiModel } from './card-type/user-card-type.api.model';
import { UserVoucherApiModel } from './voucher/user-voucher.api.model';
import { UserFbItemApiModel } from './fb-item/user-fb-item.api.model';
import { UserVoucherItemApiModel } from './voucher-item/user-voucher-item.api.model';
import { UserBookingApiModel } from './booking/user-booking.api.model';

export class UserHistoryApiModel {
  @Expose()
  @Type(() => UserSalesDocumentApiModel)
  salesDocuments: UserSalesDocumentApiModel[];

  @Expose()
  @Type(() => UserBookingApiModel)
  bookings: UserBookingApiModel[];

  @Expose()
  @Type(() => UserTicketApiModel)
  tickets: UserTicketApiModel[];

  @Expose()
  @Type(() => UserMoviePrintApiModel)
  moviePrints: UserMoviePrintApiModel[];

  @Expose()
  @Type(() => UserMovieApiModel)
  movies: UserMovieApiModel[];

  @Expose()
  @Type(() => UserEventApiModel)
  events: UserEventApiModel[];

  @Expose()
  @Type(() => UserSeatApiModel)
  seats: UserSeatApiModel[];

  @Expose()
  @Type(() => UserScreeningApiModel)
  screenings: UserScreeningApiModel[];

  @Expose()
  @Type(() => UserScreenApiModel)
  screens: UserScreenApiModel[];

  @Expose()
  @Type(() => UserCardApiModel)
  cards: UserCardApiModel[];

  @Expose()
  @Type(() => UserCardTypeApiModel)
  cardTypes: UserCardTypeApiModel[];

  @Expose()
  @Type(() => UserCardTransferApiModel)
  cardTransfers: UserCardTransferApiModel[];

  @Expose()
  @Type(() => UserVoucherApiModel)
  vouchers: UserVoucherApiModel[];

  @Expose({ name: 'fbitems' })
  @Type(() => UserFbItemApiModel)
  fbItems: UserFbItemApiModel[];

  @Expose()
  @Type(() => UserVoucherItemApiModel)
  voucherItems: UserVoucherItemApiModel[];

  @Expose()
  @Type(() => UserVoucherTransferApiModel)
  voucherTransfers: UserVoucherTransferApiModel[];
}
