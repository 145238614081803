import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../../abstract.view.model';
import { UserSalesDocumentApiModel } from '../../../api-model/user-history/salesdocument/user-salesdocument.api.model';
import { UserPaymentViewModel } from './user-payment.view.model';

export class UserSalesDocumentViewModel extends AbstractViewModel<UserSalesDocumentApiModel> {
  public id: string = null;
  public basketId: string = null;
  public saleDate: DateTime;
  public clientName: string;
  public clientLastName: string;
  public clientEmail: string;
  public clientPhoneNo: string;
  public cinemaId: string;
  public totalValue: number = null;
  public totalNetValue: number = null;
  public totalTaxValue: number = null;
  public pointsAffected: number;
  public pointsAffectedBoxOffice: number;
  public pointsAffectedConcession: number;
  public cinemaName: string;
  public orderNumber: string = null;
  public payments: UserPaymentViewModel[] = [];

  constructor(protected apiModel: UserSalesDocumentApiModel = new UserSalesDocumentApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.basketId = this.apiModel.basketId;
    this.saleDate = this.apiModel.saleDate ? DateTime.fromISO(this.apiModel.saleDate, { setZone: true }) : null;
    this.clientName = this.apiModel.clientName;
    this.clientLastName = this.apiModel.clientLastName;
    this.clientEmail = this.apiModel.clientEmail;
    this.clientPhoneNo = this.apiModel.clientPhoneNo;
    this.cinemaId = this.apiModel.cinemaId;
    this.totalValue = this.apiModel.totalValue;
    this.totalNetValue = this.apiModel.totalNetValue;
    this.totalTaxValue = this.apiModel.totalTaxValue;
    this.pointsAffected = this.apiModel.pointsAffected;
    this.pointsAffectedBoxOffice = this.apiModel.pointsAffectedBoxOffice;
    this.pointsAffectedConcession = this.apiModel.pointsAffectedConcession;
    this.orderNumber = this.apiModel.orderNumber;
    this.payments = this.apiModel.payments.map((payment) => new UserPaymentViewModel(payment));
  }

  toApiModel(): UserSalesDocumentApiModel {
    return undefined;
  }
}
