import { forkJoin, iif, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { plainToInstance } from 'class-transformer';
import { UserDataProvider } from './user.data-provider';
import { BasketPageModel } from '../model/page/basket/basket.page.model';
import { ScreenHttpService } from '../http/screen.http.service';
import { ScreeningHttpService } from '../http/screening.http.service';
import { MovieCopyHttpService } from '../http/movie-copy.http.service';
import { MovieHttpService } from '../http/movie.http.service';
import { TicketHttpService } from '../http/order-ticket.http.service';
import { EventDataProvider } from './event.data-provider';
import { OrderStatus, OrderViewModel } from '../model/view-model/order/order.view.model';
import { storageKey } from '../../app.const';
import { ScreeningApiModel } from '../model/api-model/screening/screening.api.model';
import { ScreeningViewModel } from '../model/view-model/screening/screening.view.model';
import { MoviePrintApiModel } from '../model/api-model/movie/movie-print.api.model';
import { MovieApiModel } from '../model/api-model/movie/movie.api.model';
import { MoviePrintViewModel } from '../model/view-model/movie/movie-print.view.model';
import { MovieViewModel } from '../model/view-model/movie/movie.view.model';
import { TicketViewModel } from '../model/view-model/shared/ticket/ticket.view.model';
import { TicketApiModel } from '../model/api-model/shared/ticket/ticket.api.model';
import { EventViewModel } from '../model/view-model/event/event.view.model';
import { ScreenApiModel } from '../model/api-model/screen/screen.api.model';
import { ScreenViewModel } from '../model/view-model/screen/screen.view.model';
import { StateService } from '../state/state.service';
import { GenreViewModel } from '../model/view-model/genre/genre.view.model';

@Injectable({
  providedIn: 'root',
})
export class BasketDataProvider {
  public basketPageModel: BasketPageModel;

  constructor(
    private screenHttpService: ScreenHttpService,
    private screeningHttpService: ScreeningHttpService,
    private movieCopyHttpService: MovieCopyHttpService,
    private movieHttpService: MovieHttpService,
    private ticketHttpService: TicketHttpService,
    private userDataProvider: UserDataProvider,
    private eventDataProvider: EventDataProvider,
    private stateService: StateService
  ) {
    this.basketPageModel = new BasketPageModel();
  }

  public do(order: OrderViewModel, cinemaId: string): Observable<BasketPageModel> {
    const basketModel = this.basketPageModel ?? new BasketPageModel();
    console.log('#do', basketModel);

    const isEvent = this.stateService.getItem(storageKey.isEvent) === 'true';
    const screeningId = !!order?.screeningItems?.length
      ? order?.screeningItems[0]?.screeningId
      : JSON.parse(this.stateService.getItem('parentOrder'))?.parentOrderScreening?.id;
    const id = (isEvent ? this.stateService.getItem(storageKey.lastEventId) : screeningId) ?? null;

    let eventModel: EventViewModel | null = null;

    let genres: GenreViewModel[] = [];

    const eventDate = this.stateService.getItem(storageKey.chosenDate) ? DateTime.fromISO(this.stateService.getItem(storageKey.chosenDate)) : null;

    return iif(
      () => !!id,
      forkJoin({
        screeningModel: isEvent
          ? of(null)
          : this.screeningHttpService.getCinemaScreening(cinemaId, id).pipe(
              map((res) => {
                return plainToInstance(ScreeningApiModel, res as object);
              })
            ),
        eventModel: isEvent ? this.eventDataProvider.getCinemaEvent(cinemaId, id, screeningId, eventDate?.startOf('day'), eventDate?.endOf('day')) : of(null),
      }).pipe(
        mergeMap((data) => {
          eventModel = data.eventModel;
          if (data.screeningModel && !eventModel) {
            basketModel.screening = new ScreeningViewModel(data.screeningModel);
          } else {
            basketModel.screening = this.mapEventToScreening(eventModel);
            genres = eventModel.genres;
          }

          return iif(
            () => !basketModel.screening?.moviePrintId,
            of(null),
            this.movieCopyHttpService.getMoviePrint(basketModel.screening?.moviePrintId).pipe(
              tap((moviePrintApiModel: MoviePrintApiModel) => {
                basketModel.movieCopy = new MoviePrintViewModel(moviePrintApiModel);
              })
            )
          );
        }),
        mergeMap((moviePrintApiModel: MoviePrintApiModel) => {
          return iif(
            () => !moviePrintApiModel?.movieId,
            of(null),
            this.movieHttpService.getMovie(moviePrintApiModel?.movieId).pipe(
              tap((movieApiModel: MovieApiModel) => {
                basketModel.movie = new MovieViewModel(movieApiModel);
                genres = basketModel.movie.genres;
              })
            )
          );
        }),
        switchMap(() =>
          this.screenHttpService.getScreens(cinemaId, screeningId).pipe(
            tap((screenResponseModel: ScreenApiModel[]) => {
              basketModel.screen = this.mapScreenData(screenResponseModel[0], basketModel, isEvent, basketModel.event, genres);
            })
          )
        ),
        switchMap(() =>
          iif(
            () => order.status !== OrderStatus.CANCELLED,
            this.ticketHttpService.getOrderTickets(cinemaId, order.id),
            of(this.mapTicketsFromOrder(order))
          ).pipe(
            map((ticketModels: TicketApiModel[]) => ticketModels?.map((m) => new TicketViewModel(m))),
            tap((tickets) => {
              tickets?.forEach((ticket) => (ticket.seatId = order.screeningItems.find((x) => x.id === ticket.screeningItemId).seatId ?? null));
              basketModel.tickets = tickets;
            })
          )
        ),
        map(() => {
          basketModel.screeningId = id;
          basketModel.event = eventModel;
          return basketModel;
        })
      ),
      of(basketModel)
    ).pipe(
      switchMap(() =>
        iif(
          () => !basketModel.agreements,
          this.userDataProvider.getAgreements().pipe(
            tap((agreements) => (basketModel.agreements = agreements.marketingAgreements)),
            switchMap(() => of(basketModel))
          ),
          of(basketModel)
        )
      )
    );
  }

  private mapEventToScreening(eventModel: EventViewModel): ScreeningViewModel {
    const screening = new ScreeningViewModel();
    screening.id = eventModel.screeningId;

    screening.screeningTimeFrom = eventModel.screeningTimeFrom;
    screening.generalAdmission = false;
    screening.screeningDuration = eventModel.duration;
    screening.isScreenSwapping = false;
    screening.availabilityStatus = eventModel.availabilityStatus;
    screening.reservationTimeTo = eventModel.reservationTimeTo;
    screening.saleTimeTo = eventModel.saleTimeTo;
    screening.screeningTimeFrom = eventModel.screeningTimeFrom;
    screening.screeningTimeTo = eventModel.timeTo;
    screening.moviePrintId = eventModel.movieCopy?.id;
    return screening;
  }

  private mapScreenData(
    screenApiModel: ScreenApiModel,
    basketModel: BasketPageModel,
    isEvent: boolean,
    eventModel: EventViewModel | null,
    genres: GenreViewModel[]
  ): ScreenViewModel {
    const screen = new ScreenViewModel(screenApiModel);
    screen.isEvent = isEvent;

    screen.movieDate = basketModel.screening.screeningTimeFrom;
    screen.generalAdmission = basketModel.screening.generalAdmission;
    screen.movieGenres = genres || [];
    screen.screeningDuration = basketModel.screening.screeningDuration;
    screen.isScreenSwapping = basketModel.screening.isScreenSwapping;
    screen.screeningAvailabilityStatus = basketModel.screening.availabilityStatus;
    screen.reservationTimeTo = basketModel.screening.reservationTimeTo;
    screen.saleTimeTo = basketModel.screening.saleTimeTo;
    screen.screeningTimeFrom = basketModel.screening.screeningTimeFrom;
    screen.screeningTimeTo = basketModel.screening.screeningTimeTo;

    if (isEvent && eventModel) {
      // event
      screen.movieName = eventModel.name;
      screen.movieDuration = eventModel.duration;
      screen.posterUrl = eventModel.posters?.[0] || '';
      screen.pictureUrl = eventModel.pictures?.[0] || '';
      screen.movieDescription = eventModel.description;
      screen.movieTags = eventModel.tagGroups || [];
      screen.movieRating = eventModel.ratings;
      screen.movieCopyRelease = eventModel.release;
    } else {
      // movie
      screen.movieName = basketModel.movie.title;
      screen.moviePremiere = basketModel.movie.premiereDate;
      screen.movieDuration = basketModel.movie.duration;
      screen.posterUrl = basketModel.movie.posters?.[0] || '';
      screen.pictureUrl = basketModel.movie.pictures?.[0] || '';
      screen.movieDescription = basketModel.movie.description;
      screen.movieTags = basketModel.movie.tagGroups || [];
      screen.movieRating = basketModel.movie.ratings;

      // movie copy
      screen.movieLanguage = basketModel.movieCopy.language;
      screen.movieSpeakingType = basketModel.movieCopy.speakingType;
      screen.moviePrintType = basketModel.movieCopy.printType;
      screen.movieSoundType = basketModel.movieCopy.soundType;
      screen.movieCopyRelease = basketModel.movieCopy.release;
    }

    return screen;
  }

  private mapTicketsFromOrder(order: OrderViewModel): TicketApiModel[] {
    return order.screeningItems
      .filter((item) => item.ticketId)
      .map(
        (item) =>
          ({
            id: item.ticketId,
            name: item.name,
            price: item.price,
            screeningItemId: item.id,
            tax: item.tax,
            defaultPriceLevelPrice: item.defaultPriceLevelPrice,
          } as TicketApiModel)
      );
  }
}
