import { PaymentRequestClientAddress } from './create-payment.request.model';

export class OrderPaymentRequestModel {
  public channel: string = 'web';
  public continueUrl: string | null = null;
  public intPayMethodType: string | null = null;
  public intPayMethodValue: string | null = null;

  public saveToken: boolean | null = null;
  public paymentToken: string | null = null;
  public expiry: string | null = null;

  public sdkVersion: string | null = null;
  public regulationAccept: boolean = true;

  public clientAddress: PaymentRequestClientAddress | null = null;
}
