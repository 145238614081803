export enum PaymentMethodEnum {
  PBL = 'PBL',
  BLIK = 'BLIK',
  BLIK_T6 = 'BLIK_T6',
  CREDIT_CARD = 'CREDIT_CARD',
  APAY = 'APAY',
  GPAY = 'GPAY',
  CREDIT_CARD_TOKEN = 'CREDIT_CARD_TOKEN',
  BLIK_TOKEN = 'BLIK_TOKEN',
  TOKEN = 'TOKEN',
  PAY_PO = 'PAY_PO',
}
