import { DateTime } from 'luxon';
import { fromISOToDefaultZone } from '../../../date/date.helper';
import { AbstractViewModel } from '../../abstract.view.model';
import { ReservationApiModel } from '../../api-model/reservation/reservation.api.model';
import { ReservationEventViewModel } from './reservation-event.view.model';
import { ReservationItemViewModel } from './reservation-item.view.model';
import { ReservationMovieViewModel } from './reservation-movie.view.model';

export class ReservationViewModel extends AbstractViewModel<ReservationApiModel> {
  id: string;
  number: string;
  status: number;
  creationDate: DateTime;
  expiryDate: DateTime;
  cinemaId: string;
  cinemaName: string;
  screeningId: string;
  screeningName: string;
  screenId: string;
  screenName: string;
  screenNumber: number;
  screeningDate: DateTime;
  screeningStatus: number;
  userFirstName: string;
  userLastName: string;
  userPhone: string;
  userEmail: string;
  userId: string;
  movie: ReservationMovieViewModel;
  event: ReservationEventViewModel;
  reservationItems: ReservationItemViewModel[];

  constructor(protected apiModel: ReservationApiModel = new ReservationApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.number = this.apiModel.number;
    this.status = this.apiModel.status;
    this.creationDate = this.apiModel.creationDate ? DateTime.fromISO(this.apiModel.creationDate) : null;
    this.expiryDate = this.apiModel.expiryDate ? DateTime.fromISO(this.apiModel.expiryDate) : null;
    this.cinemaId = this.apiModel.cinemaId;
    this.cinemaName = this.apiModel.cinemaName;
    this.screeningId = this.apiModel.screeningId;
    this.screeningName = this.apiModel.screeningName;
    this.screenNumber = this.apiModel.screenNumber;
    this.screenId = this.apiModel.screenId;
    this.screenName = this.apiModel.screenName;
    this.screeningDate = this.apiModel.screeningDate ? DateTime.fromISO(this.apiModel.screeningDate, { setZone: true }) : null;
    this.screeningStatus = this.apiModel.screeningStatus;
    this.userFirstName = this.apiModel.userFirstName;
    this.userLastName = this.apiModel.userLastName;
    this.userPhone = this.apiModel.userPhone;
    this.userEmail = this.apiModel.userEmail;
    this.userId = this.apiModel.userId;
    this.movie = this.apiModel.movie ? new ReservationMovieViewModel(this.apiModel.movie) : null;
    this.event = this.apiModel.event ? new ReservationEventViewModel(this.apiModel.event) : null;
    this.reservationItems = this.apiModel.reservationItems ? this.apiModel.reservationItems.map((item) => new ReservationItemViewModel(item)) : [];
  }

  toApiModel(): ReservationApiModel {
    return undefined;
  }

  public isEvent(): boolean {
    return Boolean(this.event);
  }

  get poster(): string {
    const source = this.isEvent() ? this.event : this.movie;
    return source?.posters?.length ? source.posters[0] : null;
  }
}
