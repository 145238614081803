import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class GenderService {
  constructor(protected translateService: TranslateService) {}

  list() {
    return [
      {
        id: 0,
        name: this.translateService.instant('user.user-my-profile.form.genderList.unknown'),
      },
      {
        id: 1,
        name: this.translateService.instant('user.user-my-profile.form.genderList.male'),
      },
      {
        id: 2,
        name: this.translateService.instant('user.user-my-profile.form.genderList.female'),
      },
    ];
  }
}
