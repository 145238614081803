import { DateTime } from 'luxon';
import { EventViewModel } from './view-model/event/event.view.model';
import { ScreeningViewModel } from './view-model/screening/screening.view.model';

export class ScreenShowModel {
  id: string; // movieId/eventId
  regionId: string;
  cinemaId: string;
  screenId: string;
  screeningId: string;
  moviePrintId?: string;
  timeFrom: DateTime;
  timeTo: DateTime;
  saleTimeTo: DateTime;
  reservationTimeTo: DateTime;
  audience: number;
  maxOccupancy: number;
  availabilityStatus: number;
  printType: string;
  priority: number;
  title: string;

  model: ScreeningViewModel | EventViewModel;

  constructor(model?: ScreeningViewModel | EventViewModel) {
    this.model = model;
    this.id = model instanceof ScreeningViewModel ? model.movieId : model.id;
    this.regionId = model.regionId;
    this.cinemaId = model.cinemaId;
    this.screenId = model.screenId;
    this.screeningId = model instanceof ScreeningViewModel ? model.id : model.screeningId;
    this.moviePrintId = model instanceof ScreeningViewModel ? model.moviePrintId : null;
    this.timeFrom = model instanceof ScreeningViewModel ? model.screeningTimeFrom : model.timeFrom;
    this.timeTo = model instanceof ScreeningViewModel ? model.screeningTimeTo : model.timeTo;
    this.saleTimeTo = model.saleTimeTo;
    this.reservationTimeTo = model.reservationTimeTo;
    this.audience = model.audience;
    this.maxOccupancy = model.maxOccupancy;
    this.availabilityStatus = model.availabilityStatus;
    this.printType = model instanceof ScreeningViewModel ? model.printType : null;
  }

  isEvent() {
    return this.model instanceof EventViewModel;
  }
}
