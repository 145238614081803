import { Component, Input, OnInit } from '@angular/core';
import { CinemaDataProvider } from 'libs/core/src/lib/data-provider/cinema.data-provider';
import { UserDataProvider } from 'libs/core/src/lib/data-provider/user.data-provider';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { CinemaViewModel } from 'libs/core/src/lib/model/view-model/cinema/cinema.view.model';
import { UserSalesDocumentViewModel } from 'libs/core/src/lib/model/view-model/user-history/salesdocument/user-salesdocument.view.model';
import { UserHistoryViewModel } from 'libs/core/src/lib/model/view-model/user-history/user-history.view.model';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { Observable, tap } from 'rxjs';

@Component({
  template: '',
})
export class UserHistoryComponent implements OnInit {
  cinemaList: CinemaViewModel[];
  userHistory: UserHistoryViewModel;

  constructor(protected cinemaDataProvider: CinemaDataProvider, protected loadingService: LoadingService, protected userDataProvider: UserDataProvider) {}

  ngOnInit() {
    this.cinemaDataProvider.getCinemas().subscribe((cinemaList: CinemaViewModel[]) => {
      this.cinemaList = cinemaList;
    });

    this.userDataProvider
      .getHistoryViaApiModel()
      .pipe(
        tap((response) => {
          response.salesDocuments = response.salesDocuments.sort((a: UserSalesDocumentViewModel, b: UserSalesDocumentViewModel) => {
            return b.saleDate.toMillis() - a.saleDate.toMillis();
          });
          this.userHistory = response;
        })
      )
      .subscribe({ complete: () => this.loadingService.hideLoader(LoaderEnum.MAIN) });
  }

  getCinema(cinemaId: string): CinemaViewModel {
    return this.cinemaList.find((c) => c.id === cinemaId);
  }

  getTickets(orderId: string) {
    let y = this.userHistory.tickets.filter((t) => t.salesDocumentId === orderId);
    return groupBy(y, (i) => i.ticketId);
  }

  getOrderPoints(order: UserSalesDocumentViewModel, earnedOrUsed: number) {
    return earnedOrUsed > 0 ? (order?.pointsAffected > 0 ? order?.pointsAffected : 0) : order?.pointsAffected < 0 ? order?.pointsAffected : 0;
  }
}

const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce((groups, item) => {
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, {} as Record<K, T[]>);
