import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import cloneDeep from 'lodash-es/cloneDeep';
import { debounceTime, map, mergeMap, tap, toArray } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, Subscriber, Subscription, iif, throwError } from 'rxjs';
import { NavigationHelperService } from 'libs/core/src/lib/service/navigation/navigation-helper.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { CinemaViewModel, ENVIRONMENT_TOKEN, LocationStateService, makeUrl } from 'libs/core/src/public-api';
import { NextActionType } from './model/next-action.type';
import { TranslateService } from '@ngx-translate/core';
import { storageKey, appProjectName } from 'libs/core/src/app.const';
import { GeneralAdmissionService } from 'libs/core/src/lib/component/screen/ticket-count-general-admission/service/general-admission.service';
import { EventDataProvider } from 'libs/core/src/lib/data-provider/event.data-provider';
import { OrderDataProvider } from 'libs/core/src/lib/data-provider/order.data-provider';
import { ScreenDataProvider } from 'libs/core/src/lib/data-provider/screen.data-provider';
import { ScreeningDataProvider } from 'libs/core/src/lib/data-provider/screening.data-provider';
import { OccupancyModel } from 'libs/core/src/lib/model/occupancy.model';
import { ScreeningAvailabilityStrategyContext } from 'libs/core/src/lib/model/strategy/screening-availability/screening-availability.strategy-context';
import { OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { ScreeningItemViewModel } from 'libs/core/src/lib/model/view-model/order/screening-item/screening-item.view.model';
import { VoucherTypeModel } from 'libs/core/src/lib/model/voucher-type.model';
import { AnalyticsHelperService } from 'libs/core/src/lib/service/analytics-helper.service';
import { AppService } from 'libs/core/src/lib/service/app.service';
import { CountdownComponentService } from 'libs/core/src/lib/service/countdown.service';
import { DateTimeService } from 'libs/core/src/lib/service/datetime.service';
import { HeaderService } from 'libs/core/src/lib/service/header.service';
import { MessageService } from 'libs/core/src/lib/service/message.service';
import { SeatsOccupancyService } from 'libs/core/src/lib/service/seats-occupancy.service';
import { StepsService } from 'libs/core/src/lib/service/steps.service';
import { TotalizerService } from 'libs/core/src/lib/service/totalizer.service';
import { OrderValidatorService } from 'libs/core/src/lib/service/validator/order-validator.service';
import { ResponseValidatorService } from 'libs/core/src/lib/service/validator/response-validator.service';
import { ScreenValidatorService } from 'libs/core/src/lib/service/validator/screen-validator.service';
import { VoucherService } from 'libs/core/src/lib/service/voucher.service';
import { CateringStateService } from 'libs/core/src/lib/state/catering.state.service';
import { ScreenStateService } from 'libs/core/src/lib/state/screen.state.service';
import { ScreenErrorDataBuilder } from './service/screen-error.data-builder';
import { ScreenService } from './service/screen.service';
import { CartService } from '../../component/cart/service/cart.service';
import { CollisionItemModel } from '../../component/cart/service/model/collision-item.model';
import { OrderTicketsSummaryComponent } from '../../component/order/tickets-summary/tickets-summary.component';
import { NavigationEventType } from 'libs/core/src/lib/enum/navigation-event-type.enum';
import { MessageModel, MessageType } from 'libs/core/src/lib/model/message.model';
import { ScreenSeatsComponentInterface } from '../../component/screen/seats/seats.component';
import { TicketViewModel } from 'libs/core/src/lib/model/view-model/shared/ticket/ticket.view.model';
import { GaTicketViewModel } from 'libs/core/src/lib/model/view-model/screening/ga/ga-ticket.view.model';
import { SeparatorViewModel } from 'libs/core/src/lib/model/view-model/screening/separator/separator.view.model';
import { ScreeningViewModel } from 'libs/core/src/lib/model/view-model/screening/screening.view.model';
import { ScreenViewModel } from 'libs/core/src/lib/model/view-model/screen/screen.view.model';
import { SeatViewModel } from 'libs/core/src/lib/model/view-model/screen/seat/seat.view.model';
import { OccupiedStatus } from 'libs/core/src/lib/enum/occupied-status.enum';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { OrderHelper } from 'libs/core/src/lib/helper/order.helper';
import { EventRequestModel } from 'libs/core/src/lib/model/request/event.request.model';
import { ScreeningAvailabilityStatus } from 'libs/core/src/lib/enum/screening-availability-status.enum';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FlowType } from 'libs/core/src/lib/model/component/steps/flow-type.model';
import { ErrorHandlerService } from 'libs/core/src/lib/service/error-handler/error-handler.service';

@Component({
  template: '',
})
export class ScreenPageComponent implements OnInit, OnDestroy {
  useTicketCompose = false;

  public constructor(
    @Inject(ENVIRONMENT_TOKEN) environment: any,
    protected router: Router,
    protected route: ActivatedRoute,
    protected navigationHelper: NavigationHelperService,
    protected orderStateService: OrderStateService,
    protected screenDataProvider: ScreenDataProvider,
    protected orderDataProvider: OrderDataProvider,
    protected messageService: MessageService,
    protected screenOccupancyService: SeatsOccupancyService,
    protected screeningDataProvider: ScreeningDataProvider,
    protected screenValidator: ScreenValidatorService,
    protected orderValidatorService: OrderValidatorService,
    protected translate: TranslateService,
    protected countdownComponentService: CountdownComponentService,
    protected responseValidatorService: ResponseValidatorService,
    protected screenStateService: ScreenStateService,
    protected generalAdmissionService: GeneralAdmissionService,
    protected screenService: ScreenService,
    protected cartService: CartService,
    protected voucherService: VoucherService,
    protected stepsService: StepsService,
    protected screenErrorDataBuilder: ScreenErrorDataBuilder,
    protected appService: AppService,
    protected cateringStateService: CateringStateService,
    protected analyticsHelperService: AnalyticsHelperService,
    protected totalizerService: TotalizerService,
    protected eventDataProvider: EventDataProvider,
    protected headerService: HeaderService,
    protected dateTimeService: DateTimeService,
    protected loadingService: LoadingService,
    protected orderHelper: OrderHelper,
    protected modalService: BsModalService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    this.env = environment;
    this.useTicketCompose = this.env.pages.screen.withTicketCompose;

    this.stepsService.FlowType = FlowType.Standard;

    let pageIdentify = this.route.snapshot.data.pageIdentify;

    if (!pageIdentify) {
      pageIdentify = 'screen';
    }

    this.maxSelectedTicketsLimit = environment.constants.maxSelectedTickets;
    this.generalAdmissionMaxTicketsLimit = this.maxSelectedTicketsLimit;
    this.shouldShowPopupOnSelectedSeat = environment[pageIdentify].showPopupOnSelectedSeat;
    this.createOrderOnGeneralAdmission = environment.constants.createOrderOnGeneralAdmission;
    this.priceSeparatorsEnabled = environment.pages[pageIdentify].priceSeparators;
    this.showNoticeOnCollision = environment.pages[pageIdentify].showNoticeOnCollisionWithOtherScreenings;
    this.cartEnabled = environment.constants.cartEnabled;
    this.redirectToErrorPageOnScreenSwapping = environment.pages.screen.redirectToErrorPageSwapScreen;
    this.periodBetweenRequestsInScreen = environment.screen.periodBetweenRequestsInScreen;
    this.reduceRequestsForTicket = environment.screen.reduceRequestsForTicket;
    this.reduceRequestsForScreeningItems = environment.screen.reduceRequestsForScreeningItems;

    this.screeningAvailabilityStrategyContext = new ScreeningAvailabilityStrategyContext(ScreeningAvailabilityStrategyContext.SALE_STRATEGY);
  }

  loaderEnum: typeof LoaderEnum = LoaderEnum;
  public ticketPrice = 0;
  public configOrder: OrderViewModel = null;
  public isChildcomponentDataLoaded = false;
  public isLoadingData = false;
  public limit = 1;
  public ticketList: Array<TicketViewModel> = null;
  public availableTicketListGeneralAdmission = new Array<GaTicketViewModel>();
  public isTicketSummaryShow = false;
  public rowSeparators = new Array<SeparatorViewModel>();
  private _seatIds: string[] = [];
  public maxSelectedTicketsLimit = 10;
  public generalAdmissionMaxTicketsLimit = 10;
  public generalAdmissionMaxTicketsLimitWithSelectedTickets = 0;
  public shouldShowPopupOnSelectedSeat = false;
  public createOrderOnGeneralAdmission = false;
  public priceSeparatorsEnabled = false;
  public redirectToErrorPageOnScreenSwapping = false;
  public reduceRequestsForTicket = true;
  public reduceRequestsForScreeningItems = true;
  public periodBetweenRequestsInScreen = 500;
  public modalRef: BsModalRef;

  /**
   * This setting refers to warning popup that will be showed when
   * user want to add screening into cart (direct on indirect) and
   * collision will be matched with other screening eg. both screening
   * starts at the same time. This applies only for general admission
   * and enabled shopping cart
   */
  public showNoticeOnCollision = false;
  public matchedTicketCollisionCollection = new Array<CollisionItemModel>();
  public cartEnabled = false;
  public initExtraFeeIdentifierList = new Array<Array<string>>();

  /**
   * Determines if tickets was changed. Applies only for general admission screenings
   */
  public ticketsChanged = false;

  /**
   * Current selected screening id
   */
  @Input() public screeningId: string = null;

  @ViewChild('errorPopupTemplate') errorPopupTemplate: TemplateRef<any>;

  @ViewChild(OrderTicketsSummaryComponent)
  public orderTicketsSummaryComponent: OrderTicketsSummaryComponent;
  private screeningAvailabilityStrategyContext: ScreeningAvailabilityStrategyContext = null;

  protected orderStateSubscription = Subscription.EMPTY;
  private screenDataSubscription = Subscription.EMPTY;
  private ticketDataSubscription = Subscription.EMPTY;
  private orderDataSubscription = Subscription.EMPTY;
  private debouncer: Subject<void> = new Subject<void>();
  public screen: ScreenViewModel = null;
  private previousNextActionBeforeCollisionPopup: NextActionType = null;

  private env = null;
  private omitNextOrderStateChange = false;
  public screenings: ScreeningViewModel[] = [];
  public originScreenings: ScreeningViewModel[] = [];
  public isEvent = false;

  public isDropdownExpanded = false;

  public selectedVoucherList: Array<VoucherTypeModel> = [];
  private needRetryRequest = false;
  private requestInProgress = false;

  private orderCompose: EventEmitter<void> = new EventEmitter();
  public lastSelectedSeat: SeatViewModel | null = null;
  public showSelectedSeatModal = false;

  @ViewChild('screenSeats') public screenSeatsComponent: ScreenSeatsComponentInterface;

  public order: OrderViewModel;
  public cinemaId: string;
  public cinema: CinemaViewModel;

  public loaderEnable(event) {
    this.isChildcomponentDataLoaded = !event;
  }

  public ngOnInit() {
    const action = this.route.snapshot.queryParamMap.get('action') || this.route.snapshot.paramMap.get('action');
    if (action) {
      var url = window.location.toString();
      window.history.pushState(null, null, url.replace(`?action=${action}`, ''));
      window.location.reload();
    }

    const routeParamScreeningId = this.route.snapshot.queryParamMap.get('screeningId') || this.route.snapshot.paramMap.get('screeningId');
    const routeParamCinemaId = this.route.snapshot.queryParamMap.get('cinemaId') || this.orderStateService.getItem(storageKey.chosenLocation);
    if (routeParamCinemaId) {
      const cinemaId = routeParamCinemaId.toLowerCase();
      this.cinemaId = cinemaId;
      this.orderStateService.setItem(storageKey.chosenLocation, cinemaId);
    }

    if (!!routeParamScreeningId) {
      const screeningId = routeParamScreeningId.toLowerCase();
      this.screeningId = screeningId;
      this.initSubscribers(screeningId);
    }
  }

  protected initSubscribers(screeningId: string) {
    this.cleanSubscriptions();

    this._seatIds = [];
    this.ticketList = [];

    this.debouncer.pipe(debounceTime(this.periodBetweenRequestsInScreen)).subscribe(() => {
      this.ticketCompose();
    });

    this.cateringStateService.checkAvailability(true);
    this.messageService.clear();

    this.orderStateSubscription = this.orderStateService.state$.subscribe((order: OrderViewModel) => {
      this.isEvent = this.orderStateService.getItem(storageKey.isEvent) === 'true';
      console.log('isEvent:', this.isEvent);

      if (order === null) {
        this.orderStateService.removeItem(storageKey.isEvent);
        this.orderStateService.removeItem(storageKey.lastEventId);
        this.orderStateService.removePersonalTaxId();
      }

      if (this.omitNextOrderStateChange) {
        console.log('#1', this.omitNextOrderStateChange);
        this.omitNextOrderStateChange = false;
        return;
      }

      this.order = order;
      this.configOrder = cloneDeep(order);

      if (this.env.constants.enableMultipleScreeningSales === false) {
        if (this.screenStateService.state.screeningId !== null && this.screenStateService.state.screeningId !== screeningId) {
          console.log('#2 screenStateService restart', screeningId);
          this.screenStateService.reset();
          this.screenStateService.state.screeningId = screeningId;
        }

        if (!this.orderValidatorService.validateWarnings(this.order)) {
          console.log('#3 order validation', order);
          this.orderStateService.removeOrder();
        }
      }

      console.log('#4', !order, this.orderStateService.checkLastScreening(screeningId));
      if (!order || this.orderStateService.checkLastScreening(screeningId)) {
        console.log('#4a');
        this.screenStateService.reset();
      } else {
        console.log('#4b');
        this.countdownComponentService.resume();
      }

      if (this.appService.isProject(appProjectName.HELIOS)) {
        console.log('#5 for Helios');
        this.countdownComponentService.stop();
        this.countdownComponentService.hide();
      }

      this.orderStateService.setLastScreeningId(screeningId);
      console.log('#5 after');

      if (!this.screenValidator.validate(this.order, screeningId)) {
        console.log('#6 order validation', this.order, screeningId, this.screenValidator.errors);
        this.screenValidator.handleErrors();
        this.order = null;
      } else if (this.cinemaId !== null && this.screenDataSubscription === Subscription.EMPTY) {
        console.log('#7 screenDataSubscription');

        this.screenDataSubscription = this.screenDataProvider.getScreenByAndCheckIfScreeningIsEvent(this.cinemaId, screeningId, null).subscribe({
          next: (screen) => {
            this.stepsService.FlowType = screen?.generalAdmission ? FlowType.Tickets : FlowType.Standard;

            if (this.stepsService.FlowType === FlowType.Tickets) {
              this.router.navigate(['tickets'], { queryParams: { screeningId: screeningId, cinemaId: this.cinemaId } });
            }

            this.makeScreen(order, screen);
            this.fetchScreenings(this.cinemaId, screen, screeningId, this.orderStateService.getItem(storageKey.isEvent) === 'true');

            this.headerService.setData(this.screen);
          },
          error: (e) => this.catchError(e),
        });
      }
    });

    this.orderCompose.pipe(debounceTime(this.periodBetweenRequestsInScreen)).subscribe(() => {
      console.log('orderCompose');

      this.requestInProgress = true;
      this.needRetryRequest = false;

      this.createOrModifyBasket();
    });
  }

  private makeScreen(order: OrderViewModel, screen: ScreenViewModel) {
    console.log('screen', screen);
    if (!screen) {
      this.router.navigate(['error', '503']);

      return;
    } else if (!screen.occupancy) {
      const hasAlreadyAnyCurrentScreeningItems: boolean =
        order && order.screeningItems ? order.screeningItems.filter((x) => x.screeningId === this.screeningId).length > 0 : false;
      if (!hasAlreadyAnyCurrentScreeningItems) {
        this.router.navigate(['error', '5001']);
        return;
      }
    }

    if (screen.isScreenSwapping && this.redirectToErrorPageOnScreenSwapping) {
      this.router.navigate(['error', '5004']);
      return;
    }

    if (
      this.dateTimeService.isPast(screen.saleTimeTo) ||
      (!this.appService.isProject(appProjectName.HELIOS) && this.dateTimeService.isPast(screen.reservationTimeTo))
    ) {
      this.router.navigate(['error', '5002']);
      return;
    }

    const mySeatIds = this.order ? this.order.screeningItems.filter((x) => x.screeningId === this.screeningId).map((x) => x.seatId) : [];
    if (this.screenOccupancyService.totalOccupied >= screen.maxOccupancy && mySeatIds.length === 0) {
      this.router.navigate(['error', '5001']);
    }

    if (!this.screeningAvailabilityStrategyContext.isAvailable(screen.screeningAvailabilityStatus)) {
      return this.navigationHelper.goToRepertoire();
    }

    this.screen = screen;
    console.log('#8', this.screen, this.order);
    this.analyticsHelperService.findAnalysisProviderToInformByMovieId(screen.movieId);

    if (mySeatIds.length > 0) {
      this._seatIds = mySeatIds;
      this.limit = mySeatIds.length;
      this.screenOccupancyService.markMySeats(this.screen, mySeatIds, this.limit);
      this.screenOccupancyService.seatsFromOrder = mySeatIds.length;
      this.screenOccupancyService.isCateringSelected = order.fbItems.length > 0;
    }

    if (this.screen.posterUrl === undefined || this.screen.posterUrl === null || this.screen.posterUrl === '') {
      this.screen.posterUrl = makeUrl(this.env, this.env.constants.moviePosterPlaceholder);
    }

    if (screen.generalAdmission === false && this.priceSeparatorsEnabled === true) {
      console.log('#10 getting separators..');
      this.screeningDataProvider.getRowSeparators(order.cinemaId, this.screeningId).subscribe({
        next: (rows: Array<SeparatorViewModel>) => {
          this.rowSeparators = rows;
          this.loadingService.hideLoader(LoaderEnum.MAIN);
        },
        error: (e) => {
          this.loadingService.hideLoader(LoaderEnum.MAIN);
        },
      });
    } else {
      this.loadingService.hideLoader(LoaderEnum.MAIN);
    }

    this.loadingService.showLoader(LoaderEnum.MAIN);

    if (screen.generalAdmission === true && this.createOrderOnGeneralAdmission === true) {
      this.fetchAvailableTicketListGeneralAdmission().subscribe((availableTicketList: Array<GaTicketViewModel>) => {
        this.availableTicketListGeneralAdmission = availableTicketList;
        this.loadingService.hideLoader(LoaderEnum.MAIN);
      });
    } else {
      this.loadingService.hideLoader(LoaderEnum.MAIN);
    }
  }

  private catchError(err) {
    if (!err) {
      return;
    }

    switch (err.status) {
      case 400:
      case 409:
        this.router.navigate(['error', '5001']);
        break;
      case 404:
        this.router.navigate(['error', '503']);
        break;
      default: // TODO: 5000 for Muvi?
        this.router.navigate(['error', 'default']);
        break;
    }
  }

  private fetchScreenings(cinemaId: string, screen: ScreenViewModel, screeningId: string, isEvent: boolean) {
    console.log('#12 fetching screenings..', this.appService.projectName);
    if (!screen) {
      return;
    }

    if (this.appService.isProject(appProjectName.HELIOS)) {
      this.loadingService.showLoader(LoaderEnum.MAIN);

      this.screenings = [];
      const dateTo = cloneDeep(screen.screeningTimeFrom).plus({ days: 3 }).toLocal();
      const dateFrom = cloneDeep(screen.screeningTimeFrom).minus({ days: 3 }).toLocal();
      const dateToString = dateTo.toString().split('T')[0];
      const dateFromString = dateFrom.toString().split('T')[0];
      const sortComparer: any = (a, b) => a.screeningTimeFrom.toJSDate().getTime() - b.screeningTimeFrom.toJSDate().getTime();

      iif(
        () => !isEvent,
        this.screeningDataProvider.findByIdViaApiModel(cinemaId, screeningId).pipe(
          mergeMap((screening) => {
            return this.screeningDataProvider.getScreeningsByMovieId(cinemaId, screening.movie.id, dateFromString, dateToString);
          }),
          map((c) => c.filter((item) => !this.dateTimeService.isPast(item.saleTimeTo) && !item.isScreenSwapping)),
          mergeMap((screenings) => {
            this.originScreenings = screenings;
            return screenings;
          }),
          toArray(),
          tap((screenings) => {
            this.screenings = screenings.filter((item) => item.availabilityStatus !== ScreeningAvailabilityStatus.ForPreview);
            this.screenings.sort(sortComparer);
          })
        ),
        this.eventDataProvider
          .getCinemaEventList(
            new EventRequestModel(cinemaId, null, dateFrom.startOf('day'), dateTo.endOf('day'), this.orderStateService.getItem(storageKey.lastEventId))
          )
          .pipe(
            tap((events) => {
              this.originScreenings = events.map((event) =>
                Object.assign(new ScreeningViewModel(), {
                  id: event.screeningId,
                })
              );

              this.screenings = events
                .filter((event) => event.availabilityStatus !== ScreeningAvailabilityStatus.ForPreview)
                .map((event) =>
                  Object.assign(new ScreeningViewModel(), {
                    screeningTimeFrom: event.timeFrom,
                    timeFrom: event.timeFrom,
                    id: event.screeningId,
                  })
                );

              this.screenings.sort(sortComparer);
            })
          )
      ).subscribe((_) => {
        this.loadingService.hideLoader(LoaderEnum.MAIN);
      });
    }
  }

  public onGeneralAdmissionComponentInit(): void {
    if (this.screen.generalAdmission === true && this.order && this.order.screeningItems) {
      const filteredOrderItems: Array<ScreeningItemViewModel> = this.order.screeningItems.filter((x) => x.screeningId === this.screeningId);
      this.markMySelectedTicketGeneralAdmission(filteredOrderItems);
    }
  }

  public onClickedCloseButtonOnScreenSwappingModal(): void {
    return this.navigationHelper.goToRepertoire();
  }

  public onClickedCloseButtonOnScreenCollisionModal(proceed: boolean): void {
    if (proceed === true) {
      this.onNextActionWithGeneralAdmission(this.previousNextActionBeforeCollisionPopup, true);
    } else {
      this.matchedTicketCollisionCollection = new Array<CollisionItemModel>();
    }
  }

  public onSeatsSelected(event: [string[], boolean]) {
    this.isLoadingData = true;
    const seats = event[0] !== undefined ? event[0] : [];
    this._seatIds = [...seats];

    if (this.requestInProgress) {
      console.log('needRetry!');
      this.needRetryRequest = true;
    } else {
      console.log('request!');
      this.orderCompose.emit();
    }
  }

  public lastSelectedSeatChange(seatModel: SeatViewModel) {
    this.lastSelectedSeat = seatModel;
    this.showSelectedSeatModal = this.canShowModal(seatModel);

    if (!this.showSelectedSeatModal) {
      this.screenSeatsComponent.changeSeatState(this.lastSelectedSeat.id);
    }
  }

  canShowModal(seatModel: SeatViewModel): boolean {
    return (
      (!seatModel.groupId && seatModel.defaultGroupDescription && (seatModel.defaultGroupDescription as string) !== '') ||
      (seatModel.groupId &&
        seatModel.groupDescriptionCollection &&
        seatModel.groupDescriptionCollection.length > 0 &&
        (seatModel.groupDescriptionCollection[0] as string) !== '')
    );
  }

  public onClickedCloseModal(accept: boolean = true): void {
    if (accept) {
      this.screenSeatsComponent.changeSeatState(this.lastSelectedSeat.id);
    }
    this.lastSelectedSeat = null;
    this.showSelectedSeatModal = false;
  }

  public onShowedSeatPopup(seatGroupType: string): void {
    this.screenStateService.state.screeningId = this.screeningId;
    this.screenStateService.state.showSeatGroupPopups[seatGroupType] = true;
  }

  public markMySelectedTicketGeneralAdmission(orderItems: Array<ScreeningItemViewModel>): void {
    const myTickets = orderItems.map((x) => x.ticketId);
    const optionalExtraFeesIdList = new Array<Array<string>>();

    for (const orderItem of orderItems) {
      for (const extraFeeIdentifier of orderItem.optionalExtraFees) {
        optionalExtraFeesIdList.push([orderItem.ticketId, extraFeeIdentifier]);
      }
    }

    this.generalAdmissionService.markMyTickets(myTickets);
    setTimeout(() => {
      this.initExtraFeeIdentifierList = optionalExtraFeesIdList;
      this.ticketsChanged = false;
    }, 500);
  }

  public onSelectedTicketGeneralAdmission(ticket: TicketViewModel): void {
    if (!this.ticketList) {
      this.ticketList = new Array<TicketViewModel>();
    }

    const ticketCollection = this.ticketList.map((x) => x);
    ticketCollection.push(ticket);
    this.ticketList = ticketCollection;
    this.ticketsChanged = true;
  }

  public onDroppedTicketGeneralAdmission(ticket: TicketViewModel): void {
    if (!this.ticketList) {
      this.ticketList = new Array<TicketViewModel>();
    }

    const ticketCollection: Array<TicketViewModel> = this.ticketList.map((x) => x);
    let matchedFirstTicketTypeIndex: number | null = null;

    ticketCollection.find((element: TicketViewModel, index: number): boolean => {
      if (element.id === ticket.id) {
        matchedFirstTicketTypeIndex = index;
        return true;
      }

      return false;
    });

    if (matchedFirstTicketTypeIndex !== null) {
      ticketCollection.splice(matchedFirstTicketTypeIndex, 1);
    }

    this.ticketList = ticketCollection;
    this.ticketsChanged = this.ticketList.length !== 0;
  }

  public onCounterChange(counter: number) {
    this.screenOccupancyService.markMySeats(this.screen, this._seatIds, counter);
    this.limit = counter;
  }

  public onNavigationClick(event: NavigationEventType) {
    switch (event) {
      case NavigationEventType.PREVIOUS:
        this.onPreviousAction();
        break;
      case NavigationEventType.NEXT:
        this.onNextAction(NextActionType.NextStep);
        break;
      case NavigationEventType.SUMMARY_SHOW:
        this.onTicketSummaryClick(this.isTicketSummaryShow === true ? 'close' : 'show');
        break;
      case NavigationEventType.ADD_TO_CART:
        this.onNextAction(NextActionType.MainPage);
        break;
      case NavigationEventType.REMOVE_FROM_CART:
        this.onRemoveFromCart();
        break;
    }
  }

  public onPreviousAction() {
    if (this.orderStateService.getItem(storageKey.backUrl)) {
      window.location.href = this.orderStateService.getItem(storageKey.backUrl);
    } else {
      this.router.navigate([this.navigationHelper.getPreviousRoute(this.route.snapshot)]);
    }
  }

  private ticketCompose(): void {
    this.requestInProgress = true;
    this.needRetryRequest = false;

    if (this.useTicketCompose) {
      this.prepareTickets(false);
    } else {
      this.createOrModifyBasket();
    }
  }

  private prepareTickets(skipCreateOrModifyBasket: boolean): void {
    const finalyAction = (skipCreateOrModifyBasket: boolean) => {
      if (!skipCreateOrModifyBasket) {
        this.createOrModifyBasket();
        return;
      }

      this.tryTicketCompose();
    };

    if (this._seatIds.length === 0) {
      this.ticketList = [];
      finalyAction(skipCreateOrModifyBasket);
      return;
    }

    const missingSeatIds = this._seatIds.filter((seatId) => !this.ticketList.some((ticket) => ticket.seatId === seatId));

    if (this.reduceRequestsForTicket && this.ticketList && missingSeatIds.length === 0) {
      this.ticketList = this.ticketList.filter((ticket) => this._seatIds.includes(ticket.seatId));
      finalyAction(skipCreateOrModifyBasket);
      return;
    }

    this.ticketDataSubscription = this.screeningDataProvider.getTicketList(this.order.cinemaId, this.screeningId, this._seatIds).subscribe((res) => {
      this.ticketList = res;
      finalyAction(skipCreateOrModifyBasket);
    });
  }

  public createOrModifyBasket() {
    if (!!this.order) {
      this.patchOrderItems();
      return;
    }

    this.orderDataSubscription = this.orderHelper
      .createIfNeeded(this.cinemaId)
      .pipe(
        tap((createdOrder) => {
          this.setOrderProxy(createdOrder);
        })
      )
      .subscribe({
        next: () => {
          this.patchOrderItems();
          this.countdownComponentService.start();
        },
        error: (e) => {
          if (this.needRetryRequest) {
            this.ticketCompose();
            return;
          }

          const error = this.errorHandlerService.getError(e);
          if (error.code === '3000') {
            this.router.navigate(['maintenance'], { state: { endTime: error.properties?.endTime } });
          } else {
            this.router.navigate(['error', '20000']);
          }
        },
      });
  }

  private setOrderProxy(order: OrderViewModel) {
    this.order = cloneDeep(order);
    this.configOrder = cloneDeep(order);
    this.selectedVoucherList = this.voucherService.createVoucherCollectionFromOrder(order);
    this.shouldOmitNextOrderStateChange();
    this.orderStateService.setOrder(order);
  }

  private patchOrderItems(): void {
    const items = this._seatIds.map((id) => {
      let ticket: TicketViewModel;

      if (this.ticketList) {
        const tickets = this.ticketList.filter((x) => x.seatId === id);
        if (tickets.length > 0) {
          ticket = tickets.reduce((prev, current) => {
            return prev.price > current.price ? prev : current;
          });
        }
      }

      if (this.order) {
        const previousItem = this.order.screeningItems?.find((screeningItem) => screeningItem.seatId === id);

        if (previousItem) {
          if (ticket) {
            previousItem.ticketId = ticket.id;
            previousItem.extraFees.forEach((extraFeeFromOrder) => {
              const extraFee = ticket.extraFees.find((extraFeeFromTicket) => extraFeeFromOrder.id === extraFeeFromTicket.extraFeeId);
              if (extraFee && extraFee.isOptional) {
                previousItem.optionalExtraFees.push(extraFee.extraFeeId);
              }
            });
          }

          return previousItem;
        }
      }

      return Object.assign(new ScreeningItemViewModel(), {
        screeningId: this.screeningId,
        seatId: id,
        quantity: 1,
        ticketId: ticket ? ticket.id : null,
      });
    });

    if (this.order?.screeningItems && this.order.screeningItems.length !== items.length) {
      this.orderStateService.removeItem(storageKey.isExtraFeesSelected);
    }

    this.orderDataSubscription = this.orderDataProvider.patchItems(this.order.cinemaId, this.order, items).subscribe({
      next: (res) => {
        this.setOrderProxy(res);
        this.tryTicketCompose();
      },
      error: (e) => this.afterPatchItemError(e),
    });
  }

  private afterPatchItemError(e) {
    if (this.needRetryRequest) {
      this.ticketCompose();
    } else {
      const error = this.errorHandlerService.getError(e);
      switch (error.code) {
        case '100':
          this.screenOccupancyValidate(true, () => this.restoreOldBasket(error.message));
          break;
        case '231':
          this.screenOccupancyValidate(false, () => this.restoreOldBasket(error.message));
          break;
        default:
          this.restoreOldBasket(error.message);
          break;
      }

      //this.requestInProgress = false;
      //this.isLoadingData = false;
    }
  }

  private screenOccupancyValidate(withCheckingAlreadyReserved: boolean, restoreOldBasketCallbackFunction): void {
    if (!this.order?.cinemaId || !this.screeningId) {
      return;
    }

    this.screeningDataProvider.getOccupancyList(this.order.cinemaId, this.screeningId).subscribe((occupancy: OccupancyModel) => {
      if (this.needRetryRequest) {
        this.ticketCompose();
        return;
      }

      const seats: SeatViewModel[] = (this.screen.pseats as any).flat();
      this.screenOccupancyService.markOccupiedSeats(this.screen, occupancy);

      const screeningItemsLength = this.order.screeningItems?.length ?? 0;
      this.screenOccupancyService.seatsFromOrder = screeningItemsLength;
      if (withCheckingAlreadyReserved) {
        const alreadyReservedSeats = this._seatIds.filter(
          (x) => occupancy.occupiedSeats.includes(x) && this.order.screeningItems.filter((y) => y.seatId === x).length === 0
        );

        if (alreadyReservedSeats.length > 0) {
          this.showAlreadyReservedSeats(alreadyReservedSeats, seats);
          this.setFalseForLoadingAndProgress();
          return;
        }
      }

      if (restoreOldBasketCallbackFunction) {
        restoreOldBasketCallbackFunction();
      }
    });
  }

  private setFalseForLoadingAndProgress() {
    this.isLoadingData = false;
    this.requestInProgress = false;
  }

  private restoreOldBasket(errorMessage): void {
    const seats = this.order ? this.order.screeningItems.map((x) => x.seatId) : [];
    this.screenOccupancyService.markMySeats(this.screen, seats, seats.length);
    this._seatIds = seats;

    this.prepareTickets(true);
    this.messageService.add(new MessageModel(MessageType.info, errorMessage));
  }

  public onNextActionWithGeneralAdmission(nextAction: NextActionType, skipCollisionCheck: boolean = false) {
    if (skipCollisionCheck === false && this.shouldShowNoticeOnCollision() === true) {
      this.matchedTicketCollisionCollection = this.cartService.findCartScreeningItemsCollisions(this.screen.screeningTimeFrom, this.screen.screeningTimeTo);
      this.matchedTicketCollisionCollection = this.matchedTicketCollisionCollection.filter((x) => x.screeningId !== this.screeningId);

      if (this.matchedTicketCollisionCollection.length > 0) {
        this.matchedTicketCollisionCollection.push(
          new CollisionItemModel(this.screeningId, this.screen.movieName, this.screen.screeningTimeFrom, this.screen.screeningTimeTo)
        );
        this.previousNextActionBeforeCollisionPopup = nextAction;
        return;
      }
    }

    this.loadingService.showLoader(LoaderEnum.MAIN);

    const items: Array<ScreeningItemViewModel> = this.screenService.buildItemCollectionForGeneralAdmission(this.ticketList, this.screeningId, this.configOrder);
    const itemsGroupByTicketId: { [key: string]: number } = this.screenService.countGeneralAdmissionTicketUsage(items);

    this.screeningDataProvider
      .getTicketListGeneralAdmission(this.order.cinemaId, this.screeningId)
      .subscribe((ticketListGeneralAdmission: Array<GaTicketViewModel>) => {
        const order = this.orderStateService.getOrder();

        if (order) {
          ticketListGeneralAdmission = this.screenService.recalculateGATicketAvailability(ticketListGeneralAdmission, order);
        }

        for (const ticket of ticketListGeneralAdmission) {
          const selectedAmount: number = itemsGroupByTicketId[ticket.id];

          if (selectedAmount > ticket.availableAmount) {
            this.availableTicketListGeneralAdmission = ticketListGeneralAdmission;
            this.router.navigate(['error', '5001']);
            return;
          }
        }

        this.orderDataProvider.patchItems(order.cinemaId, order, items, true).subscribe({
          next: (order: OrderViewModel) => {
            this.orderStateService.setOrder(order);

            const filteredItems: Array<ScreeningItemViewModel> = order.screeningItems.filter((x) => x.screeningId === this.screeningId);
            const amountOfReservedTickets: number = items.length - filteredItems.length;

            if (amountOfReservedTickets === 0) {
              this.goToNext(nextAction);
            } else {
              this.showAlreadyReservedTickets(amountOfReservedTickets);
            }
          },
          error: (e) => {
            this.ticketList = new Array<TicketViewModel>();
            this.handleErrors(e);
            this.loadingService.hideLoader(LoaderEnum.MAIN);
          },
        });
      });
  }

  public onRemoveFromCart(): void {
    this.loadingService.showLoader(LoaderEnum.MAIN);

    this.cartService.removeScreening(this.screeningId).subscribe((order) => {
      this.loadingService.hideLoader(LoaderEnum.MAIN);
      this.orderStateService.setOrder(order);
      this.ticketList = new Array<TicketViewModel>();
      this.onGeneralAdmissionComponentInit();
    });
  }

  public onNextAction(nextAction: NextActionType) {
    if (this.isLoadingData) {
      return;
    }

    if (this.screen.generalAdmission) {
      this.onNextActionWithGeneralAdmission(nextAction);
    } else {
      this.loadingService.showLoader(LoaderEnum.MAIN);

      this.orderDataSubscription = this.orderDataProvider.verify(this.order.cinemaId, this.order.id).subscribe({
        next: (v) => this.goToNext(nextAction),
        error: (e: HttpErrorResponse) => {
          const hasError = e.error && e.error.error && e.error.error.code;
          let errorCode = hasError ? e.error.error.code : 0;

          if (errorCode === 0 && e.error.error.properties?.seats?.length > 0) {
            errorCode = e.error.error.properties.seats[0].code;
          }

          switch (errorCode) {
            case 111:
            case 112:
            case 114:
              const seatIdsCopy = cloneDeep(this._seatIds);
              this._seatIds = [];
              this.loadingService.hideLoader(LoaderEnum.MAIN);

              setTimeout((x) => {
                this._seatIds = seatIdsCopy;
                this.modalRef = this.modalService.show(this.errorPopupTemplate, { class: 'modal-sm' });
              }, 100);
              break;
            default:
              this.orderStateService.removeOrder();
              this.router.navigate(['error', '20000']);
              break;
          }
        },
      });
    }
  }

  public onTicketSummaryClick(action) {
    this.isTicketSummaryShow = action !== 'close';
  }

  public ngOnDestroy(): void {
    console.log('ngOnDestroy');
    this.cleanSubscriptions();
  }

  protected cleanSubscriptions() {
    this._seatIds = [];
    if (this.orderStateSubscription !== Subscription.EMPTY) {
      this.orderStateSubscription.unsubscribe();
      this.orderStateSubscription = Subscription.EMPTY;
    }

    if (this.screenDataSubscription !== Subscription.EMPTY) {
      this.screenDataSubscription.unsubscribe();
      this.screenDataSubscription = Subscription.EMPTY;
    }

    if (this.ticketDataSubscription !== Subscription.EMPTY) {
      this.ticketDataSubscription.unsubscribe();
      this.ticketDataSubscription = Subscription.EMPTY;
    }

    if (this.orderDataSubscription !== Subscription.EMPTY) {
      this.orderDataSubscription.unsubscribe();
      this.orderDataSubscription = Subscription.EMPTY;
    }

    this.messageService.clear();
  }

  private tryTicketCompose() {
    if (this.needRetryRequest) {
      this.ticketCompose();
    } else {
      this.setFalseForLoadingAndProgress();
    }
  }

  private fetchAvailableTicketListGeneralAdmission(): Observable<any> {
    return new Observable((observer: Subscriber<any>) => {
      this.screeningDataProvider
        .getTicketListGeneralAdmission(this.order.cinemaId, this.screeningId)
        .subscribe((ticketListGeneralAdmission: Array<GaTicketViewModel>) => {
          const availableTicketsBySeatGroup: { [key: string]: number } = {};

          ticketListGeneralAdmission.forEach((x) => {
            availableTicketsBySeatGroup[x.seatGroupId] = x.availableAmount;
          });
          const availableTickets = Object.entries(availableTicketsBySeatGroup).reduce((curr, [x, y]) => {
            return (curr += y);
          }, 0);

          this.generalAdmissionMaxTicketsLimit = availableTickets > this.maxSelectedTicketsLimit ? this.maxSelectedTicketsLimit : availableTickets;
          this.generalAdmissionMaxTicketsLimit = this.generalAdmissionMaxTicketsLimit <= 0 ? 0 : this.generalAdmissionMaxTicketsLimit;
          let currentSelectedTicketsAmount: number = this.generalAdmissionMaxTicketsLimit;

          if (this.order?.screeningItems) {
            const [t, ticketUsageBySeatGroup]: [number, { [key: string]: number }] = this.screenService.countGeneralAdmissionTicketUsageBySeatGroup(
              this.screeningId,
              this.order.screeningItems,
              ticketListGeneralAdmission
            );

            currentSelectedTicketsAmount += t;

            ticketListGeneralAdmission = ticketListGeneralAdmission.map((ticket) => {
              ticket.availableAmount += ticketUsageBySeatGroup[ticket.seatGroupId] || 0;
              return ticket;
            });
          }

          this.generalAdmissionMaxTicketsLimitWithSelectedTickets += currentSelectedTicketsAmount;

          if (this.generalAdmissionMaxTicketsLimitWithSelectedTickets > this.maxSelectedTicketsLimit) {
            this.generalAdmissionMaxTicketsLimitWithSelectedTickets = this.maxSelectedTicketsLimit;
          }

          observer.next(ticketListGeneralAdmission);
          observer.complete();
        });
    });
  }

  private showAlreadyReservedTickets(amountOfReservedTickets: number): void {
    const translatedMessage: string = this.translate.instant('errors.10308', {
      amount: amountOfReservedTickets,
    });

    this.messageService.add(new MessageModel(MessageType.warning, translatedMessage));
    this.fetchAvailableTicketListGeneralAdmission().subscribe((availableTickets) => {
      this.ticketList = new Array<TicketViewModel>();
      this.availableTicketListGeneralAdmission = availableTickets;
      this.loadingService.hideLoader(LoaderEnum.MAIN);
    });
  }

  private showAlreadyReservedSeats(seatIdList: Array<string>, seats: SeatViewModel[]): void {
    const occupiedSeats: Array<SeatViewModel> = cloneDeep(seats).filter((o) => o.occupied === OccupiedStatus.Occupied && seatIdList.includes(o.id));
    const allowedSeatIds: Array<string> = this._seatIds.filter((x) => seatIdList.indexOf(x) < 0);

    if (occupiedSeats.length > 0) {
      const messageType = this.appService.isProject(appProjectName.SCHULMAN, appProjectName.VIOLET_CROWN, appProjectName.LANDMARK, appProjectName.ONEIL)
        ? MessageType.danger
        : MessageType.info;

      const allreadyReserved = this.screenErrorDataBuilder.allreadyReserved(occupiedSeats);
      const messageSeats = allreadyReserved.map((o) => `${this.translate.instant('ticketssummary.label', o)}`);

      this.messageService.add(
        new MessageModel(
          messageType,
          this.translate.instant('errors.10307', {
            seats: messageSeats.join(', '),
          })
        )
      );
    }

    this.screenOccupancyService.markMySeats(this.screen, allowedSeatIds, allowedSeatIds.length);
    this._seatIds = cloneDeep(allowedSeatIds);

    if (!this.reduceRequestsForScreeningItems || this.isSthToUpdate()) {
      this.ticketCompose();
    } else {
      this.setFalseForLoadingAndProgress();
    }
  }

  private isSthToUpdate(): boolean {
    return this.order ? this._seatIds.some((seatId) => !this.order.screeningItems.find((item) => item.seatId === seatId)) : true;
  }

  private handleErrors(err): void {
    this.responseValidatorService.viewErrors(err);
  }

  public goToNext(nextAction: NextActionType): void {
    if (nextAction === NextActionType.NextStep) {
      this.router.navigate([this.navigationHelper.getNextRoute(this.route.snapshot)]);
      return;
    }

    this.router.navigate([this.navigationHelper.getRouteFor(nextAction as string, this.route.snapshot)]);
  }

  private shouldOmitNextOrderStateChange(): void {
    this.omitNextOrderStateChange = true;
  }

  private shouldShowNoticeOnCollision(): boolean {
    return this.screen.generalAdmission === true && this.cartEnabled === true && this.showNoticeOnCollision === true;
  }

  expandedDropdownEvent(event) {
    this.isDropdownExpanded = event;
  }

  public isNextButtonDisabled(): boolean {
    return !this._seatIds || 0 === this._seatIds.length;
  }

  get seatIds(): string[] {
    return cloneDeep(this._seatIds);
  }

  public getPaddingBottom(): string {
    const seats = this.screen.pseats.length;
    return `${(seats > 9 ? (seats - 9) * 4 : 0) + 30}px`;
  }

  showErrorPopup() {
    this.modalRef = this.modalService.show(this.errorPopupTemplate, { ignoreBackdropClick: true, class: 'modal-sm' });
  }

  closeErrorPopup() {
    this.modalService.hide();
  }
}
