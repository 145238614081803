import { Component } from '@angular/core';
import { CustomOptions } from '@lib/core';
import { ReservationViewModel } from 'libs/core/src/lib/model/view-model/account-items/reservation/reservation.view.model';
import { UserUpcomingComponent } from 'libs/shared/src/lib/component/user/user-upcoming/user-upcoming.component';

@Component({
  selector: 'app-user-upcoming',
  templateUrl: './user-upcoming.component.html',
  styleUrls: ['./user-upcoming.component.scss'],
})
export class CustomUserUpcomingComponent extends UserUpcomingComponent {
  override adaptScreeningWithTickets(items: ReservationViewModel[]) {
    const options = { withPurchased: true } as CustomOptions;
    return this.screeningWithTicketsAdapter.adapt(items, options);
  }
}
