import {DateTime} from 'luxon';
import { AbstractViewModel } from '../../abstract.view.model';
import { ReservationMovieApiModel } from '../../api-model/reservation/reservation-movie.api.model';
import {MoviePrintViewModel} from '../movie/movie-print.view.model';
import { TagGroupViewModel } from '../tag-group.view.model';

export class ReservationMovieViewModel extends AbstractViewModel<ReservationMovieApiModel> {
  id: string;
  duration: number;
  ageLongDescription: string;
  releaseDate: DateTime;
  posters: string[];
  pictures: string[];
  title: string;
  premiereDate: DateTime;
  tagGroups: TagGroupViewModel[];
  moviePrint: MoviePrintViewModel;

  constructor(
    protected apiModel: ReservationMovieApiModel = new ReservationMovieApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.duration = this.apiModel.duration;
    this.ageLongDescription = this.apiModel.ageLongDescription;
    this.releaseDate = this.apiModel.releaseDate ? DateTime.fromISO(this.apiModel.releaseDate) : null;
    this.posters = this.apiModel.posters ?? [];
    this.pictures = this.apiModel.pictures ?? [];
    this.title = this.apiModel.title;
    this.premiereDate = this.apiModel.premiereDate ? DateTime.fromISO(this.apiModel.premiereDate) : null;
    this.tagGroups = this.apiModel.tagGroups ? this.apiModel.tagGroups.map(item => new TagGroupViewModel(item)) : [];
    this.moviePrint = new MoviePrintViewModel(this.apiModel.moviePrint);
  }

  toApiModel(): ReservationMovieApiModel {
    return undefined;
  }
}
