import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../../abstract.view.model';
import { UserCardApiModel } from '../../../api-model/user-history/card/user-card.api.model';
import { UserCardTypeViewModel } from '../card-type/user-card-type.view.model';

export class UserCardViewModel extends AbstractViewModel<UserCardApiModel> {
  public id: string = null;
  public name: string = null;
  public pointBalance: number = null;
  public valueBalance: number = null;
  public issueDate: DateTime = null;
  public expiryDate: DateTime = null;
  public layout: string = null;
  public typeId: string = null;
  public type: number = null;
  public message: string = null;
  public templateId: string = null;
  public cardBatchId: string = null;
  public number: string = null;
  /**
   0 – not active
   1 – issued, not expired
   2 – registered, not expired
   3 – expired or blocked
   4 - used
   */
  public status: number = null;
  public hidden: boolean = null;
  public picture: string = null;
  public description: string = null;

  /* CUSTOM FIELDS */
  public cardType: UserCardTypeViewModel = null;
  public isUsed = false;
  public batchName: string = null;

  constructor(protected apiModel: UserCardApiModel = new UserCardApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.pointBalance = this.apiModel.pointBalance;
    this.valueBalance = this.apiModel.valueBalance;
    this.issueDate = this.apiModel.issueDate;
    this.expiryDate = this.apiModel.expiryDate;
    this.layout = this.apiModel.layout;
    this.typeId = this.apiModel.typeId;
    this.message = this.apiModel.message;
    this.templateId = this.apiModel.templateId;
    this.cardBatchId = this.apiModel.cardBatchId;
    this.number = this.apiModel.number;
    this.status = this.apiModel.status;
    this.hidden = this.apiModel.hidden;
    this.picture = this.apiModel.graphic;
    this.description = this.apiModel.description;
  }

  toApiModel(): UserCardApiModel {
    return undefined;
  }

  get batchNameOrDefault() {
    return this.batchName ?? this.name;
  }
}
