import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, delay, of, Subscription, switchMap, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { LoadingStatus } from 'libs/core/src/lib/model/loading/loading-status.enum';
import { BasketPageModel } from 'libs/core/src/lib/model/page/basket/basket.page.model';
import { PersonalComponent } from '../../component/personal/personal.component';
import { ResponseValidatorService } from 'libs/core/src/lib/service/validator/response-validator.service';
import { BasketDataProvider } from 'libs/core/src/lib/data-provider/basket.data-provider';
import { OrderDataProvider } from 'libs/core/src/lib/data-provider/order.data-provider';
import { LoginState } from 'libs/core/src/lib/enum/state';
import { MessageModel, MessageType } from 'libs/core/src/lib/model/message.model';
import { PersonalModel } from 'libs/core/src/lib/model/personal.model';
import { OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { CountdownComponentService } from 'libs/core/src/lib/service/countdown.service';
import { MessageService } from 'libs/core/src/lib/service/message.service';
import { NavigationHelperService } from 'libs/core/src/lib/service/navigation/navigation-helper.service';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { GoogleTagManagerService } from 'libs/core/src/lib/service/analytics-services/google-tag-manager.service';
import { AgreementViewModel } from 'libs/core/src/lib/model/view-model/agreement/agreement.view.model';
import { storageKey } from 'libs/core/src/app.const';
import { UserDataProvider } from 'libs/core/src/lib/data-provider/user.data-provider';
import { UserApiModel } from 'libs/core/src/lib/model/api-model/user/user.api.model';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { StepsService } from 'libs/core/src/lib/service/steps.service';
import { Location } from '@angular/common';

@Component({
  template: '',
})
export class PersonalPageComponent implements OnInit, OnDestroy {
  loaderEnum: typeof LoaderEnum = LoaderEnum;
  private orderStateSubscription: Subscription;
  public loadingStatus = LoadingStatus.pending;
  public formErrors: Object = null;
  public basketPageModel: BasketPageModel = null;

  public canUpdateOrderAgreements = true;
  public order: OrderViewModel;

  @ViewChild('personalComponent') public personalComponent: PersonalComponent;

  constructor(
    protected responseValidator: ResponseValidatorService,
    protected orderDataProvider: OrderDataProvider,
    protected orderStateService: OrderStateService,
    protected navigationHelper: NavigationHelperService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected countdownComponentService: CountdownComponentService,
    protected messageService: MessageService,
    protected translate: TranslateService,
    protected authStateService: AuthStateService,
    protected basketDataProvider: BasketDataProvider,
    protected googleTagManagerService: GoogleTagManagerService,
    protected userDataProvider: UserDataProvider,
    protected loadingService: LoadingService,
    protected stepsService: StepsService,
    protected location: Location
  ) {}

  ngOnInit() {
    const random = Math.random();
    this.showLoader(random);

    this.countdownComponentService.start();

    this.orderStateSubscription = this.orderStateService.state$
      .pipe(
        switchMap((order) => {
          // Transform data here
          this.order = order;
          return this.basketDataProvider.do(order, order?.cinemaId).pipe(
            catchError(() => {
              this.router.navigate(['error', '20001']);
              return of(null);
            })
          );
        })
      )
      .subscribe((basketPageModel) => {
        this.basketPageModel = basketPageModel;
        this.hideLoader(random);
      });

    this.googleTagManagerService.addToCart();
  }

  showLoader(processKey?: number) {
    if (processKey) {
      this.processKey = processKey;
    }

    if (this.loadingStatus === LoadingStatus.pending) {
      return;
    }

    this.loadingStatus = LoadingStatus.pending;
    this.loadingService.showLoader(LoaderEnum.MAIN);
  }

  hideLoader(processKey?: number) {
    if (this.processKey && this.processKey != processKey) {
      return;
    }

    this.processKey = undefined;
    this.loadingStatus = LoadingStatus.success;
    this.loadingService.hideLoader(LoaderEnum.MAIN);
    this.loadingService.hideLoader(LoaderEnum.NEXT_BUTTON);
  }

  processKey: number = 0;
  onUserFormSubmit(value: PersonalModel) {
    const random = Math.random();

    this.showLoader(random);

    const orderRequest = Object.assign(this.order.toApiModel(), {
      userEmail: value.email,
      userFirstName: value.firstname,
      userLastName: value.lastname,
      userPhone: value.phone,
      taxId: value.taxId,
    });

    this.orderDataProvider
      .put(this.order.cinemaId, orderRequest)
      .pipe(
        delay(0),
        tap((result: OrderViewModel) => {
          this.orderStateService.setOrder(result);
        }),
        switchMap(() => {
          this.canUpdateOrderAgreements = false;
          return this.putOrderAgreements();
        }),
        switchMap(() => this.putUserAgreements(value))
      )
      .subscribe({
        next: () => {
          this.goNextRoute();
          this.hideLoader(random);
        },
        error: (errors: HttpErrorResponse) => {
          this.hideLoader(random);
          this.formErrors = this.responseValidator.handleProperiesErrors(errors);
        },
      });
  }

  onLoginFormEventEmit(state: LoginState) {
    this.messageService.clear();

    if (state === LoginState.onRegister) {
      this.goToLoginFormRegister();
    }

    if (state === LoginState.LogInOK) {
      this.orderDataProvider.put(this.order.cinemaId, this.order.toApiModel()).subscribe(() => {
        this.goNextRoute();
      });
    }

    if (state === LoginState.LogInFault) {
      this.onLoginError();
    }
  }

  goToLoginFormRegister() {
    this.router.navigate(['user-card-register']);
  }

  goNextRoute() {
    if (this.order.getPrice() === 0) {
      this.showLoader();
      this.orderDataProvider.closeBasket(this.order.cinemaId, this.order.id).subscribe({
        complete: () => {
          const nextRoute = this.navigationHelper.getNextRouteFor(this.route.snapshot.data.pageIdentify);
          const finalRoute = this.navigationHelper.getNextRouteFor(nextRoute);
          this.router.navigate([finalRoute], {
            queryParams: {
              orderId: this.order.id,
              cinemaId: this.order.cinemaId,
            },
          });
        },
      });
    } else {
      this.router.navigate([this.navigationHelper.getNextRoute(this.route.snapshot)]);
    }
  }

  onLoginError() {
    this.messageService.add(new MessageModel(MessageType.warning, this.translate.instant('errors.10008'), '10008'));
  }

  ngOnDestroy(): void {
    this.orderStateSubscription?.unsubscribe();
  }

  public onStepBackClick(): void {
    window.history.back();
  }

  public onNavigationClick(event: string) {
    switch (event) {
      case 'previous':
        this.router.navigate([this.navigationHelper.getPreviousRoute(this.route.snapshot)]);
        break;
      case 'next':
        if (this.canUpdateOrderAgreements) {
          this.putOrderAgreements();
        }

        if (this.personalComponent) {
          this.personalComponent.onSubmit();
        } else {
          this.router.navigate([this.navigationHelper.getNextRoute(this.route.snapshot)]);
        }

        break;
    }
  }

  private getAgreements() {
    let personalAgreementsLocalStorage: AgreementViewModel[] = [];

    if (this.orderStateService.getItem(storageKey.personalAgreements)) {
      personalAgreementsLocalStorage = JSON.parse(this.orderStateService.getItem(storageKey.personalAgreements));
    }

    if (this.basketPageModel) {
      this.basketPageModel.agreements = this.basketPageModel.agreements
        ?.sort(function (x, y) {
          const xNum = x.required ? 1 : 0;
          const yNum = y.required ? 1 : 0;
          return yNum - xNum;
        })
        .map((agreement) => {
          const agreementLocalStorage = personalAgreementsLocalStorage.find((agr) => agr.id === agreement.id);
          agreement.checked = agreementLocalStorage ? agreementLocalStorage.checked : false;
          return agreement;
        });
    }

    return this.basketPageModel ? this.basketPageModel.agreements : [];
  }

  private getBasketAgreements() {
    return this.basketPageModel.agreements?.filter((agreement) => agreement.checked === true) ?? [];
  }

  get isLoaded() {
    return this.loadingStatus === LoadingStatus.success;
  }

  protected putOrderAgreements() {
    return this.orderDataProvider.updateAgreements(
      this.order.cinemaId,
      this.order.id,
      this.getBasketAgreements().map((element) => element.id)
    );
  }

  protected putUserAgreements(value: PersonalModel) {
    const user = Object.assign(new UserApiModel(), {
      ...this.authStateService.getUser(),
      ...{ phone: value.phone },
      ...{ token: this.authStateService.getToken() },
    });
    if (!user || !user.token) {
      return of(null);
    }

    const agreements = this.getBasketAgreements();
    user.agreements = [
      ...user.agreements.filter(
        (us) =>
          !agreements
            .filter((a) => a.checked)
            .map((a) => a.id.toUpperCase())
            .includes(us.toUpperCase())
      ),
      ...agreements.filter((a) => a.checked).map((a) => a.id),
    ];

    if (user.agreements.length > 0) {
      return this.userDataProvider.update(user, user.token).pipe(tap((user) => this.authStateService.setUser(user.toApiModel())));
    }

    return of(null);
  }

  goToPreviousRoute(): void {
    this.router.navigate([this.navigationHelper.getPreviousRoute(this.route.snapshot)]);
  }

  canShowSubmitButton() {
    return this.authStateService.userIsLoggedAndTokenIsValid() || this.personalComponent?.personalForm?.value?.autoRegisterAccount !== null;
  }
}
