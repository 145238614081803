<h1>TEST</h1>

<h2>UI</h2>
<div class="d-flex gap-1">
  <ng-container [ngTemplateOutlet]="uiPropertiesTemplate"></ng-container>
</div>
<div class="d-flex flex-wrap gap-1">
  <ng-container [ngTemplateOutlet]="appRadio"></ng-container>
  <ng-container [ngTemplateOutlet]="appCheckbox"></ng-container>
  <ng-container [ngTemplateOutlet]="appNumericStepper"></ng-container>
  <ng-container [ngTemplateOutlet]="appDateTimePicker"></ng-container>
  <ng-container [ngTemplateOutlet]="appDatePicker"></ng-container>
  <ng-container [ngTemplateOutlet]="appDateComponent"></ng-container>
  <ng-container [ngTemplateOutlet]="appTabs"></ng-container>
</div>

<h2>Components</h2>
<div class="d-flex gap-1">
  <ng-container [ngTemplateOutlet]="componentsPropertiesTemplate"></ng-container>
</div>
<div class="d-flex flex-wrap gap-1">
  <ng-container [ngTemplateOutlet]="userCartTemplate"></ng-container>
  <ng-container [ngTemplateOutlet]="appFlagTemplate"></ng-container>
</div>


<!-- UI -->
<ng-template #uiPropertiesTemplate>
  <div class="card">
    <h6>Properties</h6>
    <div>
      <label for="sideSelector">Select Side:</label>
      <select id="sideSelector" [(ngModel)]="side" (change)="onSideChange($event)">
        <option value="left">Left</option>
        <option value="right">Right</option>
      </select>
    </div>
  </div>
</ng-template>

<ng-template #appRadio>
  <div class="card component">
    <h6>Radio buton</h6>
    <small>app-radio</small>
    <div class="d-flex flex-column gap-1 mt-3">
      <app-radio id="radio1" name="radio_UITest_1" [side]="side" [value]="1" [disabled]="true">Disabled
        Radio</app-radio>
      <app-radio id="radio3" name="radio_UITest_1" [side]="side" [value]="2" [disabled]="true" [checked]="true">Disabled
        Checked Radio</app-radio>
      <app-radio id="radio2" name="radio_UITest_2" [side]="side" [value]="1">Acive Radio</app-radio>
      <app-radio id="radio4" name="radio_UITest_2" [side]="side" [value]="2" [checked]="true">Acive Checked
        Radio</app-radio>
    </div>
  </div>
</ng-template>

<ng-template #appCheckbox>
  <div class="card component">
    <h6>Checkbox</h6>
    <small>app-checkbox</small>
    <div class="d-flex flex-column gap-1 mt-3">
      <app-checkbox id="checkbox1" name="checkbox_UITest" [side]="side" [disabled]="true">Disabled
        Checkbox</app-checkbox>
      <app-checkbox id="checkbox3" name="checkbox_UITest" [side]="side" [disabled]="true" [checked]="true">Disabled
        Checked Checkbox</app-checkbox>
      <app-checkbox id="checkbox2" name="checkbox_UITest" [side]="side">Active Checkbox</app-checkbox>
      <app-checkbox id="checkbox4" name="checkbox_UITest" [side]="side" [checked]="true">Active Checked
        Checkbox</app-checkbox>
    </div>
  </div>
</ng-template>

<ng-template #appNumericStepper>
  <div class="card component">
    <h6>Numeric stepper</h6>
    <small>app-numeric-stepper</small>
    <div class="d-flex flex-column gap-1 mt-3">
      <app-numeric-stepper quantity="10" [disabled]="true"></app-numeric-stepper>
      <app-numeric-stepper quantity="5" [inputField]="true" [disabled]="true"></app-numeric-stepper>

      <app-numeric-stepper></app-numeric-stepper>
      <app-numeric-stepper [inputField]="true" [selectOnFocus]="true"></app-numeric-stepper>
    </div>
  </div>
</ng-template>

<ng-template #appDateTimePicker>
  <div class="card component">
    <h6>Date Time picker</h6>
    <small>app-datetime-picker</small>
    <div class="d-flex flex-column gap-1 mt-3">
      <app-datetime-picker></app-datetime-picker>
    </div>
  </div>
</ng-template>

<ng-template #appDatePicker>
  <div class="card component">
    <h6>Date picker</h6>
    <small>app-date-picker</small>
    <div class="d-flex flex-column gap-1 mt-3">
      <app-date-picker></app-date-picker>
    </div>
  </div>
</ng-template>

<ng-template #appDateComponent>
  <div class="card component">
    <h6>Date component</h6>
    <small>app-date-component</small>
    <div class="d-flex flex-column gap-1 mt-3">
      <app-date-component sortType="dmy"></app-date-component>
    </div>
  </div>
</ng-template>

<ng-template #appTabs>
  <div class="card component">
    <h6>Tabs</h6>
    <small>app-tabs</small>
    <div class="d-flex flex-column gap-1 mt-3">
      <app-tabs>
        <ng-container
                      *ngFor="let tab of [{title:'Tab 1', active: false}, {title:'Tab 2', active: true}, {title:'Tab 3', active: true}]">
          <app-tab [title]="tab.title" [active]="tab.active"></app-tab>
        </ng-container>
      </app-tabs>
    </div>
  </div>
</ng-template>

<!-- Components -->
<ng-template #componentsPropertiesTemplate>
  <div class="card">
    <h6>Properties</h6>
    <div>
      <label for="orderFlowSelector">Order flow:</label>
      <select id="orderFlowSelector" [(ngModel)]="orderFlow" (change)="onOrderFlowChange($event)">
        <option value="standard">Standard</option>
        <option value="rewards">Rewards</option>
      </select>
    </div>
  </div>
</ng-template>

<ng-template #appFlagTemplate>
  <div class="card component">
    <h6>Flag</h6>
    <small>app-flag</small>
    <div class="d-flex flex-column gap-1 mt-3">
      <!-- content after-flag -->
      <app-flag lang="gsw"><span after-flag>gsw</span></app-flag>
      <app-flag lang="fr"><span after-flag>fr</span></app-flag>
      <app-flag lang="en"><span after-flag>en</span></app-flag>
      <app-flag lang="gsw"><span after-flag>gsw</span></app-flag>
      <app-flag lang="mul"><span after-flag>mul</span></app-flag>

      <!-- content bfore-flag -->
      <app-flag lang="gsw"><span before-flag>gsw</span></app-flag>
      <app-flag lang="fr"><span before-flag>fr</span></app-flag>
      <app-flag lang="en"><span before-flag>en</span></app-flag>
      <app-flag lang="gsw"><span before-flag>gsw</span></app-flag>
      <app-flag lang="mul"><span before-flag>mul</span></app-flag>
    </div>
  </div>
</ng-template>

<ng-template #userCartTemplate>
  <div class="card component">
    <h6>User Cart</h6>
    <small>user-cart</small>
    <div class="d-flex flex-column gap-1 mt-3">
      <ng-container *ngIf="testObject">
        <user-cart [testModel]="testObject"></user-cart>
      </ng-container>
    </div>
  </div>
</ng-template>