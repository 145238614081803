import { AfterViewInit, Component, EventEmitter, Inject, isDevMode, OnDestroy, OnInit, Output } from '@angular/core';
import { first, forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import PaymentData = GooglePay.PaymentData;
import { PaymentProviderComponentInterface } from '../payment-provider.component.interface';
import { GooglePay } from '../gpay/client/client';
import { OrderDataProvider } from 'libs/core/src/lib/data-provider/order.data-provider';
import { PaymentProviderConfigRequestModel } from 'libs/core/src/lib/model/request/payment-provider-config.request.model';
import { PaymentProviderPayMethodRequestModel } from 'libs/core/src/lib/model/request/payment-provider-pay-method.request.model';
import { PaymentConfigViewModel } from 'libs/core/src/lib/model/view-model/payment/config/payment.config.view.model';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { PaymentProviderStateService } from '../../../service/payment-provider-state.service';
import {
  PaymentProviderEvent,
  DonePaymentProviderEvent,
  ErrorPaymentKeyProviderEvent,
  ErrorPaymentProviderEvent,
  WorkPaymentProviderEvent,
  WaitPaymentProviderEvent,
} from '../../event/payment-provider.event';
import { OrderPaymentPackage } from '../../../../../../../../core/src/lib/model/order-payment.package.model';
import { CreatePaymentDataRequestDto, GooglePayService } from '../gpay/service/google-pay.service';
import { ENVIRONMENT_TOKEN, FinancialHelper, loadScript, loadScriptPromise, ProviderEnum, sanitizeInput } from 'libs/core/src/public-api';
import { PaymentProviderPayMethodViewModel } from 'libs/core/src/lib/model/view-model/payment-provider-pay-method.view.model';
import { PaymentViewModel } from 'libs/core/src/lib/model/view-model/payment.view.model';
import { OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { ApplePayService } from '../apay/service/apple-pay.service';
import { FormGroup } from '@angular/forms';
import { CreatePaymentRedirectUrl } from '../../../create-payment-redirect-url';
import { ActivatedRoute } from '@angular/router';
import { PaymentHelperService } from '../../../service/payment-helper.service';
import { PaymentMethodEnum } from '../../model/payment-method.enum';
import { DateTime } from 'luxon';
import { PaymentAuthMethod, PaymentCardNetwork, PaymentDataModel, PaymentTotalPriceStatus } from '../gpay/model/payment-data.model';
import { MobileDeviceUtils } from 'libs/core/src/lib/utilities/mobile-device-utils';
import { generalPaymentError, przelewy24PaymentError } from '../../../payment-error.consts';
import { P24MessageData, P24TokenizationData } from './model/p24.interface';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppService } from 'libs/core/src/lib/service/app.service';
import { cardTokenizationIframeOptions } from './model/card-tokenization-iframe-options';
import { LoggerService } from 'libs/core/src/lib/service/logger/logger.service';
import { IOrderPaymentPackage } from 'libs/core/src/lib/model/interface/order-payment.package.model';
import { OrderPaymentRequestModel } from 'libs/core/src/lib/model/request/order-payment.request.model';

declare let ApplePaySession: any | undefined;
declare var Przelewy24CardTokenization: any | undefined;

export enum PayProvider {
  GOOGLE = 'GooglePay',
  APPLE = 'ApplePay',
}

export enum Przelewy24PayWithProvider {
  GOOGLE = 'Przelewy24PayWithGoogle',
  APPLE = 'Przelewy24ApplePay',
}

const P24_PayPo_ID = '227';

@UntilDestroy()
@Component({
  selector: 'app-payment-provider-p24-component',
  templateUrl: './p24-payment-provider.component.html',
})
export class P24PaymentProviderComponent implements PaymentProviderComponentInterface, OnInit, AfterViewInit, OnDestroy {
  @Output()
  public events: EventEmitter<PaymentProviderEvent> = new EventEmitter<PaymentProviderEvent>();

  private _messageListener: (event: MessageEvent) => void;

  public waitSecondsForTransactionStep = 7;
  public cardWaitSecondsForTransactionStep = 4;

  public paymentMethod = PaymentMethodEnum;
  public readonly paymentProviderIdentifier: string = ProviderEnum.P24;

  public availablePaymentMethods: {
    pbl: PaymentProviderPayMethodViewModel[];
    card: PaymentProviderPayMethodViewModel;
    cardToken: PaymentProviderPayMethodViewModel[];
    blik: PaymentProviderPayMethodViewModel;
    blikT6: PaymentProviderPayMethodViewModel;
    blikToken: PaymentProviderPayMethodViewModel;
    paypo: PaymentProviderPayMethodViewModel;
    apay: PaymentProviderPayMethodViewModel;
    gpay: PaymentProviderPayMethodViewModel;
  } = {
    pbl: [],
    card: null,
    cardToken: [],
    blik: null,
    blikT6: null,
    blikToken: null,
    paypo: null,
    apay: null,
    gpay: null,
  };

  public selectedMethod: PaymentProviderPayMethodViewModel | null = null;
  public selectedTokenCardMethod: PaymentProviderPayMethodViewModel | null = null;

  public selectedPayMethodTypeCustom: string | null = null;
  public paymentProviderPayMethodCollection: Array<PaymentProviderPayMethodViewModel> = new Array<PaymentProviderPayMethodViewModel>();

  public form: FormGroup;
  public blikFormGroup: FormGroup;
  public cardWithTokenizeFormGroup: FormGroup;
  public paypoFormGroup: FormGroup;

  public formSubmitAttempt = false;

  public paymentProviderConfig: PaymentConfigViewModel | null;
  private paymentProviderInitSubscription: Subscription = Subscription.EMPTY;

  private order: OrderViewModel = null;
  private sessionId: string = null;

  private readonly paymentChannel: string | null = null;

  private continueUrl: string;

  private timeSign = DateTime.now().toMillis();

  token: string | null = null;
  sign: string | null = null;

  private isApplePaySessionDefined = false;

  P24: any;

  creditCardFormIsReadyToTokenize = false;
  creditCardFormIsLoaded = false;

  private _cardTokenizationSubject: Subject<P24MessageData> = new Subject<P24MessageData>();
  private _cardTokenization$: Observable<P24MessageData> = this._cardTokenizationSubject.asObservable();

  public constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private orderDataProvider: OrderDataProvider,
    private orderStateService: OrderStateService,
    private applePayService: ApplePayService,
    private googlePayService: GooglePayService,
    private selectedPaymentTypeService: PaymentProviderStateService,
    private paymentHelperService: PaymentHelperService,
    private route: ActivatedRoute,
    public authStateService: AuthStateService,
    private appService: AppService,
    private loggerService: LoggerService
  ) {
    this.paymentChannel = this.environment.constants.paymentChannel;

    this.selectedPaymentTypeService.state$.pipe(untilDestroyed(this)).subscribe((state) => {
      if (state && (state.type !== this.selectedPayMethodTypeCustom || state.payByLink?.id !== this.selectedMethod?.id)) {
        this.selectedPayMethodTypeCustom = state.type;

        if (this.selectedPayMethodTypeCustom === PaymentMethodEnum.BLIK_T6) {
          this.selectedMethod = !this.availablePaymentMethods.blikToken ? this.availablePaymentMethods.blikT6 : this.availablePaymentMethods.blikToken;
        } else if (this.selectedPayMethodTypeCustom === PaymentMethodEnum.BLIK) {
          this.selectedMethod = this.availablePaymentMethods.blik;
        } else if (this.selectedPayMethodTypeCustom === PaymentMethodEnum.PAY_PO) {
          this.selectedMethod = this.availablePaymentMethods.paypo;
        } else {
          this.selectedMethod = state.payByLink;
        }

        //TOKENIZACJA I OBSŁUGA PŁATNOŚCI KARTĄ PRZEZ P24 w IFRAME
        if (this.selectedPayMethodTypeCustom === PaymentMethodEnum.CREDIT_CARD) {
          this.registerCardTokenizationIframe();
        }
      } else {
        this.selectedPayMethodTypeCustom = null;
        this.selectedMethod = null;
      }
    });
  }

  public ngOnInit(): void {
    this.applePayService.checkApplePayAvailability().then((isAvailable) => {
      if (isAvailable) {
        this.isApplePaySessionDefined = true;
      }
    });
  }

  public ngAfterViewInit(): void {
    this.initialComponent();
  }

  private initialComponent() {
    this._messageListener = (event: MessageEvent) => this.receiveMessage(event);
    window.addEventListener('message', this._messageListener, false);

    this.order = this.orderStateService.getOrder();
    this.events.next(new WorkPaymentProviderEvent());

    this.form = this.selectedPaymentTypeService.getPrzelewy24RulesFormGroup();
    this.blikFormGroup = this.selectedPaymentTypeService.getPrzelewy24BlikFormGroup();
    this.cardWithTokenizeFormGroup = this.selectedPaymentTypeService.getPrzelewy24CardWithTokenizeGroup();
    this.paypoFormGroup = this.selectedPaymentTypeService.getPrzelewy24PayPoFormGroup();

    this.initPaymentProviderWidget();
  }

  public ngOnDestroy(): void {
    if (this.paymentProviderInitSubscription !== Subscription.EMPTY) {
      this.paymentProviderInitSubscription.unsubscribe();
      this.paymentProviderInitSubscription = Subscription.EMPTY;
    }

    this.form.reset();

    window.removeEventListener('message', this._messageListener);
  }

  private getLocalPaymentRedirectUrl() {
    const request: CreatePaymentRedirectUrl = new CreatePaymentRedirectUrl();
    request.paymentChannel = this.paymentChannel;
    request.order = this.order;
    request.route = this.route;
    return request;
  }

  public onPostInitPayment(paymentModel: PaymentViewModel): void {
    if (this.canUsePaymentMethod(PaymentMethodEnum.PBL, PaymentMethodEnum.PAY_PO, PaymentMethodEnum.CREDIT_CARD, PaymentMethodEnum.CREDIT_CARD_TOKEN)) {
      this.redirect(paymentModel.plainPayload);
      return;
    }

    if (this.canUsePaymentMethod(PaymentMethodEnum.GPAY)) {
      this.payWith(PayProvider.GOOGLE, paymentModel);
    }

    if (this.canUsePaymentMethod(PaymentMethodEnum.APAY)) {
      this.payWith(PayProvider.APPLE, paymentModel);
    }

    if (this.canUsePaymentMethod(PaymentMethodEnum.BLIK)) {
      if (paymentModel.type === PaymentMethodEnum.BLIK) {
        this.redirect(paymentModel.plainPayload);
      } else {
        const waitSeconds: number = this.waitSecondsForTransactionStep;
        this.events.next(new WaitPaymentProviderEvent(waitSeconds));

        setTimeout(() => {
          this.redirect(this.paymentHelperService.createPaymentRedirectUrl(this.getLocalPaymentRedirectUrl(), this.continueUrl));
        }, waitSeconds * 1000);
      }
    }
  }

  public onPreInitPayment(event: OrderPaymentPackage): Observable<OrderPaymentPackage> {
    this.continueUrl = event.continueUrl;

    return new Observable<OrderPaymentPackage>((subscriber) => {
      if (this.canUsePaymentMethod(PaymentMethodEnum.PBL)) {
        if (!this.form.valid || !this.form.get('regulationAccept').value) {
          event.abort = true;
          this.emitNotAcceptedProviderRulesEvent();
        } else if (!this.selectedMethod) {
          event.abort = true;
          this.emitNotSelectedPaymentProviderEvent();
        } else {
          event.intPayMethodType = this.selectedMethod.type;
          event.intPayMethodValue = this.selectedMethod.id;
        }

        subscriber.next(event);
        subscriber.complete();
        return;
      }

      if (this.canUsePaymentMethod(PaymentMethodEnum.PAY_PO)) {
        if (!this.paypoFormGroup.valid) {
          event.abort = true;
        } else if (!this.selectedMethod) {
          event.abort = true;
          this.emitNotSelectedPaymentProviderEvent();
        } else {
          event.intPayMethodType = this.selectedMethod.type;
          event.intPayMethodValue = this.selectedMethod.id;
          event.clientAddress = this.paypoFormGroup.get('clientAddress').value;
        }

        subscriber.next(event);
        subscriber.complete();
        return;
      }

      if (this.canUsePaymentMethod(PaymentMethodEnum.BLIK)) {
        if (this.selectedMethod.type === PaymentMethodEnum.BLIK_TOKEN) {
          event.intPayMethodType = this.selectedMethod.type;
          event.intPayMethodValue = this.selectedMethod.token;
        } else if (this.selectedMethod.type === PaymentMethodEnum.BLIK) {
          event.intPayMethodType = this.selectedMethod.type;
          event.intPayMethodValue = this.selectedMethod.id;
        } else if (this.selectedMethod.type === PaymentMethodEnum.BLIK_T6) {
          this.formSubmitAttempt = true;
          if (this.blikFormGroup.valid) {
            const blikCodeValue = this.blikFormGroup.get('blikCode').value;
            const filteredInputBlikCode: string | null = sanitizeInput(blikCodeValue);
            event.intPayMethodType = this.selectedMethod.type;
            event.intPayMethodValue = filteredInputBlikCode;
          } else {
            event.abort = true;
          }
        }

        subscriber.next(event);
        subscriber.complete();
        return;
      }

      if (this.canUsePaymentMethod(PaymentMethodEnum.CREDIT_CARD)) {
        if (this.selectedTokenCardMethod !== null) {
          event.intPayMethodType = this.selectedTokenCardMethod.type;
          event.intPayMethodValue = this.selectedTokenCardMethod.token;
          subscriber.next(event);
          subscriber.complete();
        } else if (this.creditCardFormIsReadyToTokenize) {
          const saveToken = Boolean(Number(this.cardWithTokenizeFormGroup.get('saveToken').value));
          this.cardTokenizationPromise(saveToken ? 'permanent' : 'temporary')
            .then((data) => {
              event.intPayMethodType = PaymentMethodEnum.CREDIT_CARD_TOKEN;
              event.intPayMethodValue = data.refId;
              event.saveToken = saveToken;

              subscriber.next(event);
              subscriber.complete();
            })
            .catch((error) => this.handleError(event, error, subscriber));
        } else {
          this.emitIncorrectDataCreditCardFormEvent();
          event.abort = true;
          subscriber.next(event);
          subscriber.complete();
        }

        return;
      }

      if (this.canUsePaymentMethod(PaymentMethodEnum.GPAY)) {
        const handleGooglePay = () => {
          this.handleProcessForGooglePay(event, subscriber);
        };

        this.logAndGo(`Pre init payment by ${PayProvider.GOOGLE}`, handleGooglePay);
        return;
      }

      if (this.canUsePaymentMethod(PaymentMethodEnum.APAY)) {
        this.handleProcessForApplePay(event, subscriber);
        return;
      }

      event.abort = true;
      this.emitNotSelectedPaymentProviderEvent();
      subscriber.next(event);
      subscriber.complete();
    });
  }

  public onClickPaymentMethod(method: PaymentProviderPayMethodViewModel): void {
    const state = this.selectedPaymentTypeService.getState();
    this.selectedPaymentTypeService.setState({ ...state, payByLink: method });
  }

  public onClickTokenCardMethod(method: PaymentProviderPayMethodViewModel): void {
    this.selectedTokenCardMethod = this.selectedTokenCardMethod !== method ? method : null;
  }

  public onToggleBlikMethod(): void {
    this.selectedMethod =
      this.selectedMethod === this.availablePaymentMethods.blikT6 ? this.availablePaymentMethods.blikToken : this.availablePaymentMethods.blikT6;
  }

  private initPaymentProviderWidget(): void {
    const requestModel = new OrderPaymentRequestModel();
    requestModel.channel = this.paymentChannel;

    const orderPaymentPackage = new OrderPaymentPackage();
    orderPaymentPackage.cinemaId = this.order.cinemaId;
    orderPaymentPackage.orderId = this.order.id;
    orderPaymentPackage.paymentProviderIdentifier = this.paymentProviderIdentifier;
    orderPaymentPackage.requestModel = requestModel;

    this.paymentProviderInitSubscription = forkJoin({
      config: this.orderDataProvider.getPaymentProviderConfig(orderPaymentPackage),
      methods: this.orderDataProvider.getPaymentProviderPayMethodCollection(orderPaymentPackage),
    }).subscribe({
      next: (forkData) => {
        const methods = forkData.methods.filter((method) => method.status === 'ENABLED');

        this.availablePaymentMethods.card = methods.find((x) => x.type === PaymentMethodEnum.CREDIT_CARD) ?? null;
        this.availablePaymentMethods.cardToken = methods.filter((x) => x.type === PaymentMethodEnum.CREDIT_CARD_TOKEN);

        this.availablePaymentMethods.pbl = methods.filter((x) => x.type === PaymentMethodEnum.PBL);
        this.availablePaymentMethods.paypo = methods.find((x) => x.type === PaymentMethodEnum.PAY_PO);

        this.availablePaymentMethods.blik = methods.find((x) => x.type === PaymentMethodEnum.BLIK) ?? null;
        this.availablePaymentMethods.blikT6 = methods.find((x) => x.type === PaymentMethodEnum.BLIK_T6) ?? null;
        this.availablePaymentMethods.blikToken = methods.find((x) => x.type === PaymentMethodEnum.BLIK_TOKEN);

        this.availablePaymentMethods.apay =
          this.isApplePaySessionDefined && MobileDeviceUtils.isApple() ? methods.find((x) => x.type === PaymentMethodEnum.APAY) : null;

        this.availablePaymentMethods.gpay = methods.find((x) => x.type === PaymentMethodEnum.GPAY) ?? null;

        this.paymentProviderConfig = forkData.config;
        this.paymentProviderPayMethodCollection = methods;

        this.events.next(new DonePaymentProviderEvent());
      },
      error: (e: HttpErrorResponse) => this.events.next(new ErrorPaymentProviderEvent(e)),
    });
  }

  get getDefaultPaymentImageUrl() {
    return `/assets/images/payment-providers/Przelewy24_logo.png`;
  }

  addTimeSign(url) {
    return `${url}?t=${this.timeSign}`;
  }

  private canUsePaymentMethod(...params: PaymentMethodEnum[]) {
    return params.some((method) => this.selectedPayMethodTypeCustom.includes(method));
  }

  private handleProcessForGooglePay(event: any, subscriber: any): void {
    const merchantId = this.environment.paymentManager.przelewy24?.googlePayMerchantId;
    if (!merchantId) {
      this.handleError(event, 'No Google Pay merchantID.', subscriber);
      return;
    }

    const googlePayClient: GooglePay.PaymentsClient = this.googlePayService.getClient(this.paymentProviderConfig.environment);
    const orderPrice: number = this.order.getPrice();

    const gpayRequestData = new CreatePaymentDataRequestDto();

    gpayRequestData.gateway = this.paymentProviderIdentifier;
    gpayRequestData.gatewayMerchantId = this.paymentProviderConfig.merchantId;

    gpayRequestData.merchantId = merchantId;

    gpayRequestData.totalPriceStatus = PaymentTotalPriceStatus.FINAL;
    gpayRequestData.totalPrice = FinancialHelper.fixedValue(orderPrice);
    gpayRequestData.currencyCode = this.environment.constants.currency;
    gpayRequestData.countryCode = this.environment.constants.country;
    gpayRequestData.allowedCardAuthMethods = [PaymentAuthMethod.PAN_ONLY, PaymentAuthMethod.CRYPTOGRAM_3DS];
    gpayRequestData.allowedCardMethods = [PaymentCardNetwork.VISA, PaymentCardNetwork.MASTERCARD];

    const paymentDataRequest: PaymentDataModel = this.googlePayService.createPaymentDataRequest(gpayRequestData);

    const callLoadPaymentData = () => {
      googlePayClient
        .loadPaymentData(paymentDataRequest as any)
        .then((paymentData: PaymentData) => {
          const afterTokenization = () => {
            const token: string | null = paymentData?.paymentMethodData?.tokenizationData?.token ?? null;
            event.intPayMethodType = PaymentMethodEnum.GPAY;
            event.intPayMethodValue = btoa(token as string);
            subscriber.next(event);
            subscriber.complete();
          };

          this.logAndGo(`${PayProvider.GOOGLE}: complete tokenization`, afterTokenization);
        })
        .catch((error) => this.handleError(event, error, subscriber));
    };

    this.logAndGo(`${PayProvider.GOOGLE}: loadPaymentData()`, callLoadPaymentData);
  }

  private handleProcessForApplePay(event: any, subscriber: any): void {
    const merchantName = this.paymentProviderConfig.merchantName?.length ? this.paymentProviderConfig.merchantName : this.appService.projectTitle;
    const session = this.applePayService.getSession(merchantName, this.order.getPrice());
    if (!session) {
      const callAbortPayment = () => {
        event.abort = true;
        subscriber.next(event);
        subscriber.complete();
      };

      this.logAndGo(`${PayProvider.APPLE}: Session not created!`, callAbortPayment);
      return;
    }

    session.onvalidatemerchant = (event) => {
      this.orderDataProvider.getApplePaySession(this.order.cinemaId, this.order.id, this.paymentProviderIdentifier).subscribe({
        next: (merchantSession) => {
          session.completeMerchantValidation(merchantSession);
        },
        error: (error: any) => {
          console.error('error:', error);
          session.abort();
        },
      });
    };

    session.onpaymentauthorized = (paymentEvent) => {
      const completePaymentModel = {
        status: ApplePaySession.STATUS_SUCCESS,
      };

      session.completePayment(completePaymentModel);

      const callCompletePayment = () => {
        const token: string | null = JSON.stringify(paymentEvent?.payment?.token?.paymentData) ?? null;
        event.intPayMethodType = PaymentMethodEnum.APAY;
        event.intPayMethodValue = btoa(token as string);
        subscriber.next(event);
        subscriber.complete();
      };

      this.logAndGo(`${PayProvider.APPLE}: completePayment()`, callCompletePayment);
    };

    session.begin();
  }

  private cardTokenizationPromise(mode: 'permanent' | 'temporary'): Promise<P24TokenizationData> {
    return new Promise((resolve, reject) => {
      this._cardTokenization$.pipe(first()).subscribe({
        next: (message: P24MessageData) => {
          if (message.type === 'success') {
            resolve(message.data);
          } else {
            console.error(message.errors);
            reject(new Error(message.errors[0]));
          }
        },
        error: (err) => {
          reject(err);
        },
      });

      this.P24.tokenize(mode);
    });
  }

  private registerCardTokenizationIframe() {
    this.creditCardFormIsLoaded = false;

    const data = {
      merchantId: +this.paymentProviderConfig.merchantId,
      sessionId: this.order.id,
      sign: this.paymentProviderConfig.providerConfiguration.sign,
    };

    const type = 'form';
    const id = '#creditcardbox';
    const src = `https://${isDevMode() ? 'sandbox' : 'secure'}.przelewy24.pl/js/cardTokenizationIframe.min.js`;

    setTimeout(() => {
      loadScript('przelewy24-tokenization', src, () => {
        this.P24 = new Przelewy24CardTokenization(data.merchantId, data.sessionId, data.sign);
        const myElement = document.getElementById('creditcardbox');
        this.P24.render(type, id, this.getCardTokenizationIframeOptions());
      });
    });
  }

  private getCardTokenizationIframeOptions() {
    return cardTokenizationIframeOptions; //TODO add translation
  }

  receiveMessage(event: MessageEvent) {
    if (event?.data?.p24) {
      if (this.selectedPayMethodTypeCustom === PaymentMethodEnum.CREDIT_CARD) {
        if (event.data.type === 'start') {
          this.creditCardFormIsLoaded = true;
          this.creditCardFormIsReadyToTokenize = false;
        } else if (event.data.type === 'ready') {
          this.creditCardFormIsReadyToTokenize = event.data.status;
        } else if (event.data.type === 'success' || event.data.type === 'fail') {
          this._cardTokenizationSubject.next(event.data);
        }
      }
    }
  }

  private emitNotSelectedPaymentProviderEvent() {
    this.events.emit(new ErrorPaymentKeyProviderEvent(generalPaymentError.NotSelectedPaymentProvider));
  }

  private emitNotAcceptedProviderRulesEvent() {
    this.events.emit(new ErrorPaymentKeyProviderEvent(przelewy24PaymentError.NotAcceptedProviderRules));
  }

  private emitIncorrectDataCreditCardFormEvent() {
    this.events.emit(new ErrorPaymentKeyProviderEvent(przelewy24PaymentError.IncorrectDataCreditCardForm));
  }

  private payWith(payProvider: PayProvider, paymentModel: PaymentViewModel) {
    const providerSignature =
      payProvider === PayProvider.GOOGLE ? Przelewy24PayWithProvider.GOOGLE : payProvider === PayProvider.APPLE ? Przelewy24PayWithProvider.APPLE : undefined;
    if (!providerSignature) {
      return;
    }

    const payWithProvider = () => {
      const scriptUrl = this.getScriptUrlForPayWithProvider(providerSignature, paymentModel.id);
      loadScriptPromise(scriptUrl, false)
        .then(() => {
          if (window[providerSignature]) {
            window[providerSignature].config({
              errorCallback: () => this.cancelBasketAndRedirect(`${providerSignature}: errorCallback`),
              exceptionCallback: (e) => this.cancelBasketAndRedirect(`${providerSignature}: exceptionCallback`, e),
              requestFailedCallback: (e, t, n) => this.cancelBasketAndRedirect(`${providerSignature}: requestFailedCallback`, e, t, n),
              completePaymentCallback: () => {
                const completePaymentProcess = () => {
                  this.events.next(new DonePaymentProviderEvent());
                  this.redirect();
                };

                this.logAndGo(`${providerSignature}: completePaymentCallback; Redirect to order summary`, completePaymentProcess);
              },
            });

            const callCharge = () => {
              window[providerSignature].charge();
            };

            this.logAndGo(`${providerSignature}: charge()`, callCharge);
          } else {
            this.cancelBasketAndRedirect(`${providerSignature}: not defined`);
          }
        })
        .catch((error) => {
          this.cancelBasketAndRedirect(`${providerSignature}: script loading failed: `, error);
        });
    };

    this.logAndGo(`Post init payment with ${payProvider} - Load script for ${providerSignature}`, payWithProvider);
  }

  private getScriptUrlForPayWithProvider(provider: Przelewy24PayWithProvider, token: string) {
    const endpoint = provider === Przelewy24PayWithProvider.GOOGLE ? 'payWithGoogle' : 'applepay';
    return `https://${isDevMode() ? 'sandbox' : 'secure'}.przelewy24.pl/bundle/${endpoint}/${token}`;
  }

  private redirect(url: string = null) {
    if (!url) {
      url = this.paymentHelperService.getTargetUrl(this.getLocalPaymentRedirectUrl());
    }

    window.location.href = url;
  }

  private cancelBasketAndRedirect(url: string, message?: any, ...optionalParams: any[]) {
    console.error(message, optionalParams);
    const failedProcess = () => {
      this.orderStateService.removeOrderWithDelete(true);
      setTimeout(() => {
        this.redirect();
      }, 200);
    };

    this.logAndGo(`Failed - ${message}`, failedProcess);
  }

  private handleError(event: any, error: any, subscriber: any): void {
    event.abort = true;
    console.error(error);
    subscriber.next(event);
    subscriber.complete();
  }

  private logAndGo(message: string, callback?: () => void) {
    const logData: { sessionId?: string } = {};
    if (this.order) {
      logData.sessionId = `${this.order.cinemaId}.${this.order.id}`;
    }

    this.loggerService.log(message, logData).subscribe({
      complete: callback,
    });
  }
}
