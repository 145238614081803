import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CardLoginRequestModel } from '../model/request/card-login.request.model';
import { Observable, Subject } from 'rxjs';
import { CardLoginResponseModel } from '../model/response/card-login.response.model';
import { map, tap } from 'rxjs/operators';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { CardTypeApiModel } from '../model/api-model/card/card-type.api.model';
import { CardApiModel } from '../model/api-model/card/card.api.model';
import { OrderApiModel } from '../model/api-model/order/order.api.model';
import { PurchaseCardTypeRequestModel } from '../model/request/purchase-card-type.request.model';
import { UseCardRequestModel } from '../model/request/use-card.request.model';
import { OrderHttpService } from './order.http.service';
import { CardTopUpApiModel } from '../model/api-model/card/card-top-up.api.model';

@Injectable({
  providedIn: 'root',
})
export class CardHttpService {
  public static cacheBuster$ = new Subject<void>();

  constructor(private http: HttpClient) {}

  public login(request: CardLoginRequestModel): Observable<CardLoginResponseModel> {
    return this.http
      .post('/card/login_check', instanceToPlain(request, { groups: ['login'] }))
      .pipe(map((result) => plainToInstance(CardLoginResponseModel, result as Object)));
  }

  public getCard(searchValue: string): Observable<CardApiModel> {
    return this.http.get<CardApiModel>('/card/' + searchValue).pipe(map((result) => plainToInstance(CardApiModel, result as Object)));
  }

  public types(cinemaId: string): Observable<CardTypeApiModel[]> {
    return this.http.get<CardTypeApiModel[]>('/card/types', { params: { cinemaId: cinemaId } });
  }

  public addCardToOrder(cinemaId: string, orderId: string, request: PurchaseCardTypeRequestModel): Observable<any> {
    return this.http.patch<CardTypeApiModel[]>(`/cinema/${cinemaId}/order/${orderId}/cardtypeitem`, instanceToPlain(request));
  }

  public useCard(cinemaId: string, orderId: string, request: UseCardRequestModel[]): Observable<any> {
    return this.http.patch<CardTypeApiModel[]>(`/cinema/${cinemaId}/order/${orderId}/internalPayment`, instanceToPlain(request));
  }

  public assignCardToOrder(cinemaId: string, orderId: string, cardId: string): Observable<OrderApiModel> {
    return this.http
      .patch<OrderApiModel>(`/cinema/${cinemaId}/order/${orderId}/card/${cardId}`, null)
      .pipe(tap((res) => OrderHttpService.cacheModify(res.id, res)));
  }

  public deleteCardFromOrder(cinemaId: string, orderId: string): Observable<OrderApiModel> {
    return this.http.delete<OrderApiModel>(`/cinema/${cinemaId}/order/${orderId}/card`).pipe(tap((res) => OrderHttpService.cacheModify(res.id, res)));
  }

  public deleteSubscription(cardId: string): Observable<any> {
    return this.http.delete(`/user/card/${cardId}/memebershipSubscription`);
  }

  public topUp(cinemaId: string, orderId: string, request: CardTopUpApiModel[]): Observable<CardTopUpApiModel[]> {
    return this.http.patch<CardTopUpApiModel[]>(`/cinema/${cinemaId}/order/${orderId}/cardtopup`, instanceToPlain(request));
  }
}
