import { Expose, Type } from 'class-transformer';
import { BannerApiModel } from '../banner.api.model';
import { GenreApiModel } from '../genre/genre.api.model';
import { RatingApiModel } from '../rating.api.model';
import { TagGroupApiModel } from '../tag-group.api.model';
import { EventItemApiModel } from './event-item.api.model';

export class EventApiModel {
  @Expose()
  id: string;

  @Expose()
  duration: number;

  @Expose()
  description: string;

  @Expose()
  ageLongDescription: string;

  @Expose()
  releaseDate: string;

  @Expose()
  distributor: string;

  @Expose()
  distributorId: string;

  @Expose()
  @Type(() => GenreApiModel)
  genres: GenreApiModel[];

  @Expose()
  trailers: string[];

  @Expose()
  posters: string[];

  @Expose()
  pictures: string[];

  @Expose()
  posPosters: string[];

  @Expose()
  @Type(() => BannerApiModel)
  banners: BannerApiModel[];

  @Expose()
  @Type(() => RatingApiModel)
  ratings: RatingApiModel[];

  @Expose()
  name: string;

  @Expose()
  link: string;

  @Expose()
  screeningId: string;

  @Expose()
  screenId: string;

  @Expose()
  cinemaId: string;

  @Expose()
  timeFrom: string;

  @Expose()
  timeTo: string;

  @Expose()
  saleTimeTo: string;

  @Expose()
  reservationTimeTo: string;

  @Expose()
  availabilityStatus: number;

  @Expose()
  externalId: number;

  @Expose()
  @Type(() => EventItemApiModel)
  items: EventItemApiModel[];

  @Expose()
  @Type(() => TagGroupApiModel)
  tagGroups: TagGroupApiModel[];

  @Expose()
  restrictedToCardTypeIds: string[];

  @Expose()
  release: string;

  @Expose()
  generalAdmission: boolean;

  @Expose()
  audience: number;

  @Expose()
  maxOccupancy: number;
}
