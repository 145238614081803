import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { appProjectName } from 'libs/core/src/app.const';
import { SeatsOccupancyService } from 'libs/core/src/lib/service/seats-occupancy.service';
import { AppService } from 'libs/core/src/lib/service/app.service';
import { MessageService } from 'libs/core/src/lib/service/message.service';
import { MessageModel, MessageType } from 'libs/core/src/lib/model/message.model';
import { ConfirmModalComponent } from '../../modal/confirm-modal/confirm-modal.component';
import { ENVIRONMENT_TOKEN, NotificationModel, NotificationService, NotificationType } from 'libs/core/src/public-api';
import { SeparatorViewModel } from 'libs/core/src/lib/model/view-model/screening/separator/separator.view.model';
import { ScreenViewModel } from 'libs/core/src/lib/model/view-model/screen/screen.view.model';
import { SeatViewModel } from 'libs/core/src/lib/model/view-model/screen/seat/seat.view.model';
import { OccupiedStatus } from 'libs/core/src/lib/enum/occupied-status.enum';
import { ScreenDataProvider } from 'libs/core/src/lib/data-provider/screen.data-provider';
import { SeatGroupSalesMode } from 'libs/core/src/lib/enum/seat-group-sales-mode.enum';
import { isNumber } from 'libs/core/src/lib/tool/number/number';
import { Coordinates } from 'libs/core/src/lib/model/coordinates.model';
import { SeatGroupDisplayMode } from 'libs/core/src/lib/enum/seat-group-display-mode.enum';
import { CurvedScreenSeatsComponent } from './curved-seats.component';
import { CmsHelper } from 'libs/core/src/lib/helper/cms.helper';

export interface ScreenSeatsComponentInterface {
  changeSeatState(s: string);
}

@Component({
  template: '',
})
export class ScreenSeatsComponent extends CurvedScreenSeatsComponent implements OnInit, ScreenSeatsComponentInterface {
  @Input() screen: ScreenViewModel = null;
  @Input() limit = 1;
  @Input() priceSeparatorsEnabled = false;
  @Input() clickedSeatGroupTypes: { [key: string]: boolean } = {};
  @Input() shouldShowPopupOnSelectedSeat = false;
  @Input() rowSeparators: Array<SeparatorViewModel> = new Array<SeparatorViewModel>();
  @Output() seatsSelectedEvent = new EventEmitter<[string[], boolean]>();
  @Output() seatPopupShowEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() lastSelectedSeatChange: EventEmitter<SeatViewModel> = new EventEmitter<SeatViewModel>();

  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;

  public lastSelectedSeat: SeatViewModel | null = null;
  public showSelectedSeatModal = false;
  public popupGroupTypeFilter: Array<string> | null = null;
  private debouncer: Subject<[Array<string>, boolean]> = new Subject<[Array<string>, boolean]>();
  private lastSeatModel: SeatViewModel;
  private lastSkipModal: boolean;
  private _seatIds: Array<string>;
  private isEmitted = true;

  private showPopupBeforeSeatSelect = this.appService.isProject(
    appProjectName.BLUE,
    appProjectName.CINESTAR,
    appProjectName.CINELAND,
    appProjectName.LANDMARK,
    appProjectName.ONEIL
  );
  private showPopupAfterSeatSelect = !this.appService.isProject(
    appProjectName.BLUE,
    appProjectName.CINESTAR,
    appProjectName.CINELAND,
    appProjectName.LANDMARK,
    appProjectName.ONEIL
  );

  get occupiedStatus() {
    return OccupiedStatus;
  }

  get seatIds(): Array<string> {
    return this._seatIds;
  }

  @Input()
  set seatIds(value: Array<string>) {
    if (this.isEmitted) {
      this._seatIds = value;
    }
  }

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected seatOccupancyService: SeatsOccupancyService,
    protected modalService: BsModalService,
    protected appService: AppService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected notificationService: NotificationService,
    private screenDataProvider: ScreenDataProvider,
    private cmsHelper: CmsHelper
  ) {
    super();
    this.debouncer
      .pipe(
        tap(() => (this.isEmitted = false)),
        debounceTime(500)
      )
      .subscribe((val) => {
        this.seatsSelectedEvent.emit(val);
        this.isEmitted = true;
      });

    this.popupGroupTypeFilter = environment.screen.seatPopup?.groupTypesFilter;
  }

  ngOnInit() {
    this.changeSeatState(this._seatIds, true);

    this.screen.pseats.forEach((pseat) => {
      pseat.forEach((s) => {
        s.connectSeat = this.connectSeat(this.screen, s);
      });
    });

    this.ifPossibleUseCoordinates(this.screen.pseats);
  }

  private createObservableForCateringWarning(): Subject<boolean> {
    const cateringWarning = new Subject<boolean>();

    cateringWarning.subscribe((allow) => {
      if (allow) {
        this.seatOccupancyService.isCateringSelected = false;
        this.onSeatClick(this.lastSeatModel, this.lastSkipModal);
      } else {
        this.lastSeatModel.occupied = this.lastSeatModel.occupied === OccupiedStatus.Free ? OccupiedStatus.Mine : OccupiedStatus.Free;
      }
    });

    return cateringWarning;
  }

  public onSeatClick(seat: SeatViewModel, skipModal: boolean = false, oninit = false) {
    const addAction = seat.occupied === OccupiedStatus.Free;
    const ids: string[] = [seat.id];
    if (seat.groupConnectedSeats && seat.groupConnectedSeats.length > 0) {
      ids.push(...seat.groupConnectedSeats.map((o) => o.id));
    } else if (this.screen.groups.filter((f) => f.id === seat.groupId && f.salesMode === SeatGroupSalesMode.Together).length > 0) {
      const group = this.screen.groups.filter((f) => f.id === seat.groupId && f.salesMode === SeatGroupSalesMode.Together)[0];
      ids.push(...group.seatIds.filter((f) => f !== seat.id));
    }

    if (!skipModal && !oninit) {
      const checkSeatsLimit = this.seatOccupancyService.checkSeatsLimit(this.seatIds, seat, this.limit);
      const checkMaxOccupancyLimit = this.seatOccupancyService.checkMaxOccupancyLimit(this.seatIds, seat);
      if (!checkSeatsLimit || !checkMaxOccupancyLimit) {
        if (!this.cmsHelper.canUseCms) {
          const messageType = this.appService.isProject(appProjectName.SCHULMAN, appProjectName.VIOLET_CROWN, appProjectName.LANDMARK, appProjectName.ONEIL)
            ? MessageType.danger
            : MessageType.info;
          this.messageService.add(new MessageModel(messageType, this.translateService.instant('errors.105', { count: this.limit })));
        } else {
          this.notificationService.addNotification(
            new NotificationModel(this.translateService.instant('errors.105', { count: this.limit }), NotificationType.ALERT)
          );
        }
        return;
      }

      if (this.showPopupBeforeSeatSelect && seat.occupied === OccupiedStatus.Free && this.canShowPopup(seat, skipModal)) {
        return;
      }
    }

    if (!skipModal && this.seatOccupancyService.isCateringSelected) {
      this.lastSeatModel = seat;
      this.lastSkipModal = skipModal;

      const modal = this.modalService.show(ConfirmModalComponent);
      modal.content.subject = this.createObservableForCateringWarning();
      return;
    }

    //this.seatIds = ids;
    this.toggleOccupiedStatus(seat, oninit);
    if (!oninit) {
      if (addAction) {
        this._seatIds.push(...ids);
      } else {
        ids.forEach((id) => {
          const index = this._seatIds.indexOf(id, 0);
          if (index > -1) {
            this._seatIds.splice(index, 1);
          }
        });
      }
    }

    this.debouncer.next([this._seatIds, skipModal]);

    if (this.showPopupAfterSeatSelect && seat.occupied === OccupiedStatus.Mine && this.shouldShowPopupOnSelectedSeat === true) {
      this.canShowPopup(seat, skipModal);
    }

    this.seatOccupancyService.markMySeats(this.screen, this._seatIds, this.limit);
  }

  private canShowPopup(seat: SeatViewModel, skipModal: boolean = false) {
    const seatGroupTypes = seat.groupTypes.map((type) => {
      const lowerCaseType = type.trim().toLocaleLowerCase();
      return lowerCaseType === 'none' ? 'standard' : lowerCaseType;
    });

    if (this.popupGroupTypeFilter) {
      const popupGroupTypeFilter = (this.popupGroupTypeFilter ?? []).map((type) => type.trim().toLocaleLowerCase());
      if (!seatGroupTypes.some((groupType) => popupGroupTypeFilter.includes(groupType))) {
        return;
      }
    }

    let seatGroupType: string = seatGroupTypes.join('_');
    this.lastSelectedSeat = seat;
    if (this.clickedSeatGroupTypes[seatGroupType] === undefined) {
      this.clickedSeatGroupTypes[seatGroupType] = false;
    }
    if (this.clickedSeatGroupTypes[seatGroupType] === true) {
      console.log('canShowPopup', false);
      return false;
    }

    if (seat.groupDescriptionCollection.length > 0 && !skipModal) {
      this.showSelectedSeatModal = !this.clickedSeatGroupTypes[seatGroupType];
      this.clickedSeatGroupTypes[seatGroupType] = this.showSelectedSeatModal;

      if (this.clickedSeatGroupTypes[seatGroupType] === true) {
        this.lastSelectedSeatChange.next(this.lastSelectedSeat);
        this.seatPopupShowEvent.next(seatGroupType);

        // {showPopupBeforeSeatSelect, showPopupAfterSeatSelect} TODO przesłać inforamcję
        console.log('canShowPopup', true);
        return true;
      }
    }

    console.log('canShowPopup', false);
    return false;
  }

  public changeSeatState(seatid: string | string[], oninit = false) {
    if (!seatid) {
      return;
    }

    const seatIds = Array.isArray(seatid) ? seatid : [seatid];
    const seatIdSet = new Set(seatIds);

    if (this.screen) {
      for (const seatsRow of this.screen.pseats) {
        for (const seat of seatsRow) {
          if (seatIdSet.has(seat.id)) {
            this.onSeatClick(seat, true, oninit);
          }
        }
      }
    }
  }

  private toggleOccupiedStatus(seat: SeatViewModel, oninit: boolean) {
    if (oninit || seat.occupied === OccupiedStatus.Free) {
      seat.occupied = OccupiedStatus.Mine;
      seat.groupConnectedSeats = seat.groupConnectedSeats
        ? seat.groupConnectedSeats.map((c) => {
            c.occupied = OccupiedStatus.Mine;
            return c;
          })
        : null;
      return;
    }

    seat.occupied = OccupiedStatus.Free;
    seat.groupConnectedSeats = seat.groupConnectedSeats
      ? seat.groupConnectedSeats.map((c) => {
          c.occupied = OccupiedStatus.Free;
          return c;
        })
      : null;
  }

  public onClickedCloseModal(): void {
    this.lastSelectedSeat = null;
    this.showSelectedSeatModal = false;
  }

  hasMine(groups: SeatViewModel[]) {
    return groups.map((o) => o.occupied).includes(OccupiedStatus.Mine);
  }

  private connectSeat(screen: ScreenViewModel, seat: SeatViewModel) {
    if (isNumber(seat.rowNumber) && isNumber(seat.colNumber) && screen.pseats[seat.rowNumber][seat.colNumber]) {
      const seatGroup = screen.groups.filter((f) => f.seatIds?.includes(seat.id))[0];
      if (!seatGroup || this.screenDataProvider.multiSeatGroupTypes.some((m) => seat.groupTypes.indexOf(m) >= 0)) {
        return '';
      }
      if (seatGroup.id !== seat.groupId) {
        console.log('group desynchronization!', 'seat:', seat, 'groups with seat id:', seatGroup);
      }

      const leftSeatConnected = this.isSeatConnected(screen, seatGroup.id, new Coordinates(Number(seat.rowNumber), Number(seat.colNumber) - 1));
      const rightSeatConnected = this.isSeatConnected(screen, seatGroup.id, new Coordinates(Number(seat.rowNumber), Number(seat.colNumber) + 1));

      if (leftSeatConnected && rightSeatConnected) {
        return 'connect-both';
      } else if (leftSeatConnected) {
        return 'connect-left';
      } else if (rightSeatConnected) {
        return 'connect-right';
      }
    }

    return '';
  }

  private isSeatConnected(screen: ScreenViewModel, seatGroupId: string, coordinates: Coordinates) {
    if (screen.pseats[coordinates.rowNumber].filter((f) => Number(f.colNumber) === coordinates.colNumber).length > 0) {
      const seatRow = screen.pseats.find((f) => f[0].rowNumber === coordinates.rowNumber.toString()) || [];
      const seatToCheck = seatRow.find((f) => f.colNumber === coordinates.colNumber.toString());
      if (!seatToCheck.groups) {
        return false;
      }

      return seatToCheck.groups.some((group) => group.id === seatGroupId && group.displayMode === SeatGroupDisplayMode.Together);
    }

    return false;
  }

  onRowHover(row: number): void {
    if (this.highlightType === 'row' && this.highlightColor) {
      this.clearCanvas();
      this.drawRowGlow(this.screen.pseats[row]);
    }
  }

  onPlaceHover(row: number, col: number, sub?: number): void {
    if (this.highlightType === 'place' && this.highlightColor) {
      this.clearCanvas();
      this.drawPlaceGlow(this.screen.pseats[row], col, sub);
    }
  }
}
