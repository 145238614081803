<h3 class="mb-3">{{'user.history.title' | translate}}</h3>

<div [smooth-in]="userHistory">
  <!-- mobile -->
  <div class="d-block d-md-none">
    <div class="row m-0 columns text-center p-2">
      <div class="col-3 text-left">
        {{ 'user.history.cinema' | translate }}
        <br />
        {{ 'user.history.date' | translate }}
      </div>
      <div class="col-4">
        {{ 'user.history.purchase' | translate }}
      </div>
      <div class="col-2">
        {{ 'user.history.pointsUsedEarned' | translate }}
      </div>
      <div class="col text-right">
        {{ 'user.history.spend' | translate }}
      </div>
    </div>

    <div *ngFor=" let salesDocument of userHistory?.salesDocuments" class="data row m-0 columns text-center p-2">
      <div class="col-3 text-left">
        {{ (getCinema(salesDocument.apiModel.cinemaId))?.name }}
        {{ salesDocument.saleDate | dateTime:'DATE_HUMAN_COMA_MDY' }}
      </div>
      <div class="col-4 d-flex flex-column">
        <div *ngFor="let ticket of getTickets(salesDocument.id) | keyvalue">
          {{ ticket.value.length }}x {{ ticket.value[0].name }}
        </div>
      </div>
      <div class="col-2">
        {{ getOrderPoints(salesDocument,-1) + " / " + getOrderPoints(salesDocument,1) }}
      </div>
      <div class="col text-right">
        {{ salesDocument.apiModel.totalValue | number: '0.2-2' }}
      </div>
    </div>
  </div>

  <!-- desktop -->
  <div class="d-none d-md-block">
    <div class="row m-0 columns text-center p-2">
      <div class="col-2 text-left">
        {{'user.history.date' | translate}}
      </div>
      <div class="col-2">
        {{'user.history.cinema' | translate}}
      </div>
      <div class="col-3">
        {{'user.history.purchase' | translate}}
      </div>
      <div class="col-2">
        {{'user.history.pointsUsed' | translate}}
      </div>
      <div class="col-2">
        {{'user.history.pointsEarned' | translate}}
      </div>
      <div class="col-1 text-right">
        {{'user.history.spend' | translate}}
      </div>
    </div>

    <div *ngFor="let salesDocument of userHistory?.salesDocuments" class="data row m-0 columns text-center p-2">
      <div class="col-2 text-left">
        {{salesDocument.saleDate | dateTime:'DATE_HUMAN_COMA_MDY'}}
      </div>
      <div class="col-2">
        {{(getCinema(salesDocument.apiModel.cinemaId))?.name}}
      </div>
      <div class="col-3 d-flex flex-column">
        <div *ngFor="let ticket of getTickets(salesDocument.id) | keyvalue">
          {{ticket.value.length}}x {{ticket.value[0].name}}
        </div>
      </div>
      <div class="col-2">
        {{getOrderPoints(salesDocument,-1)}}
      </div>
      <div class="col-2">
        {{getOrderPoints(salesDocument,1)}}
      </div>
      <div class="col-1 text-right">
        {{salesDocument.apiModel.totalValue | number: '0.2-2'}}
      </div>
    </div>
  </div>
</div>