import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ReservationHttpService } from '../http/reservation.http.service';
import { ReservationViewModel } from '../model/view-model/reservation/reservation.view.model';
import {
  ReservationConfirmRequestModel,
  ReservationRequestModel,
  ReservationToOrderRequestModel,
} from '../model/api-model/reservation/reservation.request.model';
import { OrderViewModel } from '../model/view-model/order/order.view.model';

@Injectable({
  providedIn: 'root',
})
export class ReservationDataProvider {
  constructor(private reservationHttpService: ReservationHttpService) {}

  getReservation(reservationId: string, cinemaId: string) {
    return this.reservationHttpService.getReservation(reservationId, cinemaId).pipe(map((reservation) => new ReservationViewModel(reservation)));
  }

  create(requestModel: ReservationRequestModel) {
    return this.reservationHttpService.create(requestModel).pipe(map((reservation) => new ReservationViewModel(reservation)));
  }

  confirm(reservationId: string, cinemaId: string, requestModel: ReservationConfirmRequestModel) {
    return this.reservationHttpService.confirm(reservationId, cinemaId, requestModel).pipe(map((reservation) => new ReservationViewModel(reservation)));
  }

  patchSeat(reservationId: string, cinemaId: string, requestModel: ReservationRequestModel) {
    return this.reservationHttpService.patchSeat(reservationId, cinemaId, requestModel).pipe(map((reservation) => new ReservationViewModel(reservation)));
  }

  delete(reservationId: string, cinemaId: string) {
    return this.reservationHttpService.delete(reservationId, cinemaId).pipe(map(() => true));
  }

  addReservationToOrder(orderId: string, cinemaId: string, requestModel: ReservationToOrderRequestModel): Observable<OrderViewModel> {
    return this.reservationHttpService.addReservationToOrder(orderId, cinemaId, requestModel).pipe(map((order) => new OrderViewModel(cinemaId, order)));
  }
}
