import { Expose, Transform } from 'class-transformer';
import { DateTime } from 'luxon';

export class UserVoucherItemApiModel {
  @Expose()
  id: string;

  @Expose()
  salesDocumentId: string;

  @Expose()
  itemId: string;

  @Expose()
  name: string;

  @Expose()
  price: number;

  @Expose()
  quantity: number;

  @Expose()
  @Transform(({ value }) => (value !== null ? DateTime.fromISO(value, { setZone: true }) : null), { toClassOnly: true })
  pickupTime: DateTime;

  @Expose()
  additionalSalesDocumentId: string;

  @Expose()
  @Transform(({ value }) => (value !== null ? DateTime.fromISO(value, { setZone: true }) : null), { toClassOnly: true })
  additionalSalesDocumentDate: DateTime;
}
