import { DateTime } from 'luxon';

enum SystemSettingType {
  Number,
  Decimal,
  DateTime,
  String,
  Guid,
}

export class SystemSettings {
  constructor(public restSettings: any, public hoParameters: SystemSetting[]) {}
}

export class SystemSetting {
  constructor(public symbol: string, public type: number, public value: string) {}

  getValueBasedOnType(): string | number | DateTime {
    switch (this.type) {
      case SystemSettingType.Number:
      case SystemSettingType.Decimal:
        return Number(this.value);
      case SystemSettingType.DateTime:
        return DateTime.fromISO(this.value);
      case SystemSettingType.String:
      case SystemSettingType.Guid:
      default:
        return this.value;
    }
  }
}
